import { useMemo } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useQuery, useMutation } from 'react-query';
import { getAppointmentDetail, deleteAppoinments } from '@apis';
import useConfirm from '@hooks/useConfirm';
import { useParams } from 'react-router';
import momentTz from 'moment-timezone';
import UILoader from '@components/ui-loader';
import toast from '@components/toast';
import { ChevronLeft, Phone } from 'react-feather';
import { chain, uniqBy } from 'lodash';
import useShop from '../../../../../utility/hooks/useShop';
import cn from 'classnames';
import { getEffectiveAppointmentStatus } from '../../../../../utility/Utils';
import NavbarSms from '../../../../../@core/layouts/components/navbar/NavbarSms';

const AppointmentDetail = ({ history }) => {
  const params = useParams();
  const { confirm } = useConfirm();
  const shop = useShop();
  const tz = useMemo(() => shop?.timezone || momentTz.tz.guess(), [shop]);
  const clientId = window.location.pathname.split('/')[1];

  const { data, isLoading } = useQuery(
    ['getAppointmentDetail'],
    async () => {
      const { data } = await getAppointmentDetail(params.appointmentId);
      return data.data;
    },
    {
      enabled: !!params.appointmentId,
      cacheTime: 0,
    }
  );

  const isClientOnlineCancellationEnabled = useMemo(() => {
    return shop?.feature_settings?.client_online_cancellation_enabled !== undefined &&
      shop.feature_settings.client_online_cancellation_enabled === true;
  });

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    return [
      phoneNumber.slice(0, 3),
      phoneNumber.slice(3, 6),
      phoneNumber.slice(6)
    ].join('-');
  };

  const productId = useMemo(() => data?.items?.map(item => item?.metadata?.productId), [data]);
  const isAppointmentPast = useMemo(
    () => data?.fromDate && momentTz(data?.fromDate).tz(tz).isBefore(momentTz(new Date()).tz(tz)),
    [data?.fromDate]
  );

  const deleteMutation = useMutation(deleteAppoinments, {
    onSuccess: (...args) => {
      toast.success('Appointment deleted');
      history.push('/');
    },
  });

  const isDeclineOrCancel = useMemo(() => ['DECLINED', 'DELETED'].includes(data?.status), [data?.status]);

  const groupByStaff = chain(data?.items)
    .groupBy('providerId')
    .mapValues(arr => {
      const result = arr.reduce(
        (res, item) => {
          res.provider = { providerId: item.metadata?.providerId, providerName: item.metadata?.providerName };
          res.products = uniqBy([...res.products, item], 'metadata.productId');
          return res;
        },
        { staff: null, products: [] }
      );
      return result;
    })
    .value();

  const onRescheduleClick = () => {
    history.push(`/${data?.id}/reschedule`);
  };

  const handleDeleteEvent = async () => {
    const isConfirm = await confirm({ confirmButtonColor: 'danger', title: 'Are you sure you want to cancel this appointment?' });
    if (isConfirm) {
      deleteMutation.mutate({ id: data.id, items: data?.items?.map(item => item.id) || [] });
    }
  };

  return (
    <UILoader blocking={isLoading}>
      <Card>
        <CardHeader className="align-items-center px-0 px-md-2">
          <div className="d-flex align-items-center">
            <Button className="rounded-circle btn-icon mr-50" color="flat-primary" size="sm" onClick={() => history.goBack()}>
              <ChevronLeft />
            </Button>
            <h4 className="text-center text-lg-left mb-0">{momentTz(data?.fromDate).tz(tz).format('DD, MMM YYYY - hh:mma')}</h4>
          </div>
        </CardHeader>
        <CardBody className="px-0 px-md-2">
          <>
            {Object.values(groupByStaff).map((item, index) => {
              return (
                <div className="mb-2" key={index}>
                  <div className="d-flex mb-75">
                    <p className="m-0 text-primary mr-1">Clinician: </p>
                    <p className="m-0 font-weight-bold">
                      {item.provider?.providerName}
                    </p>
                  </div>
                  <div className="d-flex mb-75">
                    <p className="m-0 text-primary mr-1">Appointment Type:</p>
                    <div className="m-0 p-0 flex-grow-1">
                      {item.products.map((product, index) => (
                        <div key={index} className="d-flex align-items-center justify-content-between">
                          <p className="m-0 font-weight-bold">
                            {product.metadata.productName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex">
                    <p className="m-0 text-primary mr-1">Status:</p>
                    <div className="m-0 p-0 flex-grow-1">
                      <span className={cn('appointment-item__status', data?.status)}>
                        {getEffectiveAppointmentStatus({ isPast: isAppointmentPast, status: data?.status })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>

          <div className="d-flex align-items-center justify-content-center mt-5">
            {isDeclineOrCancel || isAppointmentPast ? (
              <Button
                color="primary"
                style={{ borderRadius: '25px', fontFamily: 'Spartan' }}
                onClick={() => {
                  window.location.replace(`/${clientId}?appointment=${productId.join(',')}`);
                }}
              >
                Book again
              </Button>
            ) : (
              <>
              {isClientOnlineCancellationEnabled ? (
                <Button color="danger" outline style={{ borderRadius: '25px', fontFamily: 'Spartan' }} onClick={handleDeleteEvent}>
                  Cancel appointment
                </Button>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{
                    borderRadius: '25px',
                    fontFamily: 'Spartan',
                    border: '1px solid #E5E5E5',
                    padding: '15px 20px',
                  }}
                >
                  <p>If you need to cancel your appointment, please call us at:</p>
                  <Button
                    style={{
                      paddingTop: '6px',
                      paddingBottom: '4px',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      borderRadius: '20px',
                      border: '1px solid #03464C',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: '#03464C',
                      marginBottom: '12px',
                    }}
                    href={`tel:${shop?.phone}`}
                  >
                    <Phone className='mr-1' />
                    {formatPhoneNumber(shop?.phoneNumber)}
                  </Button>
                  {/* <NavbarSms buttonText="or text us" /> */}
                </div>
              )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </UILoader>
  );
};

export default AppointmentDetail;
