import { toast as toastr } from 'react-toastify';
import { Check, Trash } from 'react-feather';
import Avatar from '../avatar';

const ToastComponent = ({ title, icon, color }) => (
  <div className="toastify-header pb-0">
    <div className="title-wrapper">
      <Avatar size="sm" color={color} icon={icon} />
      <h6 className="toast-title">{title}</h6>
    </div>
  </div>
);

const options = {
  autoClose: 2000,
  hideProgressBar: true,
  closeButton: false,
};

const toast = {
  success: title => toastr.success(<ToastComponent title={title} color="success" icon={<Check />} />, options),
  error: title => toastr.error(<ToastComponent title={title} color="danger" icon={<Trash />} />, options),
};

export default toast;
