import { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col, Alert, FormGroup, Input, Label } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { initialBookingState } from '@recoil/atoms/booking/booking';
import userState from '../../../../../recoil/atoms/auth/user';
import shopState from '../../../../../recoil/atoms/current-shop';
import { AppointmentSteps, DAY_MONTH_DATE_FORMAT, DAY_MONTH_DATE_TIME_FORMAT, HOUR_FORMAT } from '../../../../../utility/Constant';
import { chain, uniqBy, get } from 'lodash';
import momentTz from 'moment-timezone';
import { ArrowRight, Calendar, Check } from 'react-feather';
import { defaultSelectedProduct } from '../../../../../recoil/atoms/booking/booking';
import useMomentTimezone from '../../../../../utility/hooks/useMomentTimezone';
import BookSuccessIcon from '@src/assets/images/icons/book-success.png';
import BookSuccessWaitingIcon from '@src/assets/images/icons/book-success-waiting.svg';
import { useMutation } from 'react-query';
import { addToWaitlist, removeFromWaitlist } from '../../../../../apis/appointment.api';
import toast from '@components/toast';
import { borderRadius } from 'polished';

const BookSuccessAccept = ({ userName, booking, staff, bookingDateTime, shop, address, isTelemedicine }) => {
  return (
    <>
      <p className="mb-2 mx-2 px-2 text-center" style={{ fontSize: '1.25rem' }}>
        Thank you{userName ? `, ${userName}` : ''}!<br />
        {`Your ${isTelemedicine ? 'virtual ' : ''}appointment is scheduled${isTelemedicine ? '. We will text you a virtual meeting link prior to your appointment.' : ':'}`}
      </p>
      <Alert className="pt-1 pl-1 pb-1" fade={false}>
        {Object.values(staff).map(item => (
            <div className="confirmation-summary">
              <span>
                <Calendar className="mr-1" size={18} />
                {!booking?.isDropoff && momentTz.tz(bookingDateTime, shop.timezone).format(DAY_MONTH_DATE_TIME_FORMAT)}
                {booking?.isDropoff && `${momentTz.tz(bookingDateTime, shop.timezone).format(DAY_MONTH_DATE_FORMAT)}, ${booking?.dropoffLabel}`}
              </span>
              <div className='confirmation-items'>
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {booking?.returningClientFlow ? 'Returning client' : 'New client'}
                </p>
                {booking?.species &&
                  <p className="font-weight-normal">
                    <Check className="mr-1" size={15} />
                    {booking.species?.name}
                  </p>
                }
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {item.products.map(product => product.name).join(' | ')}{' '}
                </p>
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {item.provider?.id === 'any-provider' ? 'First available clinician' : item.provider?.firstName}
                </p>
                {booking?.isDropoff && (
                  <p className="font-weight-normal">
                    <Check className="mr-1" size={15} />
                    {`Your pet will be dropped off for this appointment`}
                  </p>
                )}
              </div>
            </div>
        ))}
      </Alert>
    </>
  );
};

const BookSuccessWaiting = ({ userName, address, staff, bookingDateTime, shop, booking, isTelemedicine }) => {
  return (
    <>
      <p className="mb-1 mx-2 text-center" style={{ fontSize: '1.25rem' }}>
        Thank you{userName ? `, ${userName}` : ''}!<br />
        {`Your ${isTelemedicine ? 'virtual ' : ''}appointment has been requested!`}
      </p>
      <div className="d-flex align-items-center mb-1 text-center" style={{ fontSize: '1rem', color: '#EA6B63' }}>
        We'll let you know when your<br/>appointment has been accepted.
      </div>
      <Alert color="warning" className="pt-1 pl-1 pb-1" fade={false}>
        {Object.values(staff).map(item => (
            <div className="confirmation-summary" style={{ color: '#656565' }}>
              <span>
                <Calendar className="mr-1" size={18} />
                {!booking?.isDropoff && momentTz.tz(bookingDateTime, shop.timezone).format(DAY_MONTH_DATE_TIME_FORMAT)}
                {booking?.isDropoff && `${momentTz.tz(bookingDateTime, shop.timezone).format(DAY_MONTH_DATE_FORMAT)}, ${booking?.dropoffLabel}`}
              </span>
              <div className='confirmation-items'>
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {booking?.returningClientFlow ? 'Returning client' : 'New client'}
                </p>
                {booking?.species &&
                  <p className="font-weight-normal">
                    <Check className="mr-1" size={15} />
                    {booking.species?.name}
                  </p>
                }
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {item.products.map(product => product.name).join(' | ')}{' '}
                </p>
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {item.provider?.id === 'any-provider' ? 'First available clinician' : item.provider?.firstName}
                </p>
                {booking?.isDropoff && (
                  <p className="font-weight-normal">
                    <Check className="mr-1" size={15} />
                    {`Drop-off appointment`}
                  </p>
                )}
              </div>
            </div>
        ))}
      </Alert>
    </>
  );
};

const BookSuccess = ({ step, backPreviousStep, setStep, booking, setBooking }) => {
  const [user] = useRecoilState(userState);
  const [shop] = useRecoilState(shopState);
  const [addedToWaitlist, setAddedToWaitlist] = useState(false);

  useEffect(() => {
    let timer = null;
    const {
      metadata: { thankYouPage = '', isBookingShowThankYouPage },
    } = shop;
    if (step === AppointmentSteps.BookSuccess && thankYouPage && isBookingShowThankYouPage) {
      timer = setTimeout(() => {
        window.location.href = thankYouPage;
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [step]);

  const onReset = () => {
    setBooking(initialBookingState);
    setStep(1);
  };

  const isTelemedicine = useMemo(() => {
    const product = booking.selectedProducts[0]?.products[0];
    if (product && product.isTelemedicine) return true;
    return false;
  }, [booking.selectedProducts]);

  const waitlistEnabled = () => {
    if (shop?.feature_settings?.waitlist_ui_v1_enabled !== undefined) {
      return shop.feature_settings.waitlist_ui_v1_enabled === true && booking?.selectedProducts[0]?.products[0]?.metadata?.waitlistEligible;
    } else {
      return false;
    }
  };

  const addToWaitlistMutation = useMutation(addToWaitlist);
  const removeFromWaitlistMutation = useMutation(removeFromWaitlist);

  const modifyWaitlist = (val) => {
    setAddedToWaitlist(val);
    if (val) {
      addToWaitlistMutation.mutate(
        {
          client_id: booking.clientId,
          appointment_id: booking.appointmentId
        },
        {
          onSuccess: (data) => {
            toast.success('You\'ve been added to the waitlist!');
          },
          onError: () => {
          },
        }
      );
    } else {
      removeFromWaitlistMutation.mutate(
        {
          client_id: booking.clientId,
          appointment_id: booking.appointmentId
        },
        {
          onSuccess: (data) => {
            toast.success('You\'ve been removed from the waitlist.');
          },
          onError: () => {
          },
        }
      );
    }
  };

  let selectedStaff = chain(booking.selectedProducts)
    .groupBy('provider.id')
    .mapValues(arr => {
      const result = arr.reduce(
        (res, item) => {
          res.provider = item.provider;
          res.products = uniqBy([...res.products, ...item.products], 'id');
          return res;
        },
        { ...defaultSelectedProduct }
      );
      return result;
    })
    .value();
  if (Object.keys(selectedStaff).length > 1 || Object.keys(selectedStaff)[0] === 'undefined') {
    selectedStaff = undefined;
  }

  return (
    <div className="booking-content-wrapper">
      <div className="d-flex flex-column align-items-center mb-0 mt-2">
        {shop?.metadata?.isAutoAccept && (
          <BookSuccessAccept
            booking={booking}
            userName={user?.firstName}
            staff={selectedStaff}
            bookingDateTime={booking?.dateTime}
            shop={shop}
            address={shop?.address}
            isTelemedicine={isTelemedicine}
          />
        )}
        {!shop?.metadata?.isAutoAccept && (
          <BookSuccessWaiting
            booking={booking}
            userName={user?.firstName}
            staff={selectedStaff}
            bookingDateTime={booking?.dateTime}
            shop={shop}
            address={shop?.address}
            isTelemedicine={isTelemedicine}
          />
        )}
      </div>
      {waitlistEnabled() && (
        <Row className="justify-content-center">
          <Col sm={8} md={6} style={{ marginBottom: '8px' }}>
            <FormGroup className="waitlist-message" check>
              <Input
                className="sms-opt-in-checkbox"
                type="checkbox"
                checked={addedToWaitlist}
                onChange={() => modifyWaitlist(!addedToWaitlist)}
              />
              <Label
                className='sms-opt-in-label'
                style={{ marginLeft: '8px' }}
                check
                onClick={() => modifyWaitlist(!addedToWaitlist)}
                color="primary"
              >
                <>
                {shop?.metadata?.isAutoAccept && (
                  <>Add me to the waitlist! If any cancellations open up an earlier appointment time, I will be notified.</>
                )}
                {!shop?.metadata?.isAutoAccept && (
                  <>Once my appointment is accepted, add me to the waitlist! If any cancellations open up an earlier appointment time, I will be notified.</>
                )}
                </>
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        <Col sm={8} md={6}>
          <Button color="primary" style={{ borderRadius: '25px' }} block tag={'a'} href={`/${shop?.id}/appointments`}>
            My Appointments
            <ArrowRight className="ml-1" />
          </Button>

          {/* <Button outline color="primary" block onClick={onReset}>
            Book new appointment
          </Button> */}
        </Col>
      </Row>
    </div>
  );
};

export default BookSuccess;
