const CREATION_THRESHOLD = 1000 * 60 * 10; // 10 minutes

export const isReturningClient = (client) => {
  if (!client || !client.created_at) {
    return false;
  }
  const timeSince = Date.now() - new Date(client.created_at).getTime();
  if (timeSince > CREATION_THRESHOLD) {
    return true;
  }
  return false;
};
