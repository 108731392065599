import { COLORS, CookieKeys, StatusContent } from './Constant';
import { Cookie } from './Cookie';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Converts number to string
export const numberToString = num => `${num < 10 ? '0' : ''}${num}`;

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!Cookie.getItem(CookieKeys.user);
export const getUserData = () => Cookie.getItem(CookieKeys.user);
export const getShopData = () => Cookie.getItem(CookieKeys.shop);

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/login';
};

// Get random color - use for Calendar event
export const getRandomColor = code => {
  let sum = 0;
  for (const index in code) {
    if (code.charCodeAt(index)) {
      sum += code.charCodeAt(index);
    }
  }
  return COLORS[sum % COLORS.length];
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#03464C', // for selected option bg-color
    neutral10: '#03464C', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

export const getStatusColor = (isPast, status) => {
  if (status === 'REQUESTED' && !isPast) return { hex: '#ea6b63', hexLighter: '#ea6b634d' }; // Red
  if (status === 'ACCEPTED' && !isPast) return { hex: '#f1eb9ccc', hexLighter: '#f1eb9c66' }; // Yellow
  if (status === 'CONFIRMED' && !isPast) return { hex: '#9BBE95', hexLighter: '#b8e1b1cc' }; // Green
  if (status === 'DELETED') return { hex: '#656565', hexLighter: '#656565aa' }; // Dark Grey
  return { hex: '#9BBE95', hexLighter: '#b8e1b1cc' }; // Green
};

export const getEffectiveAppointmentStatus = ({ isPast, status }) => {
  if (isPast && ['ACCEPTED', 'CONFIRMED'].includes(status)) return StatusContent.DONE;
  return StatusContent?.[status] || StatusContent.ACCEPTED;
};

export * from './Constant';
