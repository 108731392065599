import { atom } from 'recoil';

const confirmBox = {
  show: false,
  title: '',
  message: '',
  isConfirm: false,
};

const confirmBoxState = atom({
  key: 'confirmBox',
  default: confirmBox,
});

export default confirmBoxState;
