import axios from 'axios';

export const getStaffs = filter => {
  const query = new URLSearchParams(filter).toString();
  return axios.get(`/appointment/v1/providers?${query}`);
};

export const getStaffDetail = ({ id = '' }) => axios.get(`/appointment/v1/providers/${id}`);
export const getStaffProduct = ({ id = '' }) => axios.get(`/appointment/v1/providers/${id}/products`);
export const createStaff = data => axios.post(`/appointment/v1/providers`, data);
export const updateStaff = ({ id = '', data }) => axios.put(`/appointment/v1/providers/${id}`, data);
export const updateStaffProduct = ({ id = '', data }) => axios.put(`/appointment/v1/providers/${id}/products`, data);
export const updateStaffPassword = ({ id = '', data }) => axios.put(`/appointment/v1/providers/${id}/change-password`, data);
export const deleteStaff = ({ id = '' }) => axios.delete(`/appointment/v1/providers/${id}`);
export const getStaffsWorkingHours = data => axios.post(`/appointment/v1/providers/working-hours`, data);
