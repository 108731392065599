// ** Routes Imports
import AppRoutes from './Apps';

// ** Document title
const TemplateTitle = '%s - Shop management';

// ** Default Route
const DefaultRoute = '/';

// ** Merge Routes
const Routes = [...AppRoutes];

export { DefaultRoute, TemplateTitle, Routes };
