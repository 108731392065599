import { atom } from 'recoil';

const timeZone = [];

const timeZoneState = atom({
  key: 'timeZone',
  default: timeZone,
});

export default timeZoneState;
