import { atom } from 'recoil';

const shop = null;

const shopState = atom({
  key: 'currentShop',
  default: shop,
});

export default shopState;
