import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Lock } from "react-feather";
import { Button, Row, Col, Spinner } from 'reactstrap';
import { useRecoilState } from 'recoil';
import * as Sentry from "@sentry/react";
import userState from '../../../../../recoil/atoms/auth/user';
import shopState from '../../../../../recoil/atoms/current-shop';
import themeConfig from '@configs/themeConfig'
import VerificationCodeInput from '../../../../components/inputs/VerificationCodeInput';
import { createVerificationCode, verifyCode, bookAppointment } from '@apis';
import { useMutation } from 'react-query';
import useJwt from '@src/@core/auth/jwt/useJwt';
import { AppointmentSteps, CookieKeys, SpecialErrors } from '../../../../../utility/Constant';
import { defaultSelectedProduct } from '../../../../../recoil/atoms/booking/booking';
import moment from 'moment';
import { chain, uniqBy } from 'lodash';
import { Cookie } from '../../../../../utility/Cookie';
import { updateCustomerAdmin, upsertClient } from '../../../../../apis/customer.api';
import { isDepositRequired, saveAppointmentBeforeDeposit } from '../depositHelper';
import { previousStepState } from '../../../../../recoil/atoms/booking/bookingStep';
import clientState from '../../../../../recoil/atoms/booking/client';
import { isReturningClient } from '../returningClientHelper';

const Verification = ({ goNextStep, booking, setStep, setBooking, backPreviousStep }) => {
  const [user, setUser] = useRecoilState(userState);
  const [client, setClient] = useRecoilState(clientState);
  const [shop] = useRecoilState(shopState);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [doClearCode, setDoClearCode] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(false);
  const [previousStep, _setPreviousStep] = useRecoilState(previousStepState);
  const { jwt } = useJwt();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsResendDisabled(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isResendDisabled]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', backPreviousStep);
    return () => {
      window.removeEventListener('popstate', backPreviousStep);
    };
  }, []);

  useEffect(() => {
    if (shop) {
      Sentry.metrics.increment("booking_begin_verification", 1, {
        tags: { shop: shop?.name, user: user?.id },
      });
    }
  }, [shop]);

  const isMobile = () => {
    return booking?.customer?.phoneNumber || !booking?.customer?.email
  };

  const formatPhoneNumber = (phoneNumber) => {
    return [
      phoneNumber.slice(0, 3),
      phoneNumber.slice(3, 6),
      phoneNumber.slice(6)
    ].join('-');
  };

  const codeDestination = () => {
    if (!!booking?.customer?.phoneNumber) {
      return formatPhoneNumber(booking.customer.phoneNumber);
    } else if (!!booking?.customer?.email) {
      return booking.customer.email;
    } else {
      return 'your email or phone number';
    }
  };

  const selectedProviders = chain(booking.selectedProducts)
  .groupBy('provider.id')
  .mapValues(arr => {
    const result = arr.reduce(
      (res, item) => {
        res.provider = item.provider;
        res.products = uniqBy([...res.products, ...item.products], 'id');
        return res;
      },
      { ...defaultSelectedProduct }
    );
    return result;
  })
  .value();

  const isAppointmentLimitEnabled = useMemo(() => {
    return shop?.feature_settings?.booking_limit_per_time_slot_enabled !== undefined &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.enabled &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.apply_to_providers &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.limit > 1;
  }, [shop]);

  const addBookingMutation = useMutation(bookAppointment);

  const saveAppointment = (user, clientInfo, saveAsRequest = false) => {
    const products = Object.values(selectedProviders).reduce((res, item) => {
      const arr = item.products.map(product => ({
        id: product.id,
        anyone: item.provider.id === 'any-provider' ? !booking?.dateTimeProviderId : !item.provider,
        providerId: item.provider.id === 'any-provider' ? (booking?.dateTimeProviderId || null) : item.provider?.id,
        ...(!item.provider && { label: 'Anyone' }),
      }));
      res.push(...arr);
      return res;
    }, []);

    const sendReminder = booking.allowRemind && !saveAsRequest;
    addBookingMutation.mutate(
      {
        source: 'CUSTOMER_SITE',
        products,
        userId: user?.id,
        isReminderSMS: sendReminder,
        isReminderEmail: sendReminder,
        isSendConfirmation: sendReminder,
        isReturningClient: booking.returningClientFlow,
        roomId: booking?.roomId,
        date: booking.dateTime,
        note: booking.note,
        isDropoff: booking.isDropoff,
        speciesId: booking?.species?.id || clientInfo?.species?.id,
        speciesName: booking?.species?.name || clientInfo?.species?.name,
        patientName: booking?.customer?.petName || clientInfo?.petName,
        sex: booking?.customer?.sex || clientInfo?.sex,
        birthdate: booking?.customer?.birthdate || clientInfo?.birthdate,
        breed: booking?.customer?.breed || clientInfo?.breed,
        breed_id: booking?.customer?.breed_id || clientInfo?.breed_id,
        ignoreOverlap: isAppointmentLimitEnabled,
        confirmed_keyword_action: booking?.confirmed_keyword_action || clientInfo?.confirmedKeywordAction,
        time_slot_calendar_id: booking?.time_slot_calendar_id,
        saveAsRequest,
      },
      {
        onSuccess: ({ data }) => {
          setBooking({
            ...booking,
            appointmentId: data.data.id,
            customer: {
              ...booking.customer,
              ...clientInfo
            },
            clientId: data.data.items[0].clientId,
            isSaved: true
          });
          setIsLoading(false);
          sessionStorage.removeItem(CookieKeys.confirmationForm);
          Sentry.metrics.increment("booking_save_appointment", 1, {
            tags: { shop: shop?.name, user: user?.id },
          });
          goNextStep();
        },
        onError: (error) => {
          Sentry.captureException(error);
          setIsLoading(false);
        },
      }
    );
  };

  const updateUserMutation = useMutation(updateCustomerAdmin);

  const updateUserRecordWithExistingClient = (user, client) => {
    const fullName = `${client?.first_name || ''} ${client?.last_name || ''}`.trim();
    updateUserMutation.mutate({
        id: user?.id,
        firstName: fullName,
        email: client?.email,
      },
      {
        onSuccess: (data) => {
          const tmpUser = { ...data.data.data };
          setUser(tmpUser);
          Cookie.setItem(CookieKeys.user, tmpUser);
          goNextStep();
        },
        onError: () => {
          goNextStep();
        }
      }
    );
  };

  const upsertClientMutation = useMutation(upsertClient);

  const findOrCreateClient = (user, clientInfo) => {
    const fullName = user?.firstName;
    const firstName = fullName?.split(' ')[0] || '';
    const lastName = fullName?.split(' ')[1] || '';
    const clientParams = {
      user_id: user?.id,
      email: user?.email || clientInfo?.email,
      phone: user?.phoneNumber || clientInfo?.phoneNumber,
      first_name: firstName,
      last_name: lastName,
      address1: clientInfo?.address1,
      address2: clientInfo?.address2,
      city: clientInfo?.city,
      state: clientInfo?.state,
      country: clientInfo?.country,
      zip: clientInfo?.zip,
    };
    upsertClientMutation.mutate(
      clientParams,
      {
        onSuccess: (data) => {
          let localClient = null;
          if (data?.data?.id) {
            localClient = data?.data;
            setBooking(prev => ({
              ...prev,
              clientId: localClient.id,
              customer: {
                ...prev.customer,
                firstName: `${localClient.first_name} ${localClient.last_name}`,
                email: localClient.email,
                phoneNumber: localClient.phone,
                address1: localClient.address1,
                address2: localClient.address2,
                city: localClient.city,
                state: localClient.state,
                zip: localClient.zip,
                created_at: localClient.created_at,
                patients: localClient.patients
              }
            }));
            setClient(localClient);
          }
          if (previousStep === AppointmentSteps.ReturningClientInfo) {
            if (!fullName && (data?.data?.first_name || data?.data?.last_name)) {
              // Update user record now with client name and email before going to next step
              updateUserRecordWithExistingClient(user, localClient);
            } else { // Go to AppointmentSelection and fill out name info later.
              goNextStep();
            }
          } else {
            const product = booking.selectedProducts[0].products[0];
            const isReturning = isReturningClient(localClient);
            if (product && isDepositRequired(shop, product, isReturning)) {
              if (saveAppointmentBeforeDeposit(product)) {
                const saveAsRequest = true;
                saveAppointment(user, clientInfo, saveAsRequest);
              } else {
                goNextStep();
              }
            } else {
              saveAppointment(user, clientInfo);
            }
          }
        },
        onError: (data) => {
          setIsLoading(false);
        },
      }
    );
  };

  const verifyCodeMutation = useMutation(verifyCode);

  const onSetCode = (code) => {
    setIsLoading(true);
    setIsResendDisabled(true);
    Sentry.metrics.increment("booking_finish_verification", 1, {
      tags: { shop: shop?.name, user: user?.id },
    });
    verifyCodeMutation.mutate(
      {
        ...booking?.customer,
        code
      },
      {
        onSuccess: (data) => {
          sessionStorage.removeItem(CookieKeys.confirmationForm);
          const tmpUser = { ...data.data.data };
          setUser(tmpUser);
          jwt.setToken(tmpUser.token);
          Cookie.setItem(CookieKeys.user, tmpUser);
          const prevValues = Cookie.getItem(CookieKeys.confirmationForm);
          findOrCreateClient(tmpUser, prevValues);
        },
        onError: (data) => {
          setIsLoading(false);
          if (data?.response?.data?.message === 'VerificationMaximumExceeded') {
            sessionStorage.removeItem(CookieKeys.confirmationForm);
            setBooking(prev => ({ ...prev, verificationError: true }));
          } else if (data?.response?.data?.message === SpecialErrors.InvalidCode) {
            setShowVerificationError(true);
          }
        },
      }
    );
  };

  const createCodeMutation = useMutation(createVerificationCode);

  const resendCode = () => {
    setIsResendDisabled(true);
    setIsLoading(true);
    setDoClearCode(true);
    setShowVerificationError(false);
    const body = {
      email: booking?.customer?.email,
      phoneNumber: booking?.customer?.phoneNumber,
      phoneCode: booking?.customer?.phoneCode
    };
    createCodeMutation.mutate(body,
      {
        onSuccess: () => {
          setIsLoading(false);
          sessionStorage.removeItem(CookieKeys.confirmationForm);
        },
        onError: (data) => {
          setIsLoading(false);
          if (data?.response?.data?.message === 'VerificationMaximumExceeded') {
            sessionStorage.removeItem(CookieKeys.confirmationForm);
            setBooking(prev => ({ ...prev, verificationError: true }));
          }
        },
      }
    )
  };

  return (
    <>
      <Row className="verification-header">
        <Col className="pl-0 flex-grow-0 verification-header-back">
          <Button className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
            <ChevronLeft />
          </Button>
        </Col>
        <Col className="verification-header-logo">
          <div>
            <img src={themeConfig.app.appLogoTextImage} alt='logo' style={{ height: '40px' }}/>
            <Lock className='verification-header-lock-logo' size="15" stroke-width="3" />
          </div>
        </Col>
      </Row>
      <Row className="verification-body">
        {!booking?.verificationError && (
          <>
            <p className="title" >Check your {isMobile() ? "phone" : "email"}!</p>
            <p className="text-align-center"> We sent a 6-digit confirmation code to <span style={{ whiteSpace: 'nowrap' }}><strong>{codeDestination()}</strong></span>. It expires shortly, so enter it soon to confirm your appointment.</p>
            <VerificationCodeInput
              codeLength={6}
              doClearCode={doClearCode}
              setDoClearCode={setDoClearCode}
              setCode={onSetCode}
              isLoading={isLoading}
            />
          </>
        )}

      </Row>
      <Row className="verification-body mt-1">
        {!booking?.verificationError && (
          <div className="verification-resend">
            {showVerificationError && (
              <p style={{ color: 'red', marginBottom: '0' }}>Oops, that didn't work. Please enter the code that was sent to you.</p>
            )}
            <Button
              className={`verification-resend-button ${showVerificationError ? 'mt-2' : 'mt-3'}`}
              outline
              color="primary"
              disabled={isResendDisabled || isLoading}
              onClick={isResendDisabled ? null : resendCode}
            >
              {isLoading && <Spinner size="sm" color="primary" className="mr-75" style={{ marginBottom: '3px' }} />}
              Resend Code?
            </Button>
          </div>
        )}
        {booking?.verificationError && (
          <p>We're sorry, we cannot verify your information at this time. Please try again later{shop && shop.phoneNumber ? ` or contact us at ${formatPhoneNumber(shop.phoneNumber)}.` : '.'}</p>
        )}
      </Row>
    </>
  );
};

export default Verification;
