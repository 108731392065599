import { Button, Label, Spinner } from "reactstrap";

import { PetInsuranceComponent } from '@vertical-insure/web-components/react';
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { checkoutInsurance, completeInsurancePayment, findExistingInsuranceQuote, getInsuranceClientId } from "../../../../../apis";
import { ChevronLeft } from "react-feather";
import { cloneDeep } from "lodash";
import toast from "@components/toast";
import './insurance.style.scss';
import InsuranceCheckoutForm from "./InsuranceCheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import styles from './DepositCollection.module.scss';
import { loadStripe } from "@stripe/stripe-js";

const InsuranceOffer = (props) => {
  const {
    goNextStep,
    backPreviousStep,
    booking,
    setBooking,
    params
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [viClientId, setViClientId] = useState(null);
  const [clientFirstName, setClientFirstName] = useState(null);
  const [clientLastName, setClientLastName] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientState, setClientState] = useState(null);
  const [clientCity, setClientCity] = useState(null);
  const [clientZip, setClientZip] = useState(null);
  const [species, setSpecies] = useState(null);
  const [declaredBreed, setDeclaredBreed] = useState(null);
  const [plan, setPlan] = useState('INJURY_AND_ILLNESS');
  const [age, setAge] = useState(3);
  const [petName, setPetName] = useState(null);
  const [skipOffer, setSkipOffer] = useState(false);
  const [gender, setGender] = useState(null);
  const InsuranceStates = {
    Presented: 0,
    Declined: 1,
    Accepted: 2
  };
  const [insuranceState, setInsuranceState] = useState(InsuranceStates.Presented);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [makingIndependentPayment, setMakingIndependentPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const elementRef = useRef(null);

  const {
    data: _existingQuote,
    refetch: existingQuoteRefetch
  } = useQuery(
    ['getExistingQuote', booking?.clientId, booking?.customer?.petName], () => findExistingInsuranceQuote({ client_id: booking?.clientId, name: booking?.customer?.petName }), {
      onSuccess: (data) => {
        if (data?.data?.id) {
          setSkipOffer(true);
        }
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
  });

  const {
    data: _viClientId,
    refetch: viClientIdRefetch
  } = useQuery(
    ['getViClientId'], () => getInsuranceClientId(), {
      onSuccess: (data) => {
        setViClientId(data?.data?.client_id);
      },
      onError: (err) => {
        setIsLoading(false);
      },
      refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (booking.species?.name === 'Dog' || booking.species?.name === 'Cat') {
      setSpecies(booking.species?.name.toUpperCase());
    } else {
      goNextStep();
      return;
    }
    if (booking.customer?.firstName) {
      const fullName = booking.customer.firstName;
      const nameArray = fullName.split(" ");
      if (nameArray.length > 1) {
        setClientFirstName(nameArray[0]);
        setClientLastName(nameArray[1]);
      } else {
        setClientFirstName(nameArray[0]);
      }
    }
    if (booking.customer?.state) {
      setClientState(booking.customer?.state);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.city) {
      setClientCity(booking.customer?.city);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.zip) {
      setClientZip(booking.customer?.zip);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.email) {
      setClientEmail(booking.customer?.email);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.petName) {
      setPetName(booking.customer?.petName);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.sex) {
      setGender(booking.customer?.sex.toUpperCase());
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.birthdate) {
      // convert birthdate to age
      const today = new Date();
      const birthDate = new Date(booking.customer?.birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      setAge(age);
    } else {
      setSkipOffer(true);
    }
    if (booking.customer?.breed) {
      setDeclaredBreed(booking.customer?.breed);
    } else {
      setSkipOffer(true);
    }
    viClientIdRefetch();
    existingQuoteRefetch();
  }, []);

  useEffect(() => {
    if (skipOffer) {
      goNextStep();
    }
  }, [skipOffer]);

  const onStateChange = (e) => {
    let quotes;
    if (e.detail && Array.isArray(e.detail)) {
      quotes = cloneDeep(e.detail);
    } else if (e.detail && typeof e.detail === 'object') {
      quotes = [cloneDeep(e.detail)];
    }
    setIsLoadingCheckout(false);
    if (!quotes) {
      setSelectedQuote(null);
      return;
    }
    if (quotes.some((q) => q.selectedState === 'DECLINED')) {
      setInsuranceState(InsuranceStates.Declined);
      setSelectedQuote(null);
      setClientSecret(null);
      setMakingIndependentPayment(false);
    } else if (quotes.some((q) => q.selectedState === 'ACCEPTED')) {
      setInsuranceState(InsuranceStates.Accepted);
      const quote = cloneDeep(quotes.find((q) => q.selectedState === 'ACCEPTED')?.quote);
      setSelectedQuote(quote);
      setClientSecret(null);
      setStripePromise(null);
      setMakingIndependentPayment(false);
    } else {
      setInsuranceState(InsuranceStates.Presented);
      setSelectedQuote(null);
      setClientSecret(null);
      setMakingIndependentPayment(false);
    }
  };

  const checkoutInsuranceMutation = useMutation(checkoutInsurance);

  const onCheckout = () => {
    setIsLoadingCheckout(true);
    checkoutInsuranceMutation.mutate({
      amount: selectedQuote.subscription_premium.payment_amount,
      quote_id: selectedQuote.quote_id,
      client_id: booking.clientId,
      appointment_id: booking.appointmentId,
      is_deposit_paid: booking.deposit_paid,
    },
    {
      onSuccess: (data) => {
        if (data?.data) {
          if (data.data.complete) {
            setIsLoadingCheckout(false);
            setIsComplete(true);
            setIsSuccess(true);
            setMakingIndependentPayment(false);
          } else {
            setMakingIndependentPayment(true);
            setTimeout(() => {
              elementRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 1500);
            setClientSecret(data?.data?.client_secret);
            setStripePromise(loadStripe(data?.data?.stripe_public_key, { stripeAccount: data?.data?.stripe_account }));
          }
        }
      },
      onError: () => {
        setIsLoadingCheckout(false);
        setMakingIndependentPayment(false);
        setIsComplete(true);
        setIsSuccess(false);
      },
    });
  };

  const onNextStep = () => {
    if (insuranceState === InsuranceStates.Declined || isComplete) {
      goNextStep();
    } else if (insuranceState === InsuranceStates.Accepted) {
      onCheckout();
    } else {
      toast.error('Please select or decline an insurance offer.');
    }
  };

  const petInsuranceComponent = useMemo(() => {
    return <PetInsuranceComponent
      client-id={viClientId}
      customer-email-address={clientEmail}
      customer-first-name={clientFirstName}
      customer-last-name={clientLastName}
      customer-state={clientState}
      customer-city={clientCity}
      customer-postal-code={clientZip}
      species={species}
      declared-breed={declaredBreed}
      plan={plan}
      age={age}
      pet-name={petName}
      gender={gender}
      offerStateChange={(e) => onStateChange(e)}
    />
  }, [viClientId, clientFirstName, clientLastName, clientEmail, clientState, clientCity, clientZip, species, declaredBreed, plan, age, petName, gender]);

  const completeInsurancePaymentMutation = useMutation(completeInsurancePayment);

  const onCompletePayment = () => {
    const params = {
      quote_id: selectedQuote?.quote_id
    };
    completeInsurancePaymentMutation.mutate(params, {
      onSuccess: (data) => {
        if (data?.data) {
          if (data.data.complete) {
            setIsComplete(true);
            setIsLoadingCheckout(false);
            setIsSuccess(true);
            setMakingIndependentPayment(false);
          } else {
            toast.error('Something went wrong, but your appointment has still been booked. (1)');
            setIsLoadingCheckout(false);
            setIsSuccess(false);
            setMakingIndependentPayment(false);
          }
        }
      },
      onError: (error) => {
        toast.error('Something went wrong, but your appointment has still been booked. (2)');
        setIsSuccess(false);
        setIsComplete(true);
        setIsLoadingCheckout(false);
        setMakingIndependentPayment(false);
      },
    });
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#03464C',
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <div className="d-flex align-items-center booking-header" style={{ paddingTop: '8px', animation: 'fadeIn 0.5s' }}>
        <Button className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
          <ChevronLeft />
        </Button>
      </div>
      {!isComplete && (
        <>
          {!!viClientId && !isLoading && (
            <>
              <h2 className="mt-2" style={{ font: 'Spartan' }}>Add insurance for your pet</h2>
              {!makingIndependentPayment && (
                <Button
                  color="primary"
                  onClick={onNextStep}
                  className="mb-1"
                  style={{ borderRadius: '20px' }}
                  disabled={isLoading || insuranceState === InsuranceStates.Presented || isLoadingCheckout}
                >
                  Continue
                  {isLoadingCheckout && <Spinner color="primary" size="sm" style={{ marginLeft: '10px' }} />}
                </Button>
              )}
              <>
                {petInsuranceComponent}
              </>
            </>
          )}
          {isLoading && (
            <Spinner color="primary" />
          )}
          {!makingIndependentPayment && (
            <Button
              color="primary"
              onClick={onNextStep}
              className="mt-2 mb-2"
              style={{ borderRadius: '20px' }}
              disabled={isLoading || insuranceState === InsuranceStates.Presented || isLoadingCheckout}
            >
              Continue
              {isLoadingCheckout && <Spinner color="primary" size="sm" style={{ marginLeft: '10px' }} />}
            </Button>
          )}
        </>
      )}
      {makingIndependentPayment && (
        <>
          <div className="mt-1 mb-2">
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <InsuranceCheckoutForm
                  onCompletePayment={onCompletePayment}
                />
              </Elements>
            )}
            {!clientSecret && (
              <div className={styles['deposit-payment-loading']}>
                <span style={{ marginTop: '4px' }}>Just a moment...</span>
                <Spinner color="primary" style={{ marginLeft: '10px' }} />
              </div>
            )}
          </div>
          <button className="small-text-button mt-1" color="primary" onClick={goNextStep}>I'd like to skip this</button>
          <div ref={elementRef} style={{ height: '1px' }} />
        </>
      )}
      {isComplete && (
        <>
          {isSuccess ? (
            <>
              <p className="mt-2" style={{ font: 'Spartan' }}>Your pet insurance has been purchased and you have been charged ${selectedQuote.subscription_premium.payment_amount / 100}. You will receive an email with the details of your insurance.</p>
            </>
          ) : (
            <>
              <p className="mt-2" style={{ font: 'Spartan' }}>There was a problem purchasing your pet insurance, but your appointment has still been booked.</p>
            </>
          )}
          <Button
            color="primary"
            onClick={onNextStep}
            className="mt-2 mb-2"
            style={{ borderRadius: '20px' }}
            disabled={isLoading || insuranceState === InsuranceStates.Presented || isLoadingCheckout}
          >
            Continue
          </Button>
        </>
      )}
    </>
  );
};

export default InsuranceOffer;
