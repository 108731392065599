import { atom } from 'recoil';
import { CookieKeys } from '../../../utility/Constant';
import { Cookie } from '../../../utility/Cookie';

const user = Cookie.getItem(CookieKeys.user);
const userState = atom({
  key: 'user',
  default: user,
});

export default userState;
