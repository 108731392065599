import { useRecoilState } from "recoil";
import shopState from "../../../../../recoil/atoms/current-shop";
import userState from "../../../../../recoil/atoms/auth/user";
import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

const ClientTypeSelection = ({ externalKey, step, goNextStep, booking, setBooking }) => {
  const [shop] = useRecoilState(shopState);
  const [user, setUser] = useRecoilState(userState);
  const [proceed, setProceed] = useState(false);

  useEffect(() => {
    if (proceed && shop?.feature_settings) {
      Sentry.metrics.increment("booking_client_type_selected", 1, {
        tags: { shop: shop?.name, user: user?.id },
      });
      goNextStep();
    }
  }, [proceed, shop]);

  useEffect(() => {
    if (user) {
      setBooking({
        ...booking,
        returningClientFlow: true,
      });
      setProceed(true);
    }
  }, []);

  useEffect(() => {
    if (booking?.returningClientFlow || user) {
      setProceed(true);
    }
  }, [booking?.returningClientFlow]);

  const onSelectClientType = (clientType) => {
    if (clientType === "new") {
      setBooking({
        ...booking,
        returningClientFlow: false,
      });
      setProceed(true);
    } else if (clientType === "returning") {
      setBooking({
        ...booking,
        returningClientFlow: true,
      });
    }
  };

  return (
    <div className="client-type-selection">
      {!!shop && (
        <div className="header">
          <span
            className="header-title"
          >
            Hello! 👋 Have you visited
          </span>
          <span
            className="header-title"
          >
            {shop?.name} before?
          </span>
          <div className="buttons-container">
          <Button
            className="button"
            color="primary"
            outline
            onClick={() => {
              onSelectClientType("new");
            }}
          >
            New client
          </Button>
          <Button
            className="button"
            color="primary"
            onClick={() => {
              onSelectClientType("returning");
            }}
          >
            Returning client
          </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientTypeSelection;
