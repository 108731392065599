import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { Media } from 'reactstrap';
import AvatarGroup from '@components/avatar-group';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { ChevronRight } from 'react-feather';
import { HOUR_FORMAT } from '@utility/Constant';
import { getEffectiveAppointmentStatus } from '../../../../../utility/Utils';
import useShop from '../../../../../utility/hooks/useShop';

const AppointmentItem = ({ selected, data, hideProviders, onClick, rightIcon, timezone, tab }) => {
  const servicesRef = useRef();
  const shop = useShop();
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsible, setCollapsible] = useState(false);
  const date = moment(data?.fromDate).tz(timezone || moment.tz.guess());
  const tz = useMemo(() => shop?.timezone || moment.tz.guess(), [shop]);
  const providers = useMemo(
    () => data?.items?.map(item => ({ title: item.metadata.providerName, img: item.metadata.providerAvatar })),
    [data?.item]
  );
  const heightServices = useMemo(() => (isMobile ? 75 : 25), [isMobile]);
  const isAppointmentPast = useMemo(
    () => data?.fromDate && moment(data?.fromDate).tz(tz).isBefore(moment(new Date()).tz(tz)),
    [data?.fromDate]
  );

  useEffect(() => {
    if (servicesRef?.current?.scrollHeight > heightServices) {
      setCollapsible(true);
      setIsCollapsed(true);
    }
  }, [servicesRef, heightServices]);

  const toggleCollapsed = e => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Media className="appointment-item align-items-center mb-1" onClick={onClick}>
      <Media left className="appointment-item-left">
        <span className="month">{date.format('MMM')}</span>
        <span className="date">{date.format('DD')}</span>
        <span className="time">{date.format(HOUR_FORMAT)}</span>
      </Media>
      <div className="appointment-item__divider" />
      <Media body className="appointment-item-right d-flex align-items-center">
        <div className="flex-grow-1">
          {!isEmpty(data?.items) && (
            <span className={cn('appointment-item__status', getEffectiveAppointmentStatus({ isPast: isAppointmentPast, status: data.status }).toUpperCase())}>
              {getEffectiveAppointmentStatus({ isPast: isAppointmentPast, status: data.status })}
            </span>
          )}
          <div
            className={cn(
              'd-flex appointment-item__container justify-content-between align-items-center position-relative',
              !hideProviders && 'mb-75'
            )}
          >
            <div
              ref={servicesRef}
              className={cn('w-100 appointment-item__content', {
                collapsed: isCollapsed,
                'collapsed-mobile': isCollapsed && isMobile,
              })}
            >
              {data?.items?.map((item, index) => (
                <div
                  key={index}
                  className={cn(
                    'd-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between flex-grow-1 appointment-item__service',
                    data?.items?.length - 1 !== index && 'mb-25'
                  )}
                >
                  <p className={cn('mb-0 font-weight-bold', selected && 'text-primary')}>{item.metadata?.productName}</p>
                  <div className="appointment-item__provider d-flex align-items-center justify-content-right">
                    <span className={cn('mr-1', selected && 'text-primary')}>{item.metadata?.providerName}</span>
                    <span className={cn('mr-1', selected && 'text-primary')}>
                      {item.metadata?.productTime}
                      <small>mins</small>
                    </span>
                    <span className={cn(selected && 'text-primary')}>${item.metadata?.productCost}</span>
                  </div>
                </div>
              ))}
            </div>
            {collapsible && (
              <span onClick={e => toggleCollapsed(e)} className={cn('position-absolute toggle-service', { 'btn-collapsed': isCollapsed })}>
                {isCollapsed ? 'see more..' : 'see less..'}
              </span>
            )}
          </div>
        </div>
        <ChevronRight />
        {!hideProviders && data?.providers && <AvatarGroup data={providers} />}
      </Media>
    </Media>
  );
};
export default AppointmentItem;
