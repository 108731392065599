import axios from 'axios';

import { CORE_API_URL } from '../utility/Constant';

const path = '/user/v1';
const metaPath = '/tenant/v2';
const appointmentPath = '/appointment/v1';

export const getCustomers = (params = {}) => {
  const { search = '', page = 0, size = 20 } = params;
  return axios.get(`${path}/customers`, { params: { search, page, size } });
};

export const getCustomerMetadata = (id, shopId) => axios.get(`${metaPath}/shop-users/${shopId}/customers/${id}`);

export const getCustomer = (id) => axios.get(`${path}/customers/${id}`);

export const getCustomerProfile = (id) => axios.get(`${path}/customers/${id}/profile`);

export const createCustomerAdmin = (data) => axios.post(`${path}/customers`, { ...(data || {}) });

export const updateCustomerAdmin = (data) => axios.put(`${path}/customers/${data.id}`, { ...(data || {}) });

export const getClient = (data) => axios.get(`${CORE_API_URL}/clients/by_user_id`, { params: data });

export const upsertClient = (data) => axios.post(`${CORE_API_URL}/clients/upsert`, { ...(data || {}) });

export const updateCustomerMetadata = (id, shopId, data) =>
  axios.put(`${metaPath}/shop-users/${shopId}/customers/${id}`, { ...(data || {}) });

export const customerAppointmentList = (id, params = {}) => {
  const { page = 0, size = 3 } = params;
  return axios.get(`${CORE_API_URL}/clients/${id}/appointments`, { params: { page, size } });
};

export const deleteCustomerAdmin = (id) => axios.delete(`${path}/customers/${id}`);
