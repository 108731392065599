import { atom } from 'recoil';
import { CookieKeys } from '../../../utility/Constant';
import { Cookie } from '../../../utility/Cookie';

const externalKey = Cookie.getItem(CookieKeys.user)?.token || localStorage.getItem('accessToken') || '';

const externalKeyState = atom({
  key: 'externalKey',
  default: externalKey,
});

export default externalKeyState;
