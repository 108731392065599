import mock from '../mock'
import { paginateArray } from '../utils'


mock.onGet('/v1/api/providers').reply(config => {
  const { q = '', size = 20, page = 0, role = 'provider', currentPlan = null, status = null } = config
  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    user => typeof (user.uuid) === 'string'
    
  )
  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        content: filteredData,
        totalElements: filteredData.length,
        number: page,
        size: size,
      }
    }
  ]
})



const data = {
  users: [
    {
      uuid: 'f745f93b-8486-4dda-8fb3-fba189fd076e',
      fullName: 'Galen Slixby',
      company: 'Yotz PVT LTD',
      role: 'provider',
      username: 'gslixby0',
      country: 'El Salvador',
      phoneNumber: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: '16b4262b-d82d-4cd8-baed-390bb46bead4',
      fullName: 'Halsey Redmore',
      company: 'Skinder PVT LTD',
      role: 'provider',
      username: 'hredmore1',
      country: 'Albania',
      phoneNumber: '(472) 607-9137',
      email: 'hredmore1@imgur.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 10,
      fullName: 'Jillene Bellany',
      company: 'Gigashots PVT LTD',
      role: 'customer',
      username: 'jbellany9',
      country: 'Jamaica',
      phoneNumber: '(589) 284-6732',
      email: 'jbellany9@kickstarter.com',
      currentPlan: 'company',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      uuid: 11,
      fullName: 'Jonah Wharlton',
      company: 'Eare PVT LTD',
      role: 'customer',
      username: 'jwharltona',
      country: 'United States',
      phoneNumber: '(176) 532-6824',
      email: 'jwharltona@oakley.com',
      currentPlan: 'team',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      uuid: 12,
      fullName: 'Seth Hallam',
      company: 'Yakitri PVT LTD',
      role: 'customer',
      username: 'shallamb',
      country: 'Peru',
      phoneNumber: '(234) 464-0600',
      email: 'shallamb@hugedomains.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/5.png').default
    },
    {
      uuid: 13,
      fullName: 'Yoko Pottie',
      company: 'Leenti PVT LTD',
      role: 'customer',
      username: 'ypottiec',
      country: 'Philippines',
      phoneNumber: '(907) 284-5083',
      email: 'ypottiec@privacy.gov.au',
      currentPlan: 'basic',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      uuid: 14,
      fullName: 'Maximilianus Krause',
      company: 'Digitube PVT LTD',
      role: 'provider',
      username: 'mkraused',
      country: 'Democratic Republic of the Congo',
      phoneNumber: '(167) 135-7392',
      email: 'mkraused@stanford.edu',
      currentPlan: 'team',
      status: 'active',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      uuid: '16b4262b-d82d-ssss-baed-390bb46bead4',
      fullName: 'Zsazsa McCleverty',
      company: 'Kaymbo PVT LTD',
      role: 'customer',
      username: 'zmcclevertye',
      country: 'France',
      phoneNumber: '(317) 409-6565',
      email: 'zmcclevertye@soundcloud.com',
      currentPlan: 'enterprise',
      status: 'active',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      uuid: 'f745f93b-8486-cccc-8fb3-fba189fd076e',
      fullName: 'Bentlee Emblin',
      company: 'Yambee PVT LTD',
      role: 'provider',
      username: 'bemblinf',
      country: 'Spain',
      phoneNumber: '(590) 606-1056',
      email: 'bemblinf@wired.com',
      currentPlan: 'company',
      status: 'active',
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      uuid: 24,
      fullName: 'Cleavland Hatherleigh',
      company: 'Flipopia PVT LTD',
      role: 'provider',
      username: 'chatherleighn',
      country: 'Brazil',
      phoneNumber: '(700) 783-7498',
      email: 'chatherleighn@washington.edu',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/2.png').default
    },
    {
      uuid: 25,
      fullName: 'Adeline Micco',
      company: 'Topicware PVT LTD',
      role: 'provider',
      username: 'amiccoo',
      country: 'France',
      phoneNumber: '(227) 598-1841',
      email: 'amiccoo@whitehouse.gov',
      currentPlan: 'enterprise',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 26,
      fullName: 'Hugh Hasson',
      company: 'Skinix PVT LTD',
      role: 'provider',
      username: 'hhassonp',
      country: 'China',
      phoneNumber: '(582) 516-1324',
      email: 'hhassonp@bizjournals.com',
      currentPlan: 'basic',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      uuid: 27,
      fullName: 'Germain Jacombs',
      company: 'Youopia PVT LTD',
      role: 'provider',
      username: 'gjacombsq',
      country: 'Zambia',
      phoneNumber: '(137) 467-5393',
      email: 'gjacombsq@jigsy.com',
      currentPlan: 'enterprise',
      status: 'active',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 28,
      fullName: 'Bree Kilday',
      company: 'Jetpulse PVT LTD',
      role: 'customer',
      username: 'bkildayr',
      country: 'Portugal',
      phoneNumber: '(412) 476-0854',
      email: 'bkildayr@mashable.com',
      currentPlan: 'team',
      status: 'active',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 29,
      fullName: 'Candice Pinyon',
      company: 'Kare PVT LTD',
      role: 'customer',
      username: 'cpinyons',
      country: 'Sweden',
      phoneNumber: '(170) 683-1520',
      email: 'cpinyons@behance.net',
      currentPlan: 'team',
      status: 'active',
      avatar: require('@src/assets/images/avatars/7.png').default
    },
    {
      uuid: 30,
      fullName: 'Isabel Mallindine',
      company: 'Voomm PVT LTD',
      role: 'customer',
      username: 'imallindinet',
      country: 'Slovenia',
      phoneNumber: '(332) 803-1983',
      email: 'imallindinet@shinystat.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 31,
      fullName: 'Gwendolyn Meineken',
      company: 'Oyondu PVT LTD',
      role: 'provider',
      username: 'gmeinekenu',
      country: 'Moldova',
      phoneNumber: '(551) 379-7460',
      email: 'gmeinekenu@hc360.com',
      currentPlan: 'basic',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      uuid: 32,
      fullName: 'Rafaellle Snowball',
      company: 'Fivespan PVT LTD',
      role: 'provider',
      username: 'rsnowballv',
      country: 'Philippines',
      phoneNumber: '(974) 829-0911',
      email: 'rsnowballv@indiegogo.com',
      currentPlan: 'basic',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/5.png').default
    },
    {
      uuid: 33,
      fullName: 'Rochette Emer',
      company: 'Thoughtworks PVT LTD',
      role: 'provider',
      username: 'remerw',
      country: 'North Korea',
      phoneNumber: '(841) 889-3339',
      email: 'remerw@blogtalkradio.com',
      currentPlan: 'basic',
      status: 'active',
      avatar: require('@src/assets/images/avatars/8.png').default
    },
    {
      uuid: 34,
      fullName: 'Ophelie Fibbens',
      company: 'Jaxbean PVT LTD',
      role: 'customer',
      username: 'ofibbensx',
      country: 'Indonesia',
      phoneNumber: '(764) 885-7351',
      email: 'ofibbensx@booking.com',
      currentPlan: 'company',
      status: 'active',
      avatar: require('@src/assets/images/avatars/4.png').default
    },
    {
      uuid: 35,
      fullName: 'Stephen MacGilfoyle',
      company: 'Browseblab PVT LTD',
      role: 'customer',
      username: 'smacgilfoyley',
      country: 'Japan',
      phoneNumber: '(350) 589-8520',
      email: 'smacgilfoyley@bigcartel.com',
      currentPlan: 'company',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 36,
      fullName: 'Bradan Rosebotham',
      company: 'Agivu PVT LTD',
      role: 'customer',
      username: 'brosebothamz',
      country: 'Belarus',
      phoneNumber: '(882) 933-2180',
      email: 'brosebothamz@tripadvisor.com',
      currentPlan: 'team',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 37,
      fullName: 'Skip Hebblethwaite',
      company: 'Katz PVT LTD',
      role: 'provider',
      username: 'shebblethwaite10',
      country: 'Canada',
      phoneNumber: '(610) 343-1024',
      email: 'shebblethwaite10@arizona.edu',
      currentPlan: 'company',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/9.png').default
    },
    {
      uuid: 38,
      fullName: 'Moritz Piccard',
      company: 'Twitternation PVT LTD',
      role: 'customer',
      username: 'mpiccard11',
      country: 'Croatia',
      phoneNumber: '(365) 277-2986',
      email: 'mpiccard11@vimeo.com',
      currentPlan: 'enterprise',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      uuid: 39,
      fullName: 'Tyne Widmore',
      company: 'Yombu PVT LTD',
      role: 'customer',
      username: 'twidmore12',
      country: 'Finland',
      phoneNumber: '(531) 731-0928',
      email: 'twidmore12@bravesites.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 40,
      fullName: 'Florenza Desporte',
      company: 'Kamba PVT LTD',
      role: 'provider',
      username: 'fdesporte13',
      country: 'Ukraine',
      phoneNumber: '(312) 104-2638',
      email: 'fdesporte13@omniture.com',
      currentPlan: 'company',
      status: 'active',
      avatar: require('@src/assets/images/avatars/6.png').default
    },
    {
      uuid: 41,
      fullName: 'Edwina Baldetti',
      company: 'Dazzlesphere PVT LTD',
      role: 'customer',
      username: 'ebaldetti14',
      country: 'Haiti',
      phoneNumber: '(315) 329-3578',
      email: 'ebaldetti14@theguardian.com',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 42,
      fullName: 'Benedetto Rossiter',
      company: 'Mybuzz PVT LTD',
      role: 'provider',
      username: 'brossiter15',
      country: 'Indonesia',
      phoneNumber: '(323) 175-6741',
      email: 'brossiter15@craigslist.org',
      currentPlan: 'team',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 43,
      fullName: 'Micaela McNirlan',
      company: 'Tambee PVT LTD',
      role: 'provider',
      username: 'mmcnirlan16',
      country: 'Indonesia',
      phoneNumber: '(242) 952-0916',
      email: 'mmcnirlan16@hc360.com',
      currentPlan: 'basic',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 44,
      fullName: 'Vladamir Koschek',
      company: 'Centimia PVT LTD',
      role: 'provider',
      username: 'vkoschek17',
      country: 'Guatemala',
      phoneNumber: '(531) 758-8335',
      email: 'vkoschek17@abc.net.au',
      currentPlan: 'team',
      status: 'active',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 45,
      fullName: 'Corrie Perot',
      company: 'Flipopia PVT LTD',
      role: 'customer',
      username: 'cperot18',
      country: 'China',
      phoneNumber: '(659) 385-6808',
      email: 'cperot18@goo.ne.jp',
      currentPlan: 'team',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/3.png').default
    },
    {
      uuid: 46,
      fullName: 'Saunder Offner',
      company: 'Skalith PVT LTD',
      role: 'customer',
      username: 'soffner19',
      country: 'Poland',
      phoneNumber: '(200) 586-2264',
      email: 'soffner19@mac.com',
      currentPlan: 'enterprise',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 47,
      fullName: 'Karena Courtliff',
      company: 'Feedfire PVT LTD',
      role: 'provider',
      username: 'kcourtliff1a',
      country: 'China',
      phoneNumber: '(478) 199-0020',
      email: 'kcourtliff1a@bbc.co.uk',
      currentPlan: 'basic',
      status: 'active',
      avatar: require('@src/assets/images/avatars/1.png').default
    },
    {
      uuid: 48,
      fullName: 'Onfre Wind',
      company: 'Thoughtmix PVT LTD',
      role: 'provider',
      username: 'owind1b',
      country: 'Ukraine',
      phoneNumber: '(344) 262-7270',
      email: 'owind1b@yandex.ru',
      currentPlan: 'basic',
      status: 'pending',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 49,
      fullName: 'Paulie Durber',
      company: 'Babbleblab PVT LTD',
      role: 'customer',
      username: 'pdurber1c',
      country: 'Sweden',
      phoneNumber: '(694) 676-1275',
      email: 'pdurber1c@gov.uk',
      currentPlan: 'team',
      status: 'inactive',
      avatar: require('@src/assets/images/avatars/10.png').default
    },
    {
      uuid: 50,
      fullName: 'Beverlie Krabbe',
      company: 'Kaymbo PVT LTD',
      role: 'provider',
      username: 'bkrabbe1d',
      country: 'China',
      phoneNumber: '(397) 294-5153',
      email: 'bkrabbe1d@home.pl',
      currentPlan: 'company',
      status: 'active',
      avatar: require('@src/assets/images/avatars/9.png').default
    }
  ]
}