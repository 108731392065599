import { atom } from 'recoil';
import { CookieKeys } from '../../../utility/Constant';
import { Cookie } from '../../../utility/Cookie';

const client = null;
const clientState = atom({
  key: 'client',
  default: client,
});

export default clientState;
