import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Spinner, Popover, PopoverHeader, PopoverBody, CustomInput } from 'reactstrap';
import ReactDOM from 'react-dom';
import { useRecoilState } from 'recoil';
import momentTz from 'moment-timezone';
import moment from 'moment';
import * as Sentry from "@sentry/react";
import toast from '@components/toast';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { remove, cloneDeep } from 'lodash';
import { getAppointmentProducts, getSpecies, getAvailableDates, createCalendarBlock, deleteCalendarBlock } from '../../../../../apis/appointment.api';
import { getStaffs } from '../../../../../apis/new-staff.api';
import shopState from '../../../../../recoil/atoms/current-shop';
import userState from '../../../../../recoil/atoms/auth/user';
import OliverDropdown from '../../../../components/dropdowns/OliverDropdown';
import ProductDropdown from '../../../../components/dropdowns/ProductDropdown';
import { ChevronLeft } from 'react-feather';
import { defaultSelectedProduct } from '../../../../../recoil/atoms/booking/booking';
import { getClient } from '../../../../../apis/customer.api';
import NavbarSms from '../../../../../@core/layouts/components/navbar/NavbarSms';
import AvailableDatesPicker from '../AvailableDatesPicker';
import clientState from '../../../../../recoil/atoms/booking/client';
import { isReturningClient } from '../returningClientHelper';
import { borderRadius } from 'polished';

const AppointmentSelection = ({ externalKey, step, goNextStep, booking, setBooking, backPreviousStep }) => {
  const [shop] = useRecoilState(shopState);
  const [user, setUser] = useRecoilState(userState);
  const [client, setClient] = useRecoilState(clientState);

  const [defaultTimezone, setDefaultTimezone] = useState(null);

  const [bookingPolicyOpen, setBookingPolicyOpen] = useState(false);

  const [availableSpecies, setAvailableSpecies] = useState([]);
  const [selectedSpecies, setSelectedSpecies] = useState(booking?.species || null);

  const [availableAppointmentTypes, setAvailableAppointmentTypes] = useState([]);

  const [allProviders, setAllProviders] = useState([]);
  const [availableProviders, setAvailableProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(booking?.selectedProducts?.length > 0 ? booking?.selectedProducts[0].provider : null);

  const [availableDates, setAvailableDates] = useState([]);
  const [hasOpenedDatePicker, setHasOpenedDatePicker] = useState(false);
  const [targetDate, setTargetDate] = useState(moment().format('MM/DD/YYYY'));
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);

  const placeholderAvailableTimes = [[{label: "9:00 AM", enabled: false}, {label: "10:00 AM", enabled: false}, {label: "11:00 AM", enabled: false}], [{label: "12:00 PM", enabled: false}, {label: "1:00 PM", enabled: false}, {label: "2:00 PM", enabled: false}], [{label: "3:00 PM", enabled: false}, {label: "4:00 PM", enabled: false}, {label: "5:00 PM", enabled: false}]];
  const [availableTimes, setAvailableTimes] = useState(placeholderAvailableTimes);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showWaitlistHint, setShowWaitlistHint] = useState(false);

  const [showDropoffToggle, setShowDropoffToggle] = useState(false);
  const [isSavingBlockedTime, setIsSavingBlockedTime] = useState(false);

  const daysToCheck = 100;

  const [selectedProduct, setSelectedProduct] = useState((() => {
    if (booking?.selectedProducts?.length > 0) {
      return booking.selectedProducts[0];
    }
    return defaultSelectedProduct;
  }));
  const [selectedProductId, setSelectedProductId] = useState((() => {
    if (booking?.selectedProducts?.length > 0 && booking?.selectedProducts[0]?.products?.length > 0) {
      return booking.selectedProducts[0].products[0].id;
    }
    return null;
  }));
  const [productResetCode, setProductResetCode] = useState(Math.random());
  const [providerResetCode, setProviderResetCode] = useState(Math.random());

  const anySelectionsMade = () => {
    return selectedProduct?.products?.length > 0 || selectedProvider !== null ||
      selectedSpecies !== null || selectedTime !== null ||
      hasOpenedDatePicker;
  };

  const resetDateOnNewSelectionMade = () => {
    setTargetDate(moment().format('MM/DD/YYYY'));
    setSelectedDate(null);
    setFormattedDate(null);
    setSelectedTime(null);
    setHasOpenedDatePicker(false);
    setAvailableTimes(placeholderAvailableTimes);
    setAvailableDates([]);
  };

  useEffect(() => {
    if (user) {
      setBooking({
        ...booking,
        returningClientFlow: true, // Base it off the existence of user here instead of isReturningClient(client) because it determines whether we auth next step or not
      });
    }
  }, [user]);

  const isMultiLocationEnabled = () => {
    if (shop?.feature_settings?.multi_location_v1_enabled !== undefined) return shop.feature_settings.multi_location_v1_enabled;
    return false;
  };

  const holdTimeSlotsEnabled = () => {
    if (shop?.feature_settings?.hold_time_slots_enabled !== undefined) return shop.feature_settings.hold_time_slots_enabled;
    return false;
  };

  const isNewClientBookingDisabled = () => {
    if (shop?.feature_settings?.new_client_booking_enabled === undefined || shop.feature_settings.new_client_booking_enabled === true) return false;
    return true;
  };

  useEffect(() => {
    if (shop?.metadata?.bookingPolicy && !anySelectionsMade()) {
      setBookingPolicyOpen(true);
    }
    if (shop) {
      setDefaultTimezone(shop.timezone);
      Sentry.metrics.increment("booking_choose_product", 1, {
        tags: { shop: shop?.name, user: user?.id },
      });
    }
  }, [shop]);

  const { data, isFetching: isFetchingAppointmentTypes, isLoading: isLoadingAppointmentTypes } = useQuery(
    'products',
    async () => {
      const { data } = await getAppointmentProducts({ size: 100000, showDetail: true });
      return data.data.content.sort((a, b) => a.orderIndex - b.orderIndex) || [];
    },
    {
      enabled: !!externalKey
    }
  );

  const { data: _client, isFetching: isFetchingClient, isLoading: isLoadingClient } = useQuery(
    'client',
    async () => {
      const { data } = await getClient({ user_id: user?.id });
      return data;
    },
    {
      onSuccess: result => {
        if (result) {
          setClient(result);
          setBooking(prev => ({
            ...prev,
            customer: {
              firstName: `${result.first_name} ${result.last_name}`,
              email: result.email,
              phoneNumber: result.phone,
              address1: result.address1,
              address2: result.address2,
              city: result.city,
              state: result.state,
              zip: result.zip,
              created_at: result.created_at,
              patients: result.patients
            }
          }));
        }
      },
      onError: () => {
      },
      enabled: !!user?.id
    }
  );

  const { isFetching: isFetchingSpecies, isLoading: isLoadingSpecies } = useQuery(
    'species',
    async () => {
      const { data: { data } } = await getSpecies({ size: 100000 });
      return data
    },
    {
      enabled: !!externalKey && !!data?.length && data.length > 0,
      onSuccess: result => {
        if (result && result.length > 0) {
          const available = [];
          data?.forEach(appointment_type => {
            if (!appointment_type.hidden_from_clients) {
              appointment_type.metadata.speciesIds.forEach(speciesId => {
                if (!available.includes(speciesId)) {
                  available.push(speciesId);
                }
              });
            }
          });
          const species = result.filter(item => available.includes(item.id));
          if (species.length > 0) {
            setAvailableSpecies(species);
          } else {
            setAvailableSpecies(result);
          }
        }
      }
    }
  );

  const formatAvailableTimes = (times) => {
    const result = [];
    for (let i = 0; i < times.length; i += 3) {
      result.push(times.slice(i, i + 3));
    }
    setAvailableTimes(result);
  };

  const fetchAvailableDates = async (queryKey) => {
    const { providerId, productId, providedDate, is_dropoff, page } = queryKey;
    let formattedDate = null;
    if (page) {
      formattedDate = momentTz.tz(momentTz(page), defaultTimezone).utc().toISOString()
    } else if (providedDate) {
      formattedDate = momentTz.tz(momentTz(providedDate), defaultTimezone).utc().toISOString()
    } else {
      formattedDate = momentTz.tz(momentTz(), defaultTimezone).utc().toISOString()
    }
    const params = { productIds: [productId], date: formattedDate, is_dropoff: !!is_dropoff };
    if (providerId) {
      params.providerIds = [providerId];
    }
    if (isMultiLocationEnabled() && booking?.location?.id) {
      params.location_id = booking.location.id;
    }
    params.days_to_check = 4;
    params.max_attempts = daysToCheck;
    if (page) {
      params.days_to_check = 8;
    }
    if (page || providedDate) {
      params.exact_start_date = formattedDate;
    }
    const { data: { data } } = await getAvailableDates(params);
    return data
  };

  const formatDropoffTimes = (times) => {
    if (shop?.feature_settings?.dropoff_time_booking_widget_labels !== undefined) {
      const setting = shop.feature_settings.dropoff_time_booking_widget_labels;
      if (setting.enabled) {
        const dropoffTimes = [];
        if (setting.am_label) {
          const firstTime = !setting.pm_label ? times[0] : times.find(time => time.label.includes('am'));
          if (firstTime) {
            firstTime.label = setting.am_label;
            dropoffTimes.push(firstTime);
          }
        }
        if (setting.pm_label) {
          const pmTime = !setting.am_label ? times[0] : times.find(time => time.label.includes('pm'));
          if (pmTime) {
            pmTime.label = setting.pm_label;
            dropoffTimes.push(pmTime);
          }
        }
        formatAvailableTimes(dropoffTimes);
        return;
      }
    }
    formatAvailableTimes(times);
  };

  const makeUniqueArray = (arr, field) => {
    const uniqueValues = {};
    return arr.filter(obj => {
      const value = obj[field];
      if (uniqueValues[value]) {
        return false;
      }
      uniqueValues[value] = true;
      return true;
    });
  };

  const {
    _data = { pages: [] },
    isFetching: isFetchingDates,
    isLoading: isLoadingDates,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    ['dates', `${selectedProductId}-${productResetCode}`, `${selectedProvider?.id}-${providerResetCode}`, booking?.isDropoff],
    ({ pageParam }) => fetchAvailableDates({
      providerId: selectedProvider?.id === 'any-provider' ? null : selectedProvider?.id,
      productId: selectedProductId,
      providedDate: targetDate,
      is_dropoff: !!booking?.isDropoff,
      page: pageParam
    }),
    {
      initialPageParam: targetDate ? moment(targetDate).format('MM/DD/YYYY') : null,
      getNextPageParam: lastPage => {
        const lastData = lastPage[lastPage.length - 1];
        const useTimeSlots = lastData?.use_time_slots;
        const lastDate = lastData.date;
        const searchFromDate = targetDate ? moment(targetDate) : moment();
        if (useTimeSlots || moment(lastDate).isAfter(searchFromDate.add(daysToCheck + 30, 'days'))) {
          return null;
        }
        const nextPage = moment(lastDate).add(1, 'days').format('MM/DD/YYYY');
        return nextPage;
      },
      enabled: !!externalKey && selectedProvider !== null && selectedProduct !== null && !!selectedProductId,
      onSuccess: data => {
        if (data && data.pages) {
          const dates = makeUniqueArray(data.pages.map(page => page).flat(), 'date');
          setAvailableDates(dates);
          const earliestAvailableDate = dates.find(date => date.available);
          if (earliestAvailableDate) {
            const availableSelectedDate = selectedDate ? dates.find(date => date.available && date.date === moment(selectedDate).format('MM/DD/YYYY')) : null;
            if (availableSelectedDate && hasOpenedDatePicker) {
              if (booking.isDropoff) {
                formatDropoffTimes(availableSelectedDate.times);
              } else {
                formatAvailableTimes(availableSelectedDate.times);
              }
            } else {
              setSelectedDate(moment(earliestAvailableDate.date, 'MM/DD/YYYY').toISOString());
              if (booking.isDropoff) {
                formatDropoffTimes(earliestAvailableDate.times);
              } else {
                formatAvailableTimes(earliestAvailableDate.times);
              }
            }
            const lastAvailableDate = dates.filter(date => date.available).sort((a, b) => new Date(a.date) - new Date(b.date)).reverse()[0];
            if (moment(targetDate, 'MM/DD/YYYY').add(daysToCheck, 'days').isAfter(lastAvailableDate.date) && !lastAvailableDate?.use_time_slots) {
              fetchNextPage();
            }
          } else {
            Sentry.captureException(new Error(`No available dates found for ${shop?.name}, ${selectedProvider?.firstName}, product ${selectedProduct?.products[0]?.id}, date: ${targetDate}, dropoff: ${booking?.isDropoff}`));
            setAvailableTimes([]);
          }
        } else {
          setAvailableDates([]);
          setAvailableTimes([]);
        }
      }
    }
  );

  const waitlistEnabled = useMemo(() => {
    if (shop?.feature_settings?.waitlist_ui_v1_enabled !== undefined) {
      return shop.feature_settings.waitlist_ui_v1_enabled === true && booking?.selectedProducts[0]?.products[0]?.metadata?.waitlistEligible;
    } else {
      return false;
    }
  }, [shop, booking?.selectedProducts[0]?.products[0]]);

  useEffect(() => {
    if (availableDates.length > 0 && !isLoadingDates) {
      const firstAvailableDate = availableDates.filter(d => d.available).sort((a, b) => new Date(a.date) - new Date(b.date))[0];
      if (waitlistEnabled && firstAvailableDate && moment(firstAvailableDate.date) > moment().add(7, 'days')) {
        setShowWaitlistHint(true);
      } else {
        setShowWaitlistHint(false);
      }
    } else {
      setShowWaitlistHint(false);
    }
  }, [availableDates, isLoadingDates, waitlistEnabled]);

  const {
    isFetching: isFetchingProviders,
    isLoading: isLoadingProviders,
  } = useQuery(
    'providers',
    async () => {
      const result = await getStaffs({ size: 10000, showPrivate: false });
      return result?.data?.data?.content;
    },
    {
      enabled: !!externalKey && !!shop,
      onSuccess: data => {
        let tmp = cloneDeep(data);
        tmp = tmp.sort((a, b) => a.metadata.order - b.metadata.order);
        if (shop?.metadata?.isBookingAnyone) {
          tmp.unshift({ id: 'any-provider', firstName: "Any clinician"});
        }
        setAllProviders(tmp);
      }
    }
  );

  const refetchDates = (opening) => {
    const formattedDate = opening.date;
    setTargetDate(formattedDate);
    const lastAvailableDate = availableDates.filter(date => date.available).sort((a, b) => new Date(a.date) - new Date(b.date)).reverse()[0];
    if (!lastAvailableDate || moment(formattedDate).add(daysToCheck, 'days').isAfter(lastAvailableDate.date)) {
      fetchNextPage();
    }
  };

  const createCalendarBlockMutation = useMutation(createCalendarBlock);

  const onHoldTimeSlot = (timeSlot) => {
    if (booking.selectedProducts?.length === 0 || !booking.selectedProducts[0]?.products?.length) goNextStep();
    const productLength = booking.selectedProducts[0]?.products[0]?.metadata?.time;
    if (!productLength) goNextStep();
    const toDate = moment(timeSlot.dateTime).add(productLength, 'minutes').toISOString();
    setIsSavingBlockedTime(true);
    const payload = {
      provider_id: timeSlot.provider_id,
      room_id: timeSlot.room_id,
      expire: true,
      last_modified_by: user?.id,
      last_modified_date: moment().toISOString(),
      title: 'Online appointment booking is pending.',
      from_date: timeSlot.dateTime,
      to_date: toDate,
    };
    createCalendarBlockMutation.mutate(payload, {
      onSuccess: (data) => {
        if (data?.data?.id) {
          setBooking(prev => ({ ...prev, time_slot_calendar_id: data.data.id }));
          goNextStep();
        } else {
          toast.error('We\'re sorry, this time is no longer available. Please choose another time.');
          const tmpTimes = cloneDeep(availableTimes.flat().map((time) => {
            if (time.dateTime === timeSlot.dateTime) time.enabled = false;
            return time;
          }));
          formatAvailableTimes(tmpTimes);
        }
        setIsSavingBlockedTime(false);
      },
      onError: () => {
        setIsSavingBlockedTime(false);
        toast.error('We\'re sorry, this time is no longer available. Please choose another time.');
        goNextStep();
      }
    });
  };

  const selectTimeSlot = (timeSlot) => {
    const time = timeSlot.value;
    const tmp = cloneDeep(booking);
    const dateTime = momentTz.tz(`${moment(selectedDate).format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm', defaultTimezone).toISOString();
    tmp.dateTime = dateTime;
    tmp.dateTimeProviderId = timeSlot.provider_id;
    tmp.roomId = timeSlot.room_id;
    if (booking.isDropoff) {
      const label = timeSlot.label;
      tmp.dropoffLabel = label;
    }
    // Set required fields if not selected
    if (!tmp.species) tmp.species = { selectedSpecies };
    if (!tmp.returningClientFlow) tmp.returningClientFlow = false;
    setBooking(tmp);
    Sentry.metrics.increment("booking_select_time_slot", 1, {
      tags: { shop: shop?.name, user: user?.id },
    });
    if (holdTimeSlotsEnabled()) {
      onHoldTimeSlot(timeSlot);
    } else {
      goNextStep();
    }
  };

  const filterAppointmentTypes = (allAppointmentTypes, species) => {
    if (allAppointmentTypes === undefined || !species) return [];
    const filtered = allAppointmentTypes.filter(item => {
      const speciesIds = item.metadata?.speciesIds;
      if (speciesIds) {
        return speciesIds.includes(species.id);
      } else {
        return false;
      }
    }).filter(item => {
      if (!!item.hidden_from_clients) {
        return false;
      }
      if (isReturningClient(client) && !item.metadata?.allowReturningClients) {
        return false;
      }
      if (!isReturningClient(client) && !item.metadata?.allowNewClients) {
        return false;
      }
      return item;
    });
    if (isMultiLocationEnabled() && booking.location) {
      return filtered.filter((item) => {
        return booking.location.product_ids.includes(item.id);
      });
    }
    return filtered;
  };

  const disableAvailableTimes = () => {
    const tmp = availableTimes.map(time => {
      return {
        dateTime: time.dateTime,
        label: time.label,
        value: time.value,
        enabled: false
      }
    });
    if (booking.isDropoff) {
      formatDropoffTimes(tmp);
    } else {
      formatAvailableTimes(tmp);
    }
  };

  const isItemChecked = (item) => {
    return !!selectedProduct.products.find(product => { return product.id === item.id })
  };

  const formatProviderTitle = () => {
    if (selectedProduct?.products?.length === 0) { // button is disabled
      return 'Clinician';
    } else if (selectedProvider) {
      return `${selectedProvider?.firstName}`;
    } else {
      return 'Choose your clinician';
    }
  };

  const formatSpeciesTitle = () => {
    if (selectedSpecies) {
      return `${selectedSpecies?.name}`;
    } else {
      return 'Select pet species';
    }
  };

  const filterProviders = (providerIds, isDropoff = false) => {
    let filtered = [];
    if (isDropoff) {
      filtered = allProviders.filter(provider => (providerIds.includes(provider.id) && (!!booking?.isDropoff ? provider.maxDropoffs > 0 : true)) || provider.id === 'any-provider');
    } else {
      filtered = allProviders.filter(provider => providerIds.includes(provider.id) || provider.id === 'any-provider');
    }
    if (isMultiLocationEnabled() && booking.location) {
      return filtered.filter((item) => {
        return booking.location.provider_user_ids.includes(item.id) || item.id === 'any-provider';
      });
    }
    return filtered;
  };

  const chooseProduct = (product) => {
    const products = [product];
    setSelectedProduct({ ...selectedProduct, products });
    setSelectedProductId(product.id);
    setProductResetCode(Math.random());

    const tmp = cloneDeep(booking);
    tmp.selectedProducts[tmp.selectedProducts.length - 1] = {
      ...selectedProduct,
      products
    };
    if (product.dropoffEnabled && !product.dropoffRequired) {
      setShowDropoffToggle(true);
    } else {
      setShowDropoffToggle(false);
    }
    if (product.dropoffEnabled && product.dropoffRequired) {
      tmp.isDropoff = true;
    } else {
      tmp.isDropoff = false;
    }

    if (!shop?.metadata?.isBookingSkipProvider) {
      const providerIds = product.providerIds;
      if (providerIds && providerIds.length > 0) {
        setAvailableProviders(filterProviders(providerIds));
        if (selectedProvider && !providerIds.includes(selectedProvider.id)) {
          setSelectedProvider(null);
          disableAvailableTimes();
        }
      } else {
        setSelectedProvider(null);
        disableAvailableTimes();
      }
    } else {
      const provider = { id: 'any-provider', firstName: "Any clinician"};
      setSelectedProvider(provider);
      disableAvailableTimes();
      tmp.selectedProducts[tmp.selectedProducts.length - 1] = {
        ...selectedProduct,
        products,
        provider
      };
    }
    resetDateOnNewSelectionMade();
    setBooking(tmp);
  };

  const uncheckProduct = (product) => {
    const products = remove([...selectedProduct.products], item => item.id !== product.id);
    setSelectedProduct({ ...selectedProduct, products });
    setSelectedProductId(null);

    const tmp = cloneDeep(booking);
    tmp.selectedProducts[tmp.selectedProducts.length - 1] = {
      ...selectedProduct,
      products
    };
    setShowDropoffToggle(false);
    tmp.isDropoff = false;
    setBooking(tmp);

    if (products.length === 0 && selectedProvider) {
      setSelectedProvider(null);
      disableAvailableTimes();
    }
    resetDateOnNewSelectionMade();
  };

  const selectSpecies = (species) => {
    if (selectedSpecies) {
      const product = [];
      setSelectedProduct({ ...selectedProduct, products: product });
      setSelectedProductId(null);
      if (selectedProvider) {
        setSelectedProvider(null);
        disableAvailableTimes();
      }
    }
    resetDateOnNewSelectionMade();
    setSelectedSpecies(species);
    const availableApptTypes = filterAppointmentTypes(data, species);
    setAvailableAppointmentTypes(availableApptTypes);
    // if (availableApptTypes.length === 1) {
    //   chooseProduct(availableApptTypes[0]);
    // }
    setBooking({
      ...booking,
      species
    });
  }

  const chooseProvider = (provider) => {
    if (provider.id === selectedProvider?.id) {
      return;
    }
    setAvailableDates([]);
    const tmp = cloneDeep(booking);
    tmp.selectedProducts[tmp.selectedProducts.length - 1] = {
      ...selectedProduct,
      provider
    };
    disableAvailableTimes();
    resetDateOnNewSelectionMade();
    setSelectedProvider(provider);
    setProviderResetCode(Math.random());
    setBooking(tmp);
  };

  const timeSlotLabelText = () => {
    if (isLoadingDates) {
      return 'Please wait while we find openings. This may take a minute.';
    } else {
      if (hasOpenedDatePicker) {
        return 'Appointment Times';
      } else {
        return 'Next Available Appointments';
      }
    }
  };

  const onSelectDate = (opening) => {
    setHasOpenedDatePicker(true);
    if (!opening || !opening.available) {
      const firstAvailableDate = availableDates.find(d => d.available);
      if (!firstAvailableDate || !firstAvailableDate.available) {
        toast.error('No appointments available. Please select a different date.');
        return;
      }
      const formattedValue = firstAvailableDate;
      setSelectedDate(moment(formattedValue).tz(defaultTimezone).toISOString());
    } else {
      const formattedValue = opening.date;
      setSelectedDate(moment(formattedValue).tz(defaultTimezone).toISOString());
    }
  };

  const formatProductName = (products) => {
    if (availableAppointmentTypes.length === 0 && selectedSpecies === null) { // disabled
      return 'Appointment Type';
    }
    if (products?.length === 1) {
      return `${products[0].name}`;
    } else {
      return "Choose the appointment type";
    }
  };

  useEffect(() => {
    if (client && client.created_at) {
      const isReturning = isReturningClient(client);
      if (!isReturning && isNewClientBookingDisabled()) {
        goNextStep();
      }
    }
    const species = selectedSpecies || booking?.species;
    const availableApptTypes = filterAppointmentTypes(data, species);
    setAvailableAppointmentTypes(availableApptTypes);
  }, [client?.created_at, booking?.location?.id]);

  useEffect(() => {
    if (booking?.location?.id && selectedProduct?.products?.length > 0) {
      uncheckProduct(selectedProduct?.products[0]);
    }
  }, [booking?.location?.id]);

  useEffect(() => {
    if ((selectedSpecies || booking?.species) && availableAppointmentTypes?.length === 0) {
      const species = selectedSpecies || booking?.species;
      const availableApptTypes = filterAppointmentTypes(data, species);
      setAvailableAppointmentTypes(availableApptTypes);
      // if (availableApptTypes.length === 1) {
      //   chooseProduct(availableApptTypes[0]);
      // }
    }

    if ((selectedProduct?.products?.length > 0 || booking?.selectedProduct?.products.length > 0) && availableProviders?.length === 0) {
      const product = selectedProduct.products[0] || booking?.selectedProduct.products[0];
      const providerIds = product.providerIds;
      if (providerIds && providerIds.length > 0) {
        setAvailableProviders(filterProviders(providerIds));
      }
    }
  });

  useEffect(() => {
    if (selectedProduct?.products?.length > 0 || booking?.selectedProduct?.products.length > 0) {
      const product = selectedProduct.products[0] || booking?.selectedProduct.products[0];
      const providerIds = product.providerIds;
      if (providerIds && providerIds.length > 0) {
        setAvailableProviders(filterProviders(providerIds, booking?.isDropoff));
      }
    }
  }, [booking?.isDropoff]);

  const toggleBookingPolicy = () => {
    setBookingPolicyOpen(!bookingPolicyOpen);
  };

  const contentStyle = () => {
    if (isFetchingAppointmentTypes || isLoadingAppointmentTypes || isFetchingSpecies || isLoadingSpecies) {
      return { display: 'none' };
    }
    return {};
  };

  const waitToShow = () => {
    return (isFetchingAppointmentTypes || isLoadingAppointmentTypes || isFetchingSpecies || isLoadingSpecies);
  };

  return (
    <>
      {waitToShow() && (
        <div className="d-flex justify-content-center mt-2 mb-2">
          <Spinner color="primary" />
        </div>
      )}
      <div className={`position-relative flex-grow-1 products-container`} style={contentStyle()}>
        {((!isMultiLocationEnabled() && !user) || isMultiLocationEnabled()) ? (
          <div className="d-flex align-items-center booking-header" style={{ paddingTop: '8px' }}>
            <Button id="choose-product-back-btn" className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
              <ChevronLeft />
            </Button>
          </div>
        ) : <div className='mt-2'/>}
        {bookingPolicyOpen &&
          shop?.metadata?.bookingPolicy && !anySelectionsMade() &&
          document.getElementById('wrapper') &&
          ReactDOM.createPortal(
            <div className="modal-backdrop fade" style={{ opacity: 0.5 }} />,
            document.getElementById('wrapper')
        )}
        <div id="new-client" />
        <div className="d-flex align-items-center justify-content-between mt-md-0">
          {!waitToShow() && shop?.metadata?.bookingPolicy && !anySelectionsMade() && (
            <>
              <div className="px-0" />
              <Popover
                className="w-100 booking-policies-popover"
                placement="bottom-center"
                isOpen={bookingPolicyOpen}
                delay={0}
                fade={false}
                target="new-client"
                toggle={toggleBookingPolicy}
                trigger="legacy"
              >
                <PopoverHeader className="position-relative">
                  Our Appointment Policy
                  <Button
                    className="btn-icon position-absolute h-100"
                    color="flat"
                    style={{ color: '#313131', backgroundColor: 'transparent', top: 0, right: 0 }}
                    size="sm"
                    onClick={toggleBookingPolicy}
                  >
                    {/* <X color="black" size="20" /> */}
                    <div></div>
                  </Button>
                </PopoverHeader>
                <PopoverBody>
                  <p>{shop?.metadata?.bookingPolicy}</p>
                  <div className="text-right">
                    <Button color="primary" style={{ borderRadius: '25px' }} onClick={toggleBookingPolicy}>
                      Okay
                    </Button>
                  </div>
                </PopoverBody>
              </Popover>
            </>
          )}
        </div>
        <Row className="section-label-text pl-2">
          Appointment Information
        </Row>
        <Row className="justify-content-center align-items-center mt-1">
          <OliverDropdown
            title={formatSpeciesTitle()}
            items={!isFetchingSpecies && !isLoadingSpecies ? availableSpecies : []}
            selectedItem={selectedSpecies}
            onChange={selectSpecies}
          />
        </Row>
        <Row className="justify-content-center align-items-center mt-0">
          <ProductDropdown
            disabled={availableAppointmentTypes.length === 0 && selectedSpecies === null}
            title={formatProductName(selectedProduct.products)}
            items={!isFetchingAppointmentTypes && !isLoadingAppointmentTypes ? availableAppointmentTypes : []}
            selectedItem={isItemChecked}
            onChange={chooseProduct}
            shop={shop}
          />
        </Row>
        {showDropoffToggle && (
          <Row className="justify-content-center align-items-center mt-0 mb-1 px-2">
            <CustomInput
              type="checkbox"
              id="dropoff"
              name="dropoff"
              label="I would like to drop my pet off for this appointment and pick them up afterward."
              onChange={(e) => setBooking({ ...booking, isDropoff: e.target.checked })}
              checked={booking.isDropoff}
              disabled={selectedProduct?.products?.length === 0}
            />
          </Row>
        )}
        {!shop?.metadata?.isBookingSkipProvider && (
          <Row className="justify-content-center align-items-center mt-0">
            <OliverDropdown
              disabled={selectedProduct?.products?.length === 0}
              title={formatProviderTitle()}
              items={!isFetchingProviders && !isLoadingProviders ? availableProviders : []}
              selectedItem={selectedProvider}
              onChange={chooseProvider}
              contentTitle="firstName"
            />
          </Row>
        )}
        <Row className="justify-content-center align-items-center mt-0">
          <AvailableDatesPicker
            disabled={selectedProvider === null || availableDates.length === 0 || availableTimes.length === 0}
            loadedDates={availableDates}
            refetchDates={refetchDates}
            selectedDate={selectedDate}
            setSelectedDate={onSelectDate}
            setFormattedDate={setFormattedDate}
            selectedProduct={selectedProduct?.products?.length > 0 ? selectedProduct.products[0].name : null}
            isLoadingDates={isLoadingDates}
            isFetchingDates={isFetchingDates}
            onChange={booking?.isDropoff ? formatDropoffTimes : formatAvailableTimes}
          />
        </Row>
        <Row className="section-label-text pl-2 mt-0">
          <span>
            {timeSlotLabelText()}
            {showWaitlistHint && (
              <Row className="justify-content-center align-items-center pl-1 mt-1 w-100">
              <p className="section-label-text text-secondary mb-0">Available times too far out? After you schedule an appointment you can join our waitlist and be notified if something opens up earlier!</p>
            </Row>
            )}
            {(isLoadingDates || isSavingBlockedTime) && <Spinner className="ml-1 align-items-center" color="primary" size="sm" />}
          </span>
        </Row>
        <Row className="mx-0 mt-0">
          <div className="custom-time-button-container">
            {availableTimes.map((row, rowIndex) => (
              row.map((item, colIndex) => (
                <Button
                  id={`${rowIndex}-${colIndex}-time`}
                  key={`${rowIndex}-${colIndex}-time-key`}
                  className="custom-time-button"
                  color="primary"
                  disabled={!item.enabled || isSavingBlockedTime || (availableTimes.length === 0 && (isLoadingDates || isFetchingDates))}
                  onClick={() => selectTimeSlot(item)}
                >
                  {item.label}
                </Button>
              ))
            ))}
            {availableTimes.length === 0 && (
              <>
                <p className="section-label-text pt-1" style={{ opacity: 0.5 }}>We're sorry, there are currently no available times online for this appointment type</p>
                <NavbarSms buttonText="text us" />
              </>
            )}
          </div>
          {availableTimes.length > 0 && (
            <Row className="justify-content-center align-items-center pl-2 mt-1 w-100">
              <p className="section-label-text" style={{ opacity: 0.5 }}>All times are in {defaultTimezone}</p>
            </Row>
          )}
        </Row>
        <div className="basket-button-container fade show">
        </div>
      </div>
    </>
  );
};


export default React.memo(AppointmentSelection);
