// ** Dropdowns Imports
import UserDropdown from './UserDropdown';

// ** Third Party Components
import { Sun, Moon, Maximize, Minimize } from 'react-feather';
import { Button, NavItem, NavLink } from 'reactstrap';
import userState from '../../../../recoil/atoms/auth/user';
import { useRecoilState } from 'recoil';
import bookingStepState from '../../../../recoil/atoms/booking/bookingStep';
import { AppointmentSteps } from '../../../../utility/Constant';

const NavbarUser = props => {
  const [user] = useRecoilState(userState);
  const [, setBookingStep] = useRecoilState(bookingStepState);

  const onClickSignIn = () => {
    setBookingStep(AppointmentSteps.Login);
  };

  return (
    <ul className="nav navbar-nav align-items-center">
      <div className="user-nav">
        {/* {!user && (
          <Button color="link" onClick={onClickSignIn}>
            Sign In
          </Button>
        )} */}
      </div>
      <UserDropdown />
    </ul>
  );
};
export default NavbarUser;
