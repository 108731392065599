import { Alert, Row, Col, Button, FormGroup, Label, Input, Form, FormText } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const schema = yup.object().shape({
  username: yup.string().trim().required('Please enter your phone number or email').max(255, 'Please enter within 255 characters'),
});

const ForgotPasswordForm = ({ isLoading, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      phoneCode: '1',
    },
  });

  return (
    <div className="booking-content-wrapper">
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <h4 className="text-center mb-1">Forgot password?</h4>
          <p className="text-secondary mb-5 text-center font-weight-light">
            Enter your email or phone number and we'll send you instructions to reset your password
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
              <Tabs className="reset-pass-tabs">
                <TabList className="reset-pass-tabs__tab-list">
                  <Tab className="reset-pass-tabs__tab">Email</Tab>
                  <Tab className="reset-pass-tabs__tab">Phone number</Tab>
                </TabList>

                <TabPanel>
                  <FormGroup className="mb-2 mt-2">
                    <Controller control={control} name="username" render={({ field }) => <Input type="text" placeholder="" {...field} />} />
                    {errors?.username && <FormText color="danger">{errors?.username?.message}</FormText>}
                  </FormGroup>
                </TabPanel>
                <TabPanel>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <FormGroup className="mb-2 mt-2">
                        <PhoneInput
                          country="us"
                          countryCodeEditable
                          inputProps={{ name: 'username', id: 'username' }}
                          {...field}
                          onChange={(value, data) => {
                            if (value.slice(data.dialCode?.length || 0).length === 0) {
                              field.onChange(data.dialCode);
                            } else {
                              field.onChange(value);
                            }
                            setValue('phoneCode', data.dialCode);
                          }}
                          buttonClass="border-none"
                          inputClass="w-100"
                        />
                        {!!errors.username && <FormText color="danger">{errors?.username?.message || 'Error'}</FormText>}
                      </FormGroup>
                    )}
                  />
                </TabPanel>
              </Tabs>

              <Button type="submit" color="primary" block disabled={isLoading}>
                Reset password
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordForm;
