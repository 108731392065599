// ** React Imports
import { Suspense, useEffect, useState } from 'react';

// ** Third Party Components
import { BrowserRouter as Router, Switch, Route, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Card, Container, Spinner } from 'reactstrap';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';

import useJwt from '@src/@core/auth/jwt/useJwt';
import { getShopInfo, getExternalKey, getTimezones } from '@apis';
import shopState from '@recoil/atoms/current-shop';
import externalKeyState from '@recoil/atoms/auth/externalKey';
import timeZoneState from '@recoil/atoms/booking/timeZone';
import bookingState, { initialBookingState } from '@recoil/atoms/booking/booking';
import bookingStepState, { initialBookingStep } from '@recoil/atoms/booking/bookingStep';
import '@styles/react/pages/page-booking.scss';

import BookingLeftSide from './components/BookingLeftSide';
import BookingRightSide from './components/BookingRightSide';
import { bookingRoutes } from './routes';
import useUserState from '../../../utility/hooks/useUserState';

const BookingWizard = () => {
  const { jwt } = useJwt();
  const location = useLocation();
  const params = useParams();
  const [user] = useUserState();
  const [externalKey, setExternalKey] = useRecoilState(externalKeyState);
  const [shop, setShop] = useRecoilState(shopState);
  const [timeZones, setTimeZones] = useRecoilState(timeZoneState);
  const [, setBooking] = useRecoilState(bookingState);
  const [, setBookingStep] = useRecoilState(bookingStepState);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {
    jwt.setClientId(params?.clientId);

    return () => {
      setBooking(initialBookingState);
      setBookingStep(initialBookingStep);
    };
  }, []);

  useQuery(
    'externalKey',
    async () => {
      const { data } = await getExternalKey();
      setExternalKey(data.data?.jwtToken);
      jwt.setToken(data.data?.jwtToken);
      return data.data?.jwtToken;
    },
    {
      enabled: !user && !externalKey && !!jwt.getClientId(),
    }
  );
  useQuery(
    'shopInfo',
    async () => {
      const { data } = await getShopInfo(params?.clientId);
      const { phoneNumber, phoneCode } = data.data;
      const shopInfo = { ...data.data, ...(phoneNumber && phoneCode && { phone: `+${phoneCode}${phoneNumber}` }) };
      setShop(shopInfo);
      // const { customScript = '' } = data.data?.metadata || {};
      // const bodyEl = document.querySelector('body');
      // bodyEl.insertAdjacentHTML('beforeend', customScript || '')
      return shopInfo;
    },
    {
      enabled: (!!externalKey || !!user) && isEmpty(shop),
    }
  );
  useQuery(
    'timeZones',
    async () => {
      const { data } = await getTimezones();
      setTimeZones(data.data);
      return data.data;
    },
    {
      enabled: (!!externalKey || !!user) && isEmpty(timeZones),
    }
  );

  return (
    <Router basename={`${params?.clientId}`}>
      <Container className="booking">
        <Row className="row-eq-height">
          <Col md={0} lg={3}>
            <BookingLeftSide />
          </Col>
          <Col md={12} lg={6}>
            <Switch>
              {bookingRoutes.map(route => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={route.id}
                  path={route.path}
                  exact={route.exact}
                  render={props => {
                    return (
                      <Suspense fallback={<Spinner />}>
                        <route.component {...props} />
                      </Suspense>
                    );
                  }}
                />
              ))}
            </Switch>
          </Col>

          {!isTabletOrMobile && (
            <Col md={12} lg={3}>
              <BookingRightSide />
            </Col>
          )}
        </Row>
      </Container>
    </Router>
  );
};

export default BookingWizard;
