import { lazy } from 'react';
import { lazyRetry } from '../../../App';
import MyProfile from './components/MyProfile';

export const profileRoutes = [
  {
    path: '/',
    exact: true,
    className: '',
    component: MyProfile,
    id: 'my-profile',
  },
];
