import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import BookingWizard from '../../views/apps/booking';
import AppointmentsApp from '../../views/apps/appointments';
import ProfileApp from '../../views/apps/profile';

const AppRoutes = [
  {
    path: '/:clientId/profile',
    component: ProfileApp,
    exact: true,
    meta: {
      menuHidden: true,
    },
  },
  {
    path: ['/:clientId/appointments', '/:clientId/appointments/:appointmentId', '/:clientId/appointments/:appointmentId/reschedule'],
    component: AppointmentsApp,
    exact: true,
    meta: {
      menuHidden: true,
    },
  },
  {
    path: ['/:clientId', '/:clientId/:paymentId'],
    component: BookingWizard,
    exact: true,
    meta: {
      menuHidden: true,
      publicRoute: true,
    },
  },
];

export default AppRoutes;
