import { Calendar, Users, Settings } from 'react-feather';

export default [
  {
    id: 'calendar',
    title: 'Calendar',
    icon: <Calendar size={20} />,
    navLink: '/admin/calendar',
    action: 'read',
    resource: 'calendar'
  }, {
    id: 'clients',
    title: 'Client',
    icon: <Users size={20} />,
    navLink: '/admin/clients',
    action: 'read',
    resource: 'calendar'
  }, {
    id: 'clinicians',
    title: 'Clinician',
    icon: <Users size={20} />,
    navLink: '/admin/clinicians',
    action: 'read',
    resource: 'calendar'
  },{
    id: 'settings',
    title: 'Setting',
    icon: <Settings size={20} />,
    navLink: '/admin/settings',
    action: 'read',
    resource: 'calendar'
  },
];
