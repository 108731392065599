import { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Row, Col, Label } from 'reactstrap';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from 'react-query';
import * as Sentry from "@sentry/react";
import momentTz from 'moment-timezone';
import { Calendar, Check, ChevronLeft } from 'react-feather';
import { chain, uniqBy, mapValues, isEmpty, omit, cloneDeep } from 'lodash';
import { bookAppointment, updateCustomer, createVerificationCode } from '@apis';
import userState from '../../../../../recoil/atoms/auth/user';
import { CookieKeys, DAY_MONTH_DATE_FORMAT, DAY_MONTH_DATE_TIME_FORMAT } from '../../../../../utility/Constant';
import { Cookie } from '../../../../../utility/Cookie';
import shopState from '../../../../../recoil/atoms/current-shop';
import CustomerForm from './CustomerForm';
import { defaultSelectedProduct } from '../../../../../recoil/atoms/booking/booking';
import { upsertClient } from '../../../../../apis/customer.api';
import { isDepositRequired, saveAppointmentBeforeDeposit } from '../depositHelper';
import { getBreeds, getKeywordActions } from '../../../../../apis';
import KeywordAction from './KeywordAction';
import clientState from '../../../../../recoil/atoms/booking/client';
import { isReturningClient } from '../returningClientHelper';

const ClientInfoCollection = ({ goNextStep, booking, setStep, setBooking, backPreviousStep }) => {
  const [user, setUser] = useRecoilState(userState);
  const [client, setClient] = useRecoilState(clientState);
  const [shop] = useRecoilState(shopState);
  const defaultTimezone = shop?.timezone;
  const [allowRemind, setAllowRemind] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [keywordActions, setKeywordActions] = useState([]);
  const [showKeywordAction, setShowKeywordAction] = useState(false);
  const [activeKeywordAction, setActiveKeywordAction] = useState(null);
  const [confirmedKeywordAction, setConfirmedKeywordAction] = useState(false);
  const [breeds, setBreeds] = useState([]);

  const {
    data: data,
    isFetching: isFetchingKeywordActions,
    refetch: refetchKeywordActions,
    isLoading: isLoadingKeywordActions } = useQuery(
    ['keywordActions'], () => getKeywordActions({}),
    {
      onSuccess: (data) => {
        setKeywordActions(data?.data || []);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
      },
      enabled: true
    }
  );

  const {
    data: _breeds,
    isFetching: isFetchingBreeds,
    refetch: refetchBreeds,
    isLoading: isLoadingBreeds,
  } = useQuery(['breeds', booking?.species?.id], () => getBreeds({ species_id: booking?.species?.id }), {
    onSuccess: (data) => {
      const tmp = data?.data?.map(breed => ({ label: breed.display_name, value: breed.id }));
      setBreeds(tmp || []);
    },
    onError: (error) => {
    },
    enabled: !!booking?.species?.id
  });

  useEffect(() => {
    if (shop) {
      Sentry.metrics.increment("booking_client_info_collection", 1, {
        tags: { shop: shop?.name, user: user?.id },
      });
    }
  }, [shop]);

  useEffect(() => {
    refetchKeywordActions();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', backPreviousStep);
    return () => {
      window.removeEventListener('popstate', backPreviousStep);
    };
  }, []);

  const selectedProviders = chain(booking.selectedProducts)
    .groupBy('provider.id')
    .mapValues(arr => {
      const result = arr.reduce(
        (res, item) => {
          res.provider = item.provider;
          res.products = uniqBy([...res.products, ...item.products], 'id');
          return res;
        },
        { ...defaultSelectedProduct }
      );
      return result;
    })
    .value();

  const updateCustomerMutation = useMutation(
    async obj => {
      try {
        if (!!user) {
          const { data } = await updateCustomer(user?.id, { avatar: user.avatar, ...obj });
          Cookie.setItem(CookieKeys.user, { ...user, ...data.data });
          setUser({ ...user, ...data.data });
        }
      } catch (e) {
        throw e.response.data;
      }
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const isAppointmentLimitEnabled = useMemo(() => {
    return shop?.feature_settings?.booking_limit_per_time_slot_enabled !== undefined &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.enabled &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.apply_to_providers &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.limit > 1;
  }, [shop]);

  const addBookingMutation = useMutation(bookAppointment);

  const saveAppointment = (user, clientInfo, note, saveAsRequest = false) => {
    const products = Object.values(selectedProviders).reduce((res, item) => {
      const arr = item.products.map(product => ({
        id: product.id,
        anyone: item.provider.id === 'any-provider' ? !booking?.dateTimeProviderId : !item.provider,
        providerId: item.provider.id === 'any-provider' ? (booking?.dateTimeProviderId || null) : item.provider?.id,
        ...(!item.provider && { label: 'Anyone' }),
      }));
      res.push(...arr);
      return res;
    }, []);

    const sendReminder = allowRemind && !saveAsRequest;
    addBookingMutation.mutate(
      {
        source: 'CUSTOMER_SITE',
        products,
        userId: user?.id,
        isReminderSMS: sendReminder,
        isReminderEmail: sendReminder,
        isSendConfirmation: sendReminder,
        isReturningClient: booking.returningClientFlow,
        roomId: booking?.roomId,
        date: booking.dateTime,
        note: booking.note || note,
        isDropoff: booking.isDropoff,
        speciesId: booking?.species?.id || clientInfo?.species?.id,
        speciesName: booking?.species?.name || clientInfo?.species?.name,
        patientName: booking?.customer?.petName || clientInfo?.petName,
        sex: booking?.customer?.sex || clientInfo?.sex,
        birthdate: booking?.customer?.birthdate || clientInfo?.birthdate,
        breed: booking?.customer?.breed || clientInfo?.breed,
        breed_id: booking?.customer?.breed_id || clientInfo?.breed_id,
        ignoreOverlap: isAppointmentLimitEnabled,
        confirmed_keyword_action: clientInfo.confirmedKeywordAction || booking?.confirmed_keyword_action || confirmedKeywordAction,
        time_slot_calendar_id: booking?.time_slot_calendar_id,
        saveAsRequest,
      },
      {
        onSuccess: ({ data }) => {
          setBooking({
            ...booking,
            appointmentId: data.data.id,
            clientId: data.data.items[0].clientId,
            customer: {
              ...booking.customer,
              ...clientInfo
            },
            isSaved: true,
            allowRemind
          });
          setIsLoading(false);
          sessionStorage.removeItem(CookieKeys.confirmationForm);
          Cookie.removeItem(CookieKeys.confirmationForm);
          Sentry.metrics.increment("booking_save_appointment", 1, {
            tags: { shop: shop?.name, user: user?.id },
          });
          goNextStep();
        },
        onError: (error) => {
          Sentry.captureException(error);
          setIsLoading(false);
        },
      }
    );
  };

  const upsertClientMutation = useMutation(upsertClient);

  const findOrCreateClient = (user, clientInfo, note) => {
    const fullName = user?.firstName || clientInfo?.firstName;
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1] || '';
    const clientParams = {
      user_id: user?.id,
      email: user?.email || clientInfo?.email,
      phone: user?.phoneNumber || clientInfo?.phoneNumber,
      first_name: firstName,
      last_name: lastName,
      address1: clientInfo?.address1,
      address2: clientInfo?.address2,
      city: clientInfo?.city,
      state: clientInfo?.state,
      country: clientInfo?.country,
      zip: clientInfo?.zip,
    };
    upsertClientMutation.mutate(
      clientParams,
      {
        onSuccess: (data) => {
          let localClient = null;
          if (data?.data?.id) {
            localClient = data?.data;
            setBooking({ ...booking, allowRemind, clientId: localClient.id, customer: clientInfo, note, confirmed_keyword_action: confirmedKeywordAction });
            setClient(localClient);
          }
          const product = booking.selectedProducts[0].products[0];
          if (product && isDepositRequired(shop, product, isReturningClient(localClient))) {
            if (saveAppointmentBeforeDeposit(product)) {
              const saveAsRequest = true;
              saveAppointment(user, clientInfo, note, saveAsRequest);
            } else {
              setIsLoading(false);
              goNextStep();
            }
          } else {
            saveAppointment(user, clientInfo, note);
          }
        },
        onError: (data) => {
          setIsLoading(false);
        },
      }
    );
  };

  const verificationCodeMutation = useMutation(createVerificationCode);

  const beginVerification = ({ clientInfo, note }) => {
    if (shop?.is_demo && !!shop?.feature_settings?.skip_demo_shop_booking_otp_enabled) {
      setIsLoading(false);
      setBooking(prev => ({ ...prev, verificationError: false, allowRemind, note, customer: { ...clientInfo }, confirmed_keyword_action: confirmedKeywordAction }));
      goNextStep();
      return;
    }

    const body = {
      email: clientInfo.email,
      phoneNumber: clientInfo.phoneNumber,
      phoneCode: clientInfo.phoneCode
    };
    verificationCodeMutation.mutate(body,
      {
        onSuccess: () => {
          setIsLoading(false);
          setBooking(prev => ({ ...prev, verificationError: false, allowRemind, note, customer: { ...clientInfo, confirmed_keyword_action: confirmedKeywordAction } }));
          goNextStep();
        },
        onError: (data) => {
          setIsLoading(false);
          if (data?.response?.data?.message === 'VerificationMaximumExceeded') {
            sessionStorage.removeItem(CookieKeys.confirmationForm);
            Cookie.removeItem(CookieKeys.confirmationForm);
            setBooking(prev => ({ ...prev, verificationError: true, allowRemind, note, customer: { ...clientInfo, confirmed_keyword_action: confirmedKeywordAction } }));
            goNextStep();
          }
        },
      }
    );
  };

  const triggerKeywordActions = (note) => {
    const actionsForProduct = keywordActions
      .filter(action => action.product_ids.includes(booking.selectedProducts[0].products[0]?.id) && !action.all_products)
      .sort((a, b) => {
        if (a.action_type === 'prompt' && b.action_type !== 'prompt') {
          return -1;
        }
        if (a.action_type !== 'prompt' && b.action_type === 'prompt') {
          return 1;
        }
        return 0;
      });
    if (actionsForProduct.length > 0) {
      const withKeywords = actionsForProduct.filter(action => action.keywords.length > 0);
      if (withKeywords.length > 0) {
        const bestMatch = withKeywords.find(action => {
          const { keywords } = action;
          const keyword = keywords.find(keyword => note.toLowerCase().includes(keyword.toLowerCase()));
          return !!keyword;
        });
        if (bestMatch) {
          setShowKeywordAction(true);
          setActiveKeywordAction(bestMatch);
          return true;
        }
      }
    }
    const genericActions = keywordActions.filter(action => action.all_products)
      .sort((a, b) => {
        if (a.action_type === 'prompt' && b.action_type !== 'prompt') {
          return -1;
        }
        if (a.action_type !== 'prompt' && b.action_type === 'prompt') {
          return 1;
        }
        return 0;
      });
    if (genericActions.length > 0) {
      const withKeywords = genericActions.filter(action => action.keywords.length > 0);
      if (withKeywords.length > 0) {
        const bestMatch = withKeywords.find(action => {
          const { keywords } = action;
          const keyword = keywords.find(keyword => note.toLowerCase().includes(keyword.toLowerCase()));
          return !!keyword;
        });
        if (bestMatch) {
          setShowKeywordAction(true);
          setActiveKeywordAction(bestMatch);
          return true;
        }
      }
    }
    if (actionsForProduct.length > 0) {
      const withoutKeywords = actionsForProduct.filter(action => action.keywords.length === 0);
      if (withoutKeywords.length > 0) {
        const bestMatch = withoutKeywords[0];
        setShowKeywordAction(true);
        setActiveKeywordAction(bestMatch);
        return true;
      }
    }
    if (genericActions.length > 0) {
      const withoutKeywords = genericActions.filter(action => action.keywords.length === 0);
      if (withoutKeywords.length > 0) {
        const bestMatch = withoutKeywords[0];
        setShowKeywordAction(true);
        setActiveKeywordAction(bestMatch);
        return true;
      }
    }
    return false;
  };

  const onKeywordActionCancel = () => {
    setShowKeywordAction(false);
    setActiveKeywordAction(null);
  };

  const sanitizeNote = (note) => {
    if (!note) return note;
    let tmp = cloneDeep(note);
    try {
      tmp = tmp.replace(/[!@#$%^&*\(\)\\_+`\-=\[\]\{\}\|;':",.<>?\/]/g, ''); // Remove special characters
      tmp = tmp.replace(/[‘’“”]/g, "'"); // Replace apostrophes/curly quotes
      tmp = tmp.replace(/[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu, ''); // Remove emojis
      return tmp.trim();
    } catch (error) {
      Sentry.captureException(error);
      return note;
    }
  };

  const onContinue = (values, isDirty, dirtyFields) => {
    const noteChanged = !!dirtyFields?.note;
    const customerChanged = !isEmpty(omit(dirtyFields, ['note']));
    let clientInfo = cloneDeep(values);
    let note = sanitizeNote(values?.note);
    setIsLoading(true);

    if (showKeywordAction) {
      setShowKeywordAction(false);
      setActiveKeywordAction(null);
      setConfirmedKeywordAction(true);
      clientInfo = {
        ...booking.customer,
        confirmedKeywordAction: true
      }
      note = booking.note;
    } else if (keywordActions && keywordActions.length > 0) {
      setBooking(prev => ({ ...prev, note, allowRemind, customer: { ...clientInfo } }));
      const keywordsTriggered = triggerKeywordActions(note);
      if (keywordsTriggered) {
        setIsLoading(false);
        return;
      }
    }

    if (!customerChanged) {
      if (!user) {
        beginVerification({ clientInfo, note });
      } else {
        findOrCreateClient(user, clientInfo, note);
      }
      return;
    }

    updateCustomerMutation.mutate(clientInfo, {
      onSuccess: () => {
        if (!user) {
          beginVerification({ clientInfo, note });
        } else {
          findOrCreateClient(user, clientInfo, note);
        }
      },
    });
  };

  return (
    <>
      <div className="d-flex align-items-center booking-header" style={{ paddingTop: '8px', animation: 'fadeIn 0.5s' }}>
        <Button className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
          <ChevronLeft />
        </Button>
      </div>
      <Label>Summary</Label>
      {Object.values(selectedProviders).map(item => (
        <Alert key={`alert-${item?.id}`} className="pt-1 pl-1 pb-1" fade={false}>
          <div className="confirmation-summary">
            <span>
              <Calendar className="mr-1" size={18} />
              {!booking?.isDropoff && momentTz.tz(booking?.dateTime, defaultTimezone).format(DAY_MONTH_DATE_TIME_FORMAT)}
              {booking?.isDropoff && `${momentTz.tz(booking?.dateTime, defaultTimezone).format(DAY_MONTH_DATE_FORMAT)}, ${booking?.dropoffLabel}`}
            </span>
            <div className='confirmation-items'>
              <p className="font-weight-normal">
                <Check className="mr-1" size={15} />
                {booking?.returningClientFlow || !!user ? 'Returning client' : 'New client'}
              </p>
              {booking?.species &&
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {booking.species?.name}
                </p>
              }
              <p className="font-weight-normal">
                <Check className="mr-1" size={15} />
                {item.products.map(product => product.name).join(' | ')}{' '}
              </p>
              <p className="font-weight-normal">
                <Check className="mr-1" size={15} />
                {item.provider?.id === 'any-provider' ? 'First available clinician' : item.provider?.firstName}
              </p>
              {booking?.isDropoff && (
                <p className="font-weight-normal">
                  <Check className="mr-1" size={15} />
                  {`Your pet will be dropped off for this appointment`}
                </p>
              )}
            </div>
          </div>
        </Alert>
      ))}
      <div className="booking-content-wrapper">
        <Row className="justify-content-center">
          <Col sm={8}>
            {!showKeywordAction && (
              <CustomerForm
                user={user}
                onSubmit={onContinue}
                isLoading={isLoading}
                booking={booking}
                allowRemind={allowRemind}
                setAllowRemind={setAllowRemind}
                breeds={breeds}
                shop={shop}
              />
            )}
            {showKeywordAction && (
              <>
                <KeywordAction
                  keywordAction={activeKeywordAction}
                  onContinue={onContinue}
                  onCancel={onKeywordActionCancel}
                  shop={shop}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClientInfoCollection;
