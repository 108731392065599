import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useRecoilState } from 'recoil';
import bookingState, { initialBookingState, defaultSelectedProduct } from '@recoil/atoms/booking/booking';
import bookingStepState, { initialBookingStep, previousStepState } from '@recoil/atoms/booking/bookingStep';
import externalKeyState from '@recoil/atoms/auth/externalKey';
import shopState from '@recoil/atoms/current-shop';
import AppointmentSelection from './AppointmentSelection';
import ClientInfoCollection from './ClientInfoCollection';
import Verification from './Verification';
import Login from './Login';
import SignUp from './SignUp';
import { AppointmentSteps, CookieKeys } from '../../../../../utility/Constant';
import userState from '../../../../../recoil/atoms/auth/user';
import BookSuccess from './BookSuccess';
import ForgotPassword from './ForgotPassword';
import useQuerySearch from '../../../../../utility/hooks/useQuerySearch';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import DepositCollection from './DepositCollection';
import InsuranceOffer from './InsuranceOffer';
import ClientTypeSelection from './ClientTypeSelection';
import { isDepositRequired } from '../depositHelper';
import ReturningClientInfo from './ReturningClientInfo';
import LocationSelection from './LocationSelection';
import { Cookie } from '../../../../../utility/Cookie';
import NoAvailability from './NoAvailability';
import clientState from '../../../../../recoil/atoms/booking/client';
import { isReturningClient } from '../returningClientHelper';

const BookAppointment = () => {
  const location = useLocation();
  const querySearch = useQuerySearch();
  const [externalKey, setExternalKey] = useRecoilState(externalKeyState);
  const [booking, setBooking] = useRecoilState(bookingState);
  const [appointmentStep, setBookingStep] = useRecoilState(bookingStepState);
  const [previousStep, setPreviousStep] = useRecoilState(previousStepState);
  const [shop] = useRecoilState(shopState);
  const [user] = useRecoilState(userState);
  const [client, setClient] = useRecoilState(clientState);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const params = useParams();

  const isBookStaffMode =
    location.pathname === '/clinician' &&
    (booking.selectedProducts.length <= 1 ||
      isEqual(booking.selectedProducts?.[0], defaultSelectedProduct) ||
      isEmpty(booking.selectedProducts?.[0]?.products) ||
      !booking.dateTime);

  const isDepositCollectionRequired = () => {
    if (booking.selectedProducts?.length === 0) return true;
    return isDepositRequired(shop, booking.selectedProducts[0]?.products[0], isReturningClient(client));
  };

  const isInsuranceOfferEnabled = () => {
    if (shop?.feature_settings?.vertical_insure_enabled !== undefined && shop?.feature_settings?.vertical_insure_enabled?.enabled === true) {
      return true;
    }
    return false;
  };

  const isMultiLocationEnabled = () => {
    if (shop?.feature_settings?.multi_location_v1_enabled !== undefined) return shop.feature_settings.multi_location_v1_enabled;
    return false;
  };

  const isNewClientBookingDisabled = () => {
    if (shop?.feature_settings?.new_client_booking_enabled === undefined || shop.feature_settings.new_client_booking_enabled === true) return false;
    return true;
  };

  useEffect(() => {
    setBooking(initialBookingState);
    if (!params?.paymentId) {
      setBookingStep(initialBookingStep);
    } else {
      setBookingStep(AppointmentSteps.DepositCollection);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (appointmentStep === AppointmentSteps.AppointmentSelection && shop?.metadata?.bookingPolicy) {
      Cookie.removeItem(CookieKeys.confirmationForm);
    }
  }, [shop]);

  useEffect(() => {
    switch (appointmentStep) {
      case AppointmentSteps.Login:
      case AppointmentSteps.SignUp:
      case AppointmentSteps.ForgotPassword:
      case AppointmentSteps.BookSuccess:
      case AppointmentSteps.ReturningClientInfo:
      case AppointmentSteps.ClientTypeSelection:
      case AppointmentSteps.NoAvailability:
        Cookie.removeItem(CookieKeys.confirmationForm);
        break;
      default:
        break;
    }
  }, [appointmentStep]);

  const StepComponent = useMemo(() => {
    switch (appointmentStep) {
      case AppointmentSteps.ClientTypeSelection:
        return ClientTypeSelection;
      case AppointmentSteps.ReturningClientInfo:
        return ReturningClientInfo;
      case AppointmentSteps.LocationSelection:
        return LocationSelection;
      case AppointmentSteps.AppointmentSelection:
        return AppointmentSelection;
      case AppointmentSteps.Login:
        return Login;
      case AppointmentSteps.SignUp:
        return SignUp;
      case AppointmentSteps.ForgotPassword:
        return ForgotPassword;
      case AppointmentSteps.ClientInfoCollection:
        return ClientInfoCollection;
      case AppointmentSteps.Verification:
        return Verification;
      case AppointmentSteps.DepositCollection:
        return DepositCollection;
      case AppointmentSteps.InsuranceOffer:
        return InsuranceOffer;
      case AppointmentSteps.BookSuccess:
        return BookSuccess;
      case AppointmentSteps.NoAvailability:
        return NoAvailability;
      default:
        return AppointmentSelection;
    }
  }, [appointmentStep, isBookStaffMode]);

  const goNextStep = () => {
    setPreviousStep(appointmentStep);
    switch (appointmentStep) {
      case AppointmentSteps.ClientTypeSelection:
        if (booking.returningClientFlow) {
          return setBookingStep(AppointmentSteps.ReturningClientInfo);
        } else {
          if (isMultiLocationEnabled()) {
            return setBookingStep(AppointmentSteps.LocationSelection);
          } else {
            if (!user && isNewClientBookingDisabled()) {
              return setBookingStep(AppointmentSteps.NoAvailability);
            } else {
              return setBookingStep(AppointmentSteps.AppointmentSelection);
            }
          }
        }
      case AppointmentSteps.ReturningClientInfo:
        return setBookingStep(AppointmentSteps.Verification);
      case AppointmentSteps.LocationSelection:
        return setBookingStep(AppointmentSteps.AppointmentSelection);
      case AppointmentSteps.AppointmentSelection:
        if (isNewClientBookingDisabled() && !isReturningClient(client)) {
          return setBookingStep(AppointmentSteps.NoAvailability);
        } else {
          return setBookingStep(AppointmentSteps.ClientInfoCollection);
        }
      case AppointmentSteps.ClientInfoCollection:
        if (user) {
          if (isDepositCollectionRequired()) {
            return setBookingStep(AppointmentSteps.DepositCollection);
          } else {
            if (isInsuranceOfferEnabled()) {
              return setBookingStep(AppointmentSteps.InsuranceOffer);
            } else {
              return setBookingStep(AppointmentSteps.BookSuccess);
            }
          }
        } else {
          return setBookingStep(AppointmentSteps.Verification);
        }
      case AppointmentSteps.Verification:
        if (previousStep === AppointmentSteps.ReturningClientInfo) {
          if (isMultiLocationEnabled()) {
            return setBookingStep(AppointmentSteps.LocationSelection);
          } else {
            return setBookingStep(AppointmentSteps.AppointmentSelection);
          }
        } else {
          if (isDepositCollectionRequired()) {
            return setBookingStep(AppointmentSteps.DepositCollection);
          } else {
            if (isInsuranceOfferEnabled()) {
              return setBookingStep(AppointmentSteps.InsuranceOffer);
            } else {
              return setBookingStep(AppointmentSteps.BookSuccess);
            }
          }
        }
      case AppointmentSteps.DepositCollection:
        if (isInsuranceOfferEnabled()) {
          return setBookingStep(AppointmentSteps.InsuranceOffer);
        } else {
          return setBookingStep(AppointmentSteps.BookSuccess);
        }
      case AppointmentSteps.InsuranceOffer:
        return setBookingStep(AppointmentSteps.BookSuccess);
      default:
    }
  };

  const backPreviousStep = () => {
    switch (appointmentStep) {
      case AppointmentSteps.ClientInfoCollection:
        return setBookingStep(AppointmentSteps.AppointmentSelection);
      case AppointmentSteps.ReturningClientInfo:
        const tmp0 = cloneDeep(booking);
        tmp0.returningClientFlow = false;
        setBooking(tmp0);
        return setBookingStep(AppointmentSteps.ClientTypeSelection);
      case AppointmentSteps.LocationSelection:
        const tmp1 = cloneDeep(booking);
        tmp1.returningClientFlow = false;
        setBooking(tmp1);
        return setBookingStep(AppointmentSteps.ClientTypeSelection);
      case AppointmentSteps.AppointmentSelection:
        if (isMultiLocationEnabled()) {
          return setBookingStep(AppointmentSteps.LocationSelection);
        } else {
          return setBookingStep(AppointmentSteps.ClientTypeSelection);
        }
      case AppointmentSteps.InsuranceOffer:
        return setBookingStep(AppointmentSteps.BookSuccess);
      case AppointmentSteps.Verification:
        if (previousStep === AppointmentSteps.ReturningClientInfo) {
          return setBookingStep(AppointmentSteps.ReturningClientInfo);
        } else {
          return setBookingStep(AppointmentSteps.ClientInfoCollection);
        }
      default:
        return setBookingStep(AppointmentSteps.ClientTypeSelection);
    }
  };

  return (
    <div id="booking-content" className="h-100">
      <div className="d-flex flex-column h-100">
        <StepComponent
          externalKey={externalKey}
          appointmentStep={appointmentStep}
          setStep={setBookingStep}
          booking={booking}
          setBooking={setBooking}
          goNextStep={goNextStep}
          backPreviousStep={backPreviousStep}
          params={params}
        />
      </div>
    </div>
  );
};

export default BookAppointment;
