import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import useConfirm from '../../../utility/hooks/useConfirm';

const ConfirmBox = () => {
  const { confirmBox, onConfirm, onCancel } = useConfirm();

  return (
    <Modal isOpen={confirmBox.show} toggle={onCancel} unmountOnClose centered zIndex={9999} backdrop="static">
      <ModalHeader toggle={onCancel}>{confirmBox.title || 'Are you sure?'}</ModalHeader>
      <ModalBody>{confirmBox.message || "This can't be undone!"}</ModalBody>
      <ModalFooter>
        <Button color="flat-secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color={confirmBox.confirmButtonColor || 'primary'} onClick={onConfirm}>
          {confirmBox.confirmButtonText || 'OK'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmBox;
