import { ChevronRight } from "react-feather";
import { Button } from "reactstrap";

const KeywordAction = (props) => {
  const {
    keywordAction,
    onContinue,
    onCancel,
    shop,
  } = props;

  return (
    <div className="keyword-action-wrapper">
      <div className="keyword-action-body mb-1">
        <p>{keywordAction?.info_message}</p>
      </div>
      <div className="keyword-action-footer">
        {keywordAction?.action_type === 'prompt' && (
          <Button
            type="button"
            color="primary"
            outline
            className="custom-client-type-picker-button mb-2"
            onClick={onCancel}
          >
            Go back
          </Button>
        )}
        <Button
          type="button"
          color="primary"
          className="custom-client-type-picker-button"
          onClick={onContinue}
        >
          <>
            {keywordAction?.action_type === 'prompt' ? (
              <>
                {keywordAction?.prompt_message}
              </>
            ) : (
              <>
                Continue
              </>
            )}
            <ChevronRight style={{ marginLeft: '5px' }} size={18} />
          </>
        </Button>
      </div>
    </div>
  );
};

export default KeywordAction;
