import { useState } from 'react';
import { Card, CardBody, Spinner, Row, Col, Button, FormGroup, Label, Input, Form, FormText } from 'reactstrap';
import Select from '@components/select';
import toast from '@components/toast';
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useUserState from '@hooks/useUserState';
import { useQuery } from 'react-query';
import { getCustomerMetadata } from '@apis';
import { useParams } from 'react-router';
import { useUpdateProfileMutation } from '../../../../../queries';
import { CookieKeys } from '../../../../../utility/Constant';
import { Cookie } from '../../../../../utility/Cookie';

const schema = yup.object().shape({
  name: yup.string().trim().required('Name is required').max(255, 'Please enter within 255 characters'),
  phoneNumber: yup
    .string()
    .trim()
    .required('Phone is required')
    .max(15, 'Max 15 characters')
    .test('min-phone', 'Please enter at least 10 digits', (value, context) => {
      return value.slice(context.parent.phoneCode.length).length >= 10;
    })
    .test('missing-phone', 'Please enter your phone number', (value, context) => {
      return !!value.slice(context.parent.phoneCode.length);
    }),
  email: yup
    .string()
    .trim()
    .required('Please enter your email')
    .email('Please enter correct email format')
    .max(255, 'Please enter within 255 characters'),
});

// ** Create month and date list
const dob = [];
const mob = [];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

for (let i = 1; i <= 31; i++) {
  dob.push({ value: i, label: i });
}

for (let i = 1; i <= 12; i++) {
  mob.push({ value: i, label: months[i - 1] });
}

const Appointments = ({ shopId }) => {
  const [user, setUser] = useUserState();
  const { data: metadataResult } = useQuery(['customerMetadata', shopId, user?.id], () => getCustomerMetadata(user?.id, shopId), {
    enabled: !!user?.id && !!shopId,
  });
  const metadata = metadataResult?.data?.data;

  const updateProfileMutation = useUpdateProfileMutation({
    onSuccess: data => {
      setUser(data);
      const cookieUser = Cookie.getItem(CookieKeys.user);
      Cookie.setItem(CookieKeys.user, { ...cookieUser, ...data });
      toast.success('Update profile successfully!');
    },
  });

  const defaultValues = {
    name: user.firstName || '',
    phoneCode: user.phoneCode || '1',
    phoneNumber: `${user.phoneCode || '1'}${user.phoneNumber}` || '',
    email: user.email || '',
    dateOfBirth: user?.metadata?.dateOfBirth ? dob.find(item => item.value === +user?.metadata?.dateOfBirth) : null,
    monthOfBirth: user?.metadata?.monthOfBirth ? mob.find(item => item.value === +user?.metadata?.monthOfBirth) : null,
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = values => {
    updateProfileMutation.mutate({
      avatar: user.avatar,
      firstName: values.name,
      email: values.email || undefined,
      phoneCode: values.phoneCode,
      phoneNumber: values.phoneNumber.slice(values.phoneCode?.length || 0),
      metadata: {
        dateOfBirth: values.dateOfBirth ? values.dateOfBirth.value : undefined,
        monthOfBirth: values.monthOfBirth ? values.monthOfBirth.value : undefined,
      },
    });
  };

  return (
    <Card>
      <CardBody className="px-0 px-md-2">
        <h4 className="">My Profile</h4>
        <div className="mt-1 mb-3">
          <Form className="mb-2" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>Name</Label>
              <Controller control={control} name="name" render={({ field }) => <Input type="text" {...field} />} />
              {errors?.name && <FormText color="danger">{errors?.name?.message || 'Error'}</FormText>}
            </FormGroup>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <Label>Phone</Label>
                  <PhoneInput
                    country="us"
                    inputProps={{ name: 'phoneNumber', id: 'phoneNumber' }}
                    {...field}
                    onChange={(value, data) => {
                      if (value.slice(data.dialCode?.length || 0).length === 0) {
                        field.onChange(data.dialCode);
                      } else {
                        field.onChange(value);
                      }
                      setValue('phoneCode', data.dialCode);
                    }}
                    buttonClass="border-none"
                    inputClass="w-100"
                  />
                  {!!errors.phoneNumber && <FormText color="danger">{errors?.phoneNumber?.message || 'Error'}</FormText>}
                </FormGroup>
              )}
            />
            <FormGroup>
              <Label>Email</Label>
              <Controller control={control} name="email" render={({ field }) => <Input type="text" {...field} />} />
              {errors?.email && <FormText color="danger">{errors?.email?.message || 'Error'}</FormText>}
            </FormGroup>
            {/* <FormGroup className="align-items-center">
              <Label className="text-md-right text-left customer-field-label">Date of Birth</Label>
              <Row form>
                <Col xs={6} md="4">
                  <Controller
                    name="monthOfBirth"
                    control={control}
                    render={({ field }) => <Select minHeight={42} options={mob} placeholder="Month" {...field} />}
                  />
                </Col>
                <Col xs={6} md="4">
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => <Select minHeight={42} options={dob} placeholder="Day" {...field} />}
                  />
                </Col>
              </Row>
            </FormGroup> */}
            <Button block type="submit" color="primary" className="mt-2" disabled={!isDirty}>
              Update
            </Button>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default Appointments;
