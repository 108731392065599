import { useRecoilState } from 'recoil';
import shopState from '@recoil/atoms/current-shop';

const useShop = () => {
  const [shop] = useRecoilState(shopState);

  return shop;
};

export default useShop;
