import { Nav, NavItem } from 'reactstrap';
import { NavLink, withRouter, useRouteMatch, useLocation } from 'react-router-dom';
import { bookingRoutes } from '../routes';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import shopState from '../../../../recoil/atoms/current-shop';
import { useRecoilState } from 'recoil';
import ShopDefaultAvatar from '@src/assets/images/logo/OLI_APP_4C_LTBG.png';

const BookingLeftSide = () => {
  const [shop] = useRecoilState(shopState);
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  useEffect(() => {}, []);

  return isTabletOrMobile && document.getElementById('wrapper') ? (
    ReactDOM.createPortal(
      <div className="main-menu menu-light w-100 d-block booking-left-menu">
        <div className="main-menu-content">
          <div className="shop-banner">
            <div className="shop-banner__avatar">
              <img className="img-fluid mb-75" src={shop?.avatar || ShopDefaultAvatar} />
            </div>
            <h4 className="text-center">{shop?.name}</h4>
          </div>
        </div>
      </div>,
      document.getElementById('wrapper')
    )
  ) : (
    <>
      <div className="shop-banner">
        <div className="shop-banner__avatar">
          <img className="img-fluid mb-75" src={shop?.avatar || ShopDefaultAvatar} />
        </div>
        <h4 className="text-center">{shop?.name}</h4>
      </div>
      <div className="main-menu menu-light w-100 d-block booking-left-menu">
        <div className="main-menu-content">
        </div>
      </div>
    </>
  );
};

export default BookingLeftSide;
