import { Button, Col, Row, Spinner } from "reactstrap";
import styles from './DepositCollection.module.scss';
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import DepositCheckoutForm from "./DepositCheckoutForm";
import { useMutation, useQuery } from "react-query";
import { acceptAppointment, startPaymentCollection, findPaymentRequest, updatePaymentRequest, chargePaymentMethod } from "../../../../../apis/appointment.api";
import { CheckCircle, ChevronRight } from "react-feather";
import useMomentTimezone from "../../../../../utility/hooks/useMomentTimezone";
import { CookieKeys, DAY_MONTH_DATE_TIME_FORMAT, MONTH_DATE_HOUR_FORMAT } from "../../../../../utility/Constant";
import { defaultSelectedProduct } from '../../../../../recoil/atoms/booking/booking';
import { useRecoilState } from "recoil";
import userState from "../../../../../recoil/atoms/auth/user";
import useShop from '@hooks/useShop';
import { chain, uniqBy } from "lodash";
import { bookAppointment } from "../../../../../apis/booking.api";
import { Cookie } from "../../../../../utility/Cookie";
import { borderRadius } from "polished";

const DepositCollection = (props) => {
  const {
    goNextStep,
    backPreviousStep,
    booking,
    setBooking,
    params
  } = props;

  const momentTimezone = useMomentTimezone();
  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentIntent, setIsPaymentIntent] = useState(false);
  const [isPaymentMethodSaved, setIsPaymentMethodSaved] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [paymentRequestId, setPaymentRequestId] = useState(null);
  const [user, setUser] = useRecoilState(userState);
  const [receiptEmail, setReceiptEmail] = useState(booking?.customer?.email || user?.email);
  const [stripePromise, setStripePromise] = useState(null);
  const [platformCustomerId, setPlatformCustomerId] = useState(null);
  const [hasUpdatedPaymentWithAppointmentId, setHasUpdatedPaymentWithAppointmentId] = useState(false);
  const shop = useShop();

  const startPaymentCollectionMutation = useMutation(startPaymentCollection);

  const onStartPaymentCollection = () => {
    const params = {
      payment_type: "DEPOSIT",
      product_id: booking?.selectedProducts[0]?.products[0]?.id,
      client_id: booking?.clientId,
      appointment_id: booking?.appointmentId,
      created_by: user?.id,
    };
    startPaymentCollectionMutation.mutate(params, {
      onSuccess: (data) => {
        if (data?.data) {
          setPaymentRequestId(data.data.id);
          setClientSecret(data?.data?.client_secret);
          setPlatformCustomerId(data?.data?.platform_customer_id);
          setBooking(prev => ({ ...prev, customer: { ...prev.customer, firstName: data?.data?.client_name }, deposit: { ...prev.deposit, completed_at: data?.data?.completed_at, amount: data?.data?.amount, confirmation_number: data?.data?.confirmation_number } }));
          setStripePromise(loadStripe(data?.data?.stripe_public_key));
          if (data.data.status === 'complete') {
            setIsComplete(true);
          }
        }
      },
      onError: (error) => {
      },
    });
  };

  const {
    data: paymentRequest,
    refetch: refetchPaymentRequest
  } = useQuery(
    ['findPaymentRequest', params.paymentId],
    () => findPaymentRequest(params.paymentId),
    {
      onSuccess: (data) => {
        if (data?.data) {
          setPaymentRequestId(data.data.id);
          setClientSecret(data?.data?.client_secret);
          setIsPaymentIntent(true);
          setBooking(prev => ({ ...prev, customer: { ...prev.customer, firstName: data?.data?.client_name }, deposit: { ...prev.deposit, completed_at: data?.data?.completed_at, amount: data?.data?.amount, confirmation_number: data?.data?.confirmation_number } }));
          if (data?.data?.stripe_account && data.data.stripe_account.length > 0) {
            setStripePromise(loadStripe(data?.data?.stripe_public_key, { stripeAccount: data?.data?.stripe_account }));
          } else {
            setStripePromise(loadStripe(data?.data?.stripe_public_key));
          }
          if (data.data.status === 'complete') {
            setIsPaymentMethodSaved(true);
            setIsComplete(true);
            setHasUpdatedPaymentWithAppointmentId(true);
          }
        }
      },
      enabled: params.paymentId !== null && params.paymentId !== undefined
    }
  );

  const selectedProviders = chain(booking.selectedProducts)
  .groupBy('provider.id')
  .mapValues(arr => {
    const result = arr.reduce(
      (res, item) => {
        res.provider = item.provider;
        res.products = uniqBy([...res.products, ...item.products], 'id');
        return res;
      },
      { ...defaultSelectedProduct }
    );
    return result;
  })
  .value();

  const updatePaymentRequestMutation = useMutation(updatePaymentRequest);

  const updatePaymentRequestAppointmentId = (appointmentId) => {
    updatePaymentRequestMutation.mutate({ id: paymentRequestId, appointment_id: appointmentId }, {
      onSuccess: (data) => {
        setHasUpdatedPaymentWithAppointmentId(true);
      },
      onError: (error) => {
      },
    })
  };

  const isInsuranceOfferEnabled = () => {
    if (shop?.feature_settings?.vertical_insure_enabled !== undefined && shop?.feature_settings?.vertical_insure_enabled?.enabled === true) {
      return true;
    }
    return false;
  };

  const nextButtonText = () => {
    if (isInsuranceOfferEnabled()) {
      return 'Next step';
    }
    return 'Appointment Confirmation';
  };

  const isAppointmentLimitEnabled = useMemo(() => {
    return shop?.feature_settings?.booking_limit_per_time_slot_enabled !== undefined &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.enabled &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.apply_to_providers &&
      shop.feature_settings.booking_limit_per_time_slot_enabled.limit > 1;
  }, [shop]);

  const addBookingMutation = useMutation(bookAppointment);

  const saveAppointment = (user, clientInfo, note, saveAsRequest = false) => {
    const products = Object.values(selectedProviders).reduce((res, item) => {
      const arr = item.products.map(product => ({
        id: product.id,
        anyone: item.provider.id === 'any-provider' ? !booking?.dateTimeProviderId : !item.provider,
        providerId: item.provider.id === 'any-provider' ? (booking?.dateTimeProviderId || null) : item.provider?.id,
        ...(!item.provider && { label: 'Anyone' }),
      }));
      res.push(...arr);
      return res;
    }, []);

    addBookingMutation.mutate(
      {
        source: 'CUSTOMER_SITE',
        products,
        userId: user?.id,
        isReminderSMS: booking.allowRemind,
        isReminderEmail: booking.allowRemind,
        isSendConfirmation: booking.allowRemind,
        isReturningClient: booking.returningClientFlow,
        roomId: booking?.roomId,
        date: booking.dateTime,
        note: booking.note || note,
        isDropoff: booking.isDropoff,
        speciesId: booking?.species?.id || clientInfo?.species?.id,
        speciesName: booking?.species?.name || clientInfo?.species?.name,
        patientName: booking?.customer?.petName || clientInfo?.petName,
        sex: booking?.customer?.sex || clientInfo?.sex,
        birthdate: booking?.customer?.birthdate || clientInfo?.birthdate,
        breed: booking?.customer?.breed || clientInfo?.breed,
        breed_id: booking?.customer?.breed_id || clientInfo?.breed_id,
        deposit_paid: true,
        ignoreOverlap: isAppointmentLimitEnabled,
        confirmed_keyword_action: booking?.confirmed_keyword_action || clientInfo?.confirmedKeywordAction,
        time_slot_calendar_id: booking?.time_slot_calendar_id,
        saveAsRequest,
      },
      {
        onSuccess: ({ data }) => {
          updatePaymentRequestAppointmentId(data.data.id);
          const cId = data.data.items?.length > 0 ? data.data.items[0].clientId : null;
          setBooking({
            ...booking,
            appointmentId: data.data.id,
            customer: {
              ...booking.customer,
              ...clientInfo
            },
            clientId: cId,
            isSaved: true,
            deposit_paid: true,
          });
          try {
            Cookie.removeItem(CookieKeys.confirmationForm);
          } catch (e) {
          }
          Sentry.metrics.increment("booking_save_appointment", 1, {
            tags: { shop: shop?.name, user: user?.id },
          });
        },
        onError: (error) => {
          Sentry.captureException(error);
        },
      }
    );
  };

  const chargePaymentMutation = useMutation(chargePaymentMethod);
  const acceptAppointmentMutation = useMutation(acceptAppointment);

  const onChargePayment = () => {
    const body = {
      payment_request_id: paymentRequestId,
      client_id: booking?.clientId,
      platform_customer_id: platformCustomerId,
    };
    chargePaymentMutation.mutate(body, {
      onSuccess: (data) => {
        setIsComplete(true);
        setBooking(prev => ({ ...prev, deposit: { ...prev.deposit, completed_at: new Date(), amount: data?.data?.amount } }));
        const hasProduct = booking?.selectedProducts[0]?.products?.length > 0; // Move this stuff to onChargePayment success
        if (hasProduct && booking?.isSaved && shop?.metadata?.isAutoAccept) {
          acceptAppointmentMutation.mutate({ id: booking?.appointmentId, enableReminders: !!booking?.allowRemind }, {
            onSuccess: ({ data }) => {
              setHasUpdatedPaymentWithAppointmentId(true);
            },
            onError: () => {
            },
          });
        } else if (hasProduct && !booking.isSaved) {
          let saveAsRequest;
          if (shop?.metadata?.isAutoAccept) {
            saveAsRequest = false;
          } else {
            saveAsRequest = true;
          }
          saveAppointment(user, null, booking?.note, saveAsRequest);
        } else {
          if (params.paymentId) refetchPaymentRequest();
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  };

  useEffect(() => {
    if (isPaymentMethodSaved) {
      if (!isPaymentIntent) {
        onChargePayment();
      } else {
        const hasProduct = booking?.selectedProducts[0]?.products?.length > 0;
        if (hasProduct && booking?.isSaved && shop?.metadata?.isAutoAccept) {
          acceptAppointmentMutation.mutate({ id: booking?.appointmentId, enableReminders: !!booking?.allowRemind }, {
            onSuccess: ({ data }) => {
              setHasUpdatedPaymentWithAppointmentId(true);
            },
            onError: () => {
            },
          });
        } else if (hasProduct && !booking.isSaved) {
          let saveAsRequest;
          if (shop?.metadata?.isAutoAccept) {
            saveAsRequest = false;
          } else {
            saveAsRequest = true;
          }
          saveAppointment(user, null, booking?.note, saveAsRequest);
        } else {
          if (params.paymentId) refetchPaymentRequest();
        }
      }
    }
  }, [isPaymentMethodSaved]);

  useEffect(() => {
    if (booking) {
      if (!params?.paymentId) {
        onStartPaymentCollection();
      }
    }
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#03464C',
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className={styles['deposit-collection-container']}>
    {!isPaymentMethodSaved ? (
      <>
        <Row className={styles['deposit-title']}>
          Deposit Amount
        </Row>
        <div className={styles['amount-row']}>
          <div className={styles['deposit-amount-container']}>
            {!!booking?.deposit?.amount ? (
              <div className={styles['deposit-amount']}>
                {`$${booking?.deposit?.amount.toFixed(2)}`}
              </div>
              ) : (
                <div className={styles['deposit-payment-loading']}>
                  <Spinner color="primary" size="lg" />
                </div>
              )}
            <span className={styles['deposit-amount-subtitle']}>
              one-time
            </span>
          </div>
        </div>
        <div className={styles['deposit-disclaimer']}>
          Deposit will be applied to the total amount of your visit. Payment is collected by {shop?.name} using Oliver Software, Inc.
        </div>
        <div className={styles['deposit-card-info']}>
          <span className={styles['deposit-section-title']}>Payment information</span>
          <>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <DepositCheckoutForm
                  booking={booking}
                  setBooking={setBooking}
                  receiptEmail={receiptEmail}
                  setReceiptEmail={setReceiptEmail}
                  setIsComplete={setIsComplete}
                  setIsPaymentMethodSaved={setIsPaymentMethodSaved}
                  isPaymentIntent={isPaymentIntent}
                />
              </Elements>
            )}
            {!clientSecret && (
              <div className={styles['deposit-payment-loading']}>
                <span style={{ marginTop: '4px' }}>Just a moment...</span>
                <Spinner color="primary" style={{ marginLeft: '10px' }} />
              </div>
            )}
          </>
        </div>
      </>
    ) : (
      <>
      {!isComplete ? (
        <>
          <div className={styles['deposit-payment-loading']}>
            <span style={{ marginTop: '4px' }}>Just a moment...</span>
            <Spinner color="primary" style={{ marginLeft: '10px' }} />
          </div>
        </>
      ) : (
        <>
          <div className={styles['deposit-success-row']}>
            <div>
              <Col>
                <CheckCircle size={120} color="#00BA3F" />
                <p className={styles['deposit-success-subtitle']}>
                  Payment Successful
                </p>
              </Col>
            </div>
          </div>
          <div className={styles['deposit-disclaimer']}>
            Your payment has been processed! Details of the payment are included below.
          </div>
          <hr style={{ marginTop: '30px' }} />
          <div className={styles['deposit-confirmation-number']}>
            {`Confirmation #: ${booking?.deposit?.confirmation_number || ''}`}
          </div>
          <div className={styles['deposit-summary-container']}>
            <div className="row px-1">
              <div className={styles['deposit-summary-title']}>TOTAL AMOUNT PAID</div>
              <div className={styles['deposit-summary-value']}>${booking?.deposit?.amount.toFixed(2)}</div>
            </div>
            <hr style={{ marginTop: '10px' }} />
            {booking?.customer && (
              <>
                <div className="row px-1">
                  <div className={styles['deposit-summary-title']}>PAID BY</div>
                  <div className={styles['deposit-summary-value']}>{booking?.customer?.firstName}</div>
                </div>
                <hr style={{ marginTop: '10px' }} />
              </>
            )}
            <div className="row px-1">
              <div className={styles['deposit-summary-title']}>PAYMENT DATE</div>
              <div className={styles['deposit-summary-value']}>{`${momentTimezone(booking?.deposit?.completed_at).format(MONTH_DATE_HOUR_FORMAT)}`}</div>
            </div>
          </div>
          {(!!booking?.selectedProducts?.length && !!booking?.selectedProducts[0]?.products?.length) && (
            <div style={{ textAlign: 'center', height: '50px' }}>
              <Button color="primary" style={{ marginTop: '30px', height: '50px', borderRadius: '25px' }} onClick={() => { goNextStep(); }} disabled={!hasUpdatedPaymentWithAppointmentId}>
                <>
                  {nextButtonText()}
                  {hasUpdatedPaymentWithAppointmentId ? <ChevronRight size={18} style={{ marginLeft: '10px', marginBottom: '2px' }} /> : <Spinner size={'sm'} style={{ marginLeft: '10px', marginBottom: '2px' }} />}
                </>
              </Button>
            </div>
          )}
        </>
      )}
      </>
    )}
    </div>
  );
};

export default DepositCollection;
