// ** Router Import
import Router from './router/Router';
import { RecoilRoot } from 'recoil';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { firebaseConfig } from './configs/firebase';
import '@styles/react/libs/tables/react-dataTable-component.scss';
import ConfirmBox from '@components/confirm-box';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behavior as already tried refresh
      });
  });

const App = props => (
  <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Router />
        <ConfirmBox />
      </RecoilRoot>
    </QueryClientProvider>
  </FirebaseAuthProvider>
);

export default App;
