import { atom } from 'recoil';
import { AppointmentSteps } from '@utility/Constant';

export const initialBookingStep = AppointmentSteps.ClientTypeSelection;

const bookingStepState = atom({
  key: 'bookingStep',
  default: initialBookingStep,
});

export const previousStepState = atom({
  key: 'previousStep',
  default: null,
});

export default bookingStepState;
