import { Alert, Row, Col, Button, FormGroup, Label, Input, Form, FormText } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputPasswordToggle from '@components/input-password-toggle';

const schema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Please enter at least 8 characters')
    .max(255, 'Please enter within 255 characters'),
  confirmPassword: yup
    .string()
    .trim()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Passwords mismatch'),
});

const ResetPasswordForm = ({ isLoading, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <div className="booking-content-wrapper">
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          {/* <h4 className="text-center mb-1">Forgot password?</h4> */}
          {/* <p className="text-secondary mb-5">Enter your email or phone number and we'll send you instructions to reset your password</p> */}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
              <FormGroup>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <InputPasswordToggle
                      className="input-group-merge"
                      placeholder="At least 8 characters"
                      label="New password"
                      htmlFor="basic-default-password"
                      {...field}
                    />
                  )}
                />
                {errors?.password && <FormText color="danger">{errors?.password?.message}</FormText>}
              </FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <InputPasswordToggle
                      className="input-group-merge"
                      placeholder="At least 8 characters"
                      label="Confirm your password"
                      htmlFor="basic-default-confirm-password"
                      {...field}
                    />
                  )}
                />
                {errors?.confirmPassword && <FormText color="danger">{errors?.confirmPassword?.message}</FormText>}
              </FormGroup>

              <Button className="mt-2" type="submit" color="primary" block disabled={isLoading}>
                Create
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordForm;
