import axios from 'axios';
import { CORE_API_URL } from '../utility/Constant';

const path = '/appointment/v1';

export const getAppointments = data => axios.post(`${path}/appointments/search`, data);
export const getAppointmentDetail = id => axios.get(`${path}/appointments/${id}`);
export const getAppointmentProducts = params => axios.get(`${path}/products`, { params });
export const getAppointmentCatalogs = params => axios.get(`${path}/catalogs`, { params: { fetchProducts: true, ...(params || {}) } });
export const updateAppointment = data => axios.put(`${path}/appointments`, data);
export const updateAppointmentLabel = (id, data) => axios.put(`${path}/appointments/${id}/labels`, data);
export const deleteAppoinments = data => axios.delete(`${path}/appointments`, { data });
export const acceptAppointment = (data) => axios.put(`${path}/appointments/${data.id}/accept?enableReminders=${data.enableReminders}`);
export const createAppoinments = data => axios.post(`${path}/appointments`, data);
export const searchTimes = data => axios.post(`${path}/appointments/search/times`, data);
export const getTimezones = data => axios.get(`${path}/timezones`, data);
export const getSpecies = () => axios.get(`/appointment/v1/appointments/species`);
export const getBreeds = (data) => axios.get(`${CORE_API_URL}/breeds`, { params: data });

export const getAvailableDates = data => axios.post(`${CORE_API_URL}/providers/open_times`, data);
export const addToWaitlist = data => axios.post(`${CORE_API_URL}/waitlisters`, data);
export const removeFromWaitlist = data => axios.put(`${CORE_API_URL}/waitlisters/remove`, data);

export const createPaymentRequest = data => axios.post(`${CORE_API_URL}/payment_requests`, data);
export const startPaymentCollection = data => axios.post(`${CORE_API_URL}/payment_requests/start_payment`, data);
export const chargePaymentMethod = data => axios.post(`${CORE_API_URL}/payment_requests/charge_payment_method`, data);
export const updatePaymentRequest = data => axios.put(`${CORE_API_URL}/payment_requests/${data.id}`, data);
export const findPaymentRequest = ppid => axios.get(`${CORE_API_URL}/payment_requests/ppid/${ppid}`);
export const getOfficeLocations = () => axios.get(`${CORE_API_URL}/office_locations`);

export const getInsuranceClientId = () => axios.get(`${CORE_API_URL}/insurance_quotes/client_id`);
export const checkoutInsurance = data => axios.post(`${CORE_API_URL}/insurance_quotes`, data);
export const findExistingInsuranceQuote = data => axios.get(`${CORE_API_URL}/insurance_quotes/find/${data.client_id}`, { params: { name: data.name } });
export const completeInsurancePayment = data => axios.put(`${CORE_API_URL}/insurance_quotes/complete_payment`, data);

export const createCalendarBlock = data => axios.post(`${CORE_API_URL}/calendars/blocked_time`, data);
