import axios from 'axios';

const path = '/user/v1';

const headers = {
  Authorization: 'No-Need-Authorization',
};

export const createCustomer = data => axios.post(`${path}/customers`, data);
export const updateCustomer = (id, data) => axios.put(`${path}/customers/${id}`, { ...(data || {}), role: 'ROLE_CUSTOMER' });

export const signUp = data => axios.post(`${path}/auth/sign-up`, data, { headers });

export const signIn = data => axios.post(`${path}/auth/sign-in`, { ...(data || {}), role: 'ROLE_SHOP_ADMIN' }, { headers });

export const createVerificationCode = data => axios.post(`${path}/auth/code/init`, { ...(data || {}), role: 'ROLE_CUSTOMER' });
export const verifyCode = data => axios.post(`${path}/auth/code/verify`, { ...(data || {}), role: 'ROLE_CUSTOMER' });

export const customerSignIn = data => axios.post(`${path}/auth/sign-in`, { ...(data || {}), role: 'ROLE_CUSTOMER' }, { headers });

export const socialSignIn = data => axios.post(`${path}/auth/social`, { ...(data || {}), role: 'ROLE_CUSTOMER' }, { headers });

export const getProfile = () => axios.get(`${path}/profile`);
export const updateProfile = data => axios.put(`${path}/profile`, data);

export const requestResetPassword = data => axios.post(`${path}/reset-password/init`, data);
export const verifyResetPasswordCode = data => axios.post(`${path}/reset-password/verify`, data);
export const resetPassword = data => axios.post(`${path}/reset-password/finish`, data);

export const getListShop = () => axios.get(`tenant/v2/shop-users/my-shops`);

export const getShopInfo = id => axios.get(`tenant/v2/shops/${id}`);
