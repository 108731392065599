import { useState } from 'react';
import { Alert, Row, Col, Button, Card, CardBody, FormGroup, Label, Input, Form, FormText } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputPasswordToggle from '@components/input-password-toggle';
import { useSignUpMutation, useGoogleLogin, useFacebookLogin } from '@queries';
import GoogleIcon from '@src/assets/images/icons/google.png';
import FacebookIcon from '@src/assets/images/icons/facebook.png';
import { AppointmentSteps } from '@utility/Constant';

const schema = yup.object().shape({
  // name: yup.string().trim().required('Name is required').max(255, 'Please enter within 255 characters'),
  phoneNumber: yup
    .string()
    .trim()
    .required('Phone is required')
    .test('min-phone', 'Please enter at least 10 digits', (value, context) => {
      return value.slice(context.parent.phoneCode.length).length >= 10;
    })
    .max(15, 'Max 15 characters')
    .test('missing-phone', 'Please enter your phone number', (value, context) => {
      return !!value.slice(context.parent.phoneCode.length);
    }),
  email: yup
    .string()
    .trim()
    .nullable()
    // .required('Please enter your email')
    .email('Please enter correct email format')
    .max(255, 'Please enter within 255 characters'),
  password: yup
    .string()
    .trim()
    .required('Password is required')
    .min(8, 'Please enter at least 8 characters')
    .max(255, 'Please enter within 255 characters'),
  confirmPassword: yup
    .string()
    .trim()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Passwords mismatch'),
});

const Login = ({ step, setStep, backPreviousStep, goNextStep, booking, setBooking }) => {
  const [socialError, setSocialError] = useState();

  const onContinue = customer => {
    setBooking({ ...booking, customer });
    goNextStep();
  };

  const signUpMutation = useSignUpMutation({
    onSuccess: data => onContinue(data),
  });

  const signInGoogle = useGoogleLogin({
    onSuccess: data => {
      setSocialError('');
      onContinue(data);
    },
    onError: e => {
      setSocialError(e?.message);
    },
  });

  const signInFacebook = useFacebookLogin({
    onSuccess: data => {
      setSocialError('');
      onContinue(data);
    },
    onError: e => setSocialError(e?.message),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phoneCode: '1',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = values => {
    signUpMutation.mutate(values);
  };

  return (
    <div className="booking-content-wrapper">
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <h4 className="text-center mb-1">Create your account</h4>
          {signUpMutation.isError && (
            <Alert color="danger">
              <div className="alert-body">{signUpMutation?.error?.message || 'Error'}</div>
            </Alert>
          )}
          {socialError && (
            <Alert color="danger">
              <div className="alert-body">{socialError}</div>
            </Alert>
          )}
          <Form className="mb-2" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>Email</Label>
              <Controller control={control} name="email" render={({ field }) => <Input type="text" {...field} />} />
              {errors?.email && <FormText color="danger">{errors?.email?.message || 'Error'}</FormText>}
            </FormGroup>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <Label>Phone</Label>
                  <PhoneInput
                    country="us"
                    countryCodeEditable
                    inputProps={{ name: 'phoneNumber', id: 'phoneNumber' }}
                    {...field}
                    onChange={(value, data) => {
                      if (value.slice(data.dialCode?.length || 0).length === 0) {
                        field.onChange(data.dialCode);
                      } else {
                        field.onChange(value);
                      }
                      setValue('phoneCode', data.dialCode);
                    }}
                    buttonClass="border-none"
                    inputClass="w-100"
                  />
                  {!!errors.phoneNumber && <FormText color="danger">{errors?.phoneNumber?.message || 'Error'}</FormText>}
                </FormGroup>
              )}
            />
            <FormGroup>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <InputPasswordToggle
                    className="input-group-merge"
                    placeholder="At least 8 characters"
                    label="Password"
                    htmlFor="basic-default-password"
                    {...field}
                  />
                )}
              />
              {errors?.password && <FormText color="danger">{errors?.password?.message || 'Error'}</FormText>}
            </FormGroup>
            <FormGroup>
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field }) => (
                  <InputPasswordToggle
                    className="input-group-merge"
                    placeholder="At least 8 characters"
                    label="Confirm your password"
                    htmlFor="basic-default-confirm-password"
                    {...field}
                  />
                )}
              />
              {errors?.confirmPassword && <FormText color="danger">{errors?.confirmPassword?.message || 'Error'}</FormText>}
            </FormGroup>
            <Button block type="submit" color="primary">
              Sign up
            </Button>
            <div className="text-center mt-2">
              Already have an account?{' '}
              <Button color="link" className="p-0" onClick={() => setStep(AppointmentSteps.Login)}>
                Sign in here
              </Button>
            </div>
          </Form>
          <div className="divider mb-3">
            <div className="divider-text">OR</div>
          </div>

          <Button onClick={signInGoogle.mutate} outline block color="secondary" className="btn-social">
            <img className="" src={GoogleIcon} />
            Sign in with Google
          </Button>
          <Button onClick={signInFacebook.mutate} outline block color="secondary" className="btn-social">
            <img className="" src={FacebookIcon} />
            Sign in with Facebook
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
