import moment from 'moment-timezone';
import useShop from './useShop';

const useMomentTimezone = () => {
  const shop = useShop();

  const momentTimezone = (...args) => {
    const res = moment(...args);
    return res.tz(shop?.timezone);
  };

  return momentTimezone;
};

export default useMomentTimezone;
