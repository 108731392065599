import { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Form } from 'reactstrap';
import ReactCodeInput from 'react-code-input';

const COUNT_START_POINT = 59;
const VerifyResetPasswordForm = ({ isLoading, username, onSubmit, onResend }) => {
  const [count, setCount] = useState(COUNT_START_POINT);
  const timer = useRef();

  const startCount = newCount => {
    clearTimeout(timer.current);
    if (newCount >= 0) {
      setCount(newCount);
      timer.current = setTimeout(() => {
        startCount(newCount - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    if (count === COUNT_START_POINT) {
      startCount(COUNT_START_POINT);
    }
  }, [count]);

  const onResendCode = () => {
    setCount(COUNT_START_POINT);
    onResend();
  };

  const onChangeCode = value => {
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  return (
    <div className="booking-content-wrapper">
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <h4 className="text-center mb-1">Enter 6-digits code</h4>
          <p className="text-center text-secondary mb-5 font-weight-light">Your code was sent to {username}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="d-flex justify-content-center">
          <ReactCodeInput inputMode="numeric" type="number" fields={6} onChange={onChangeCode} disabled={isLoading} />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <div className="mb-5 d-flex flex-column align-items-center">
            <p className="mt-3 mb-3 text-secondary text-center font-weight-light">
              A message with a verification code has been sent. Enter the code to continue.
            </p>

            <div className="mb-5 d-flex justify-content-center">
              <p className="mr-2 mb-0 text-secondary font-weight-light">Resend code</p>
              <div>
                {count > 0 ? (
                  <p className="mb-25 font-weight-bold">{count}s</p>
                ) : (
                  <Button color="link" className="p-0" onClick={onResendCode} disabled={isLoading}>
                    Resend
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyResetPasswordForm;
