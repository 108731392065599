import UCookie from 'universal-cookie';

const prefix = '@OLIVER_BOOKING-';

export const cookie = new UCookie();

export const Cookie = {
  setItem: (key, data) => {
    return cookie.set(`${prefix}${key}`, data, { path: '/' });
  },
  getItem: key => {
    return cookie.get(`${prefix}${key}`);
  },
  removeItem: key => {
    return cookie.remove(`${prefix}${key}`, { path: '/' });
  },
};
