import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledButtonDropdown } from "reactstrap";
import './available-dates.style.scss';
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { ChevronRight, DownloadCloud } from "react-feather";

const AvailableDatesPicker = (props) => {

  const {
    disabled,
    loadedDates,
    refetchDates,
    selectedDate,
    setSelectedDate,
    setFormattedDate,
    onChange,
    selectedProduct,
    isFetchingDates,
    isLoadingDates,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [visibleDatesCount, setVisibleDatesCount] = useState(5);
  const [maxedOut, setMaxedOut] = useState(false);

  useEffect(() => {
    setVisibleDatesCount(5);
    setMaxedOut(false);
  }, [selectedProduct]);

  const selectOpening = (opening) => {
    setIsOpen(false);
    const formattedValue = opening.date;
    if (opening && !opening.available) {
      setSelectedDate(opening);
      return;
    }
    if (moment(formattedValue).isBefore(moment())) {
      const firstAvailableDate = loadedDates.find(date => date.available);
      setSelectedDate(firstAvailableDate);
      setFormattedDate(moment(firstAvailableDate?.date).format('MM/DD/YYYY'));
    } else {
      setSelectedDate(opening);
      setFormattedDate(formattedValue);
    }
    if (opening) {
      onChange(opening.times);
    } else {
      onChange([[{label: "9:00 AM", enabled: false}, {label: "10:00 AM", enabled: false}, {label: "11:00 AM", enabled: false}], [{label: "12:00 PM", enabled: false}, {label: "1:00 PM", enabled: false}, {label: "2:00 PM", enabled: false}], [{label: "3:00 PM", enabled: false}, {label: "4:00 PM", enabled: false}, {label: "5:00 PM", enabled: false}]]);
    }
  };

  const toggle = (e) => {
    // On touch devices when the dropdown is open,
    // a touchstart event is triggered when the user clicks near the bottom of the screen.
    // This causes the dropdown to close because the doc body intercepts the touch start
    // and interprets it as a scroll event, thereby closing the dropdown inadvertently.
    // This prevents the dropdown from closing in that case.
    if (e?.type === 'touchstart' && isOpen) {
      e.preventDefault();
      return;
    }
    setIsOpen(!isOpen);
  };

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  function isToday(date) {
    const today = new Date();
    return isSameDay(date, today);
  };

  function isTomorrow(date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return isSameDay(date, tomorrow);
  };

  const title = useMemo(() => {
    if (!selectedDate) return "Date";
    const date = new Date(selectedDate);
    if (isToday(date)) {
      return disabled ? "Date" : "Today";
    }
    if (isTomorrow(date)) return "Tomorrow";
    return moment(selectedDate).format('MMM. D - dddd');
  }, [selectedDate]);

  const displayedDates = loadedDates
  .filter((opening, index) => opening.available)
  .sort((a, b) => new Date(a.date) - new Date(b.date))
  .filter((opening, index) => index < visibleDatesCount)
  .map((opening, index) => {
    return {
      ...opening,
    };
  });

  const availableDatesCount = useMemo(() => {
    return loadedDates.filter(opening => opening.available).length;
  }, [loadedDates]);

  const loadMore = () => {
    if (maxedOut) {
      return;
    }
    if (availableDatesCount > visibleDatesCount) {
      const newCount = visibleDatesCount + 5;
      setVisibleDatesCount(newCount);
    } else {
      const lastAvailableDate = displayedDates.length > 0 ? displayedDates[displayedDates.length - 1] : null;
      if (moment().add(230, 'days').isAfter(moment(lastAvailableDate?.date))) {
        setMaxedOut(true);
      }
      refetchDates(lastAvailableDate);
    }
  };

  const renderOpening = (opening) => {
    return (
      <Row
        className="available-opening"
        key={`${opening.date}-opening`}
      >
        <div className="title-container">
          <p className="available-opening-title">{moment(opening.date).format('MMMM Do - dddd')}</p>
          <p className="available-opening-subtitle">{selectedProduct}</p>
        </div>
        <div className={`times-container ${moment(selectedDate).format('MM/DD/YYYY') === opening.date ? 'active' : ''}`}>
          <>
            {`${opening.times.length} opening${opening.times.length === 1 ? '' : 's'}`}
            <ChevronRight size="20px" style={{ marginLeft: '5px' }} />
          </>
        </div>
      </Row>
    );
  };

  return (
    <div className="available-dates-picker-container">
      <UncontrolledButtonDropdown
        className="opening-dropdown align-items-center mx-1 mt-0"
        isOpen={isOpen}
        toggle={toggle}
      >
        <DropdownToggle
          outline
          color="primary"
          className="custom-toggle py-2"
          disabled={disabled}
        >
          {title}
          {!isOpen ? <RxCaretDown className="custom-toggle-arrow" size="30px" /> : <RxCaretUp className="custom-toggle-arrow" size="30px" />}
        </DropdownToggle>
        <DropdownMenu
          className="opening-dropdown-menu"
        >
          <>
            {displayedDates.map((opening, index) => (
              <DropdownItem
                className="opening-dropdown-item"
                key={`${title}-${index}`}
                active={moment(selectedDate).format('MM/DD/YYYY') === opening.date}
                onClick={() => selectOpening(opening)}
              >
                {renderOpening(opening)}
              </DropdownItem>
            ))}
            {(isFetchingDates && visibleDatesCount > availableDatesCount) && (
              <DropdownItem
                className="opening-dropdown-item"
                key="loading"
                disabled
              >
                <div className="loading-container">
                  Loading more dates...
                  <Spinner
                    className="ml-1 align-items-center"
                    color="primary"
                    size="sm"
                  />
                </div>
              </DropdownItem>
            )}
            {(!isFetchingDates || visibleDatesCount === displayedDates.length) && (
              <div
                className="opening-dropdown-item"
                key="load-more"
              >
                <div className="load-more-container">
                  <Button
                    className="load-more-button"
                    color="primary"
                    disabled={maxedOut}
                    outline
                    onClick={() => loadMore()}
                  >
                    {maxedOut ? 'There are no more available dates.' : 'Load more'}
                    <DownloadCloud size="20px" style={{ marginLeft: '5px' }} />
                  </Button>
                </div>
              </div>
            )}
          </>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default AvailableDatesPicker;
