import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Input, Label, Spinner } from "reactstrap";
import { AlertCircle, ChevronRight } from "react-feather";
import * as Sentry from "@sentry/react";
import { borderRadius } from "polished";

const DepositCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    booking,
    setBooking,
    receiptEmail,
    setReceiptEmail,
    setIsComplete,
    setIsPaymentMethodSaved,
    isPaymentIntent,
  } = props;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let result;
    if (isPaymentIntent) {
      result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          receipt_email: receiptEmail,
        },
        redirect: "if_required",
      });
      if (!result.error) {
        setIsLoading(false);
        setIsComplete(true);
        setBooking(prev => ({ ...prev, deposit: { ...prev.deposit, completed_at: new Date(), amount: (result.paymentIntent?.amount / 100.0) } }));
        setIsPaymentMethodSaved(true);
        return;
      }
    } else {
      result = await stripe.confirmSetup({
        elements,
        confirmParams: {
        },
        redirect: "if_required",
      });
      if (!result.error) {
        setIsLoading(false);
        setIsPaymentMethodSaved(true);
        return;
      }
    }
    const { error } = result;

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else if (error.code === "payment_intent_unexpected_state" && error.payment_intent?.status === "succeeded") {
      setMessage("This payment has already been paid. You have not been double-charged.");
    } else {
      setMessage("An unexpected error occurred.");
      Sentry.captureMessage(error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
      type: 'tabs',
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {(stripe && elements) && (
        <>
          <Label for="receiptEmail" style={{ marginTop: '12px', fontWeight: '500', lineHeight: '1.5' }}>Email for receipt (optional)</Label>
          <Input
            type="email"
            name="receiptEmail"
            placeholder="Email"
            value={receiptEmail}
            onChange={(e) => setReceiptEmail(e.target.value)}
          />
          <div style={{ textAlign: 'center', height: '50px' }}>
            <Button color="primary" style={{ width: '60%', marginTop: '20px', height: '50px', borderRadius: '25px' }} disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? <Spinner size="sm" style={{ marginBottom: '3px' }} /> : "Make payment"}
              </span>
              {!isLoading && <ChevronRight style={{ marginLeft: '10px', marginBottom: '2px' }} size={18} />}
            </Button>
          </div>
        </>
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message" style={{ marginTop: '30px', textAlign: 'center' }}><AlertCircle size={18} style={{ marginRight: '4px', marginBottom: '3px' }} />{message}</div>}
    </form>
  );
};

export default DepositCheckoutForm;
