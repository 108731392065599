import { useState, useEffect, forwardRef } from 'react';
import { FormText, Row, Col } from 'reactstrap';
import { get, isEqual } from 'lodash';
import Flatpickr from 'react-flatpickr';
import { DATE_FORMAT_FRIENDLY, MOMENT_DATE_FORMAT_FRIENDLY } from '@utils';
import Select from '../select';
import './input-date-time.scss';
import momentTz from 'moment-timezone';

const InputDateTime = forwardRef(({ timeOptions, onChange, errorMessage, onDateChange, value, minDate, disable }, ref) => {
  const [date, setDate] = useState(value?.date);
  const [time, setTime] = useState(value?.time);

  useEffect(() => {
    const equal = isEqual(
      {
        date,
        time,
      },
      value
    );

    if (equal) return;

    if (!!date && !!time && !equal) {
      onChange({
        date,
        time,
      });

      return;
    }

    onChange(null);
  }, [date, time]);

  const handleChange = (field, value) => {
    switch (field) {
      case 'date':
        setDate(value);
        onDateChange(value);
        if (!value) {
          setTime(null);
        }
      case 'time':
        setTime(value);
      default:
    }
  };

  return (
    <>
      <Row>
        <Col xs={8}>
          <Flatpickr
            required
            id="date"
            className="form-control datepicker"
            options={{
              dateFormat: DATE_FORMAT_FRIENDLY,
              minDate: momentTz().format(MOMENT_DATE_FORMAT_FRIENDLY) || minDate,
              disable: disable || [],
            }}
            onChange={value => {
              handleChange('date', get(value, '0'));
            }}
            value={date}
            ref={ref}
          />
        </Col>
        <Col xs={4} className="select-time-column">
          <Select
            id="time"
            options={timeOptions}
            placeholder=""
            styles={{
              control: provided => ({
                ...provided,
                minHeight: 42,
                border: '1px solid #dddddd',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }),
            }}
            onChange={newValue => handleChange('time', newValue)}
            value={time}
          />
        </Col>
      </Row>
      {errorMessage && (
        <Row className="date-input-error pb-2">
          <Col xs={12}>
            <FormText className="position-absolute b-0" color="danger">
              {errorMessage}
            </FormText>
          </Col>
        </Row>
      )}
    </>
  );
});

export default InputDateTime;
