import axios from 'axios';
import toast from '@components/toast';
import jwtDefaultConfig from './jwtDefaultConfig';
import { SpecialErrors, CookieKeys } from '../../../utility/Constant';
import { Cookie } from '@utility/Cookie';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 60000 * 2; // 2 minutes

let requestInterceptor;
let responseInterceptor;

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.eject(requestInterceptor);
    requestInterceptor = axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        const clientId = this.getClientId();
        if (config.headers?.Authorization === 'No-Need-Authorization') {
          delete config.headers['Authorization'];
        } else {
          if (accessToken && accessToken !== 'undefined') {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }

        if (clientId) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers['X-Client-Id'] = clientId;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.eject(responseInterceptor);
    responseInterceptor = axios.interceptors.response.use(
      response => {
        response.data.success === false && toast.error(response?.data?.message || 'An unknown error occurred');
        return response;
      },
      error => {
        if (error && error.response && error.response.status) {
          const { response } = error;
          const { message } = response?.data || {};
          switch (error.response.status) {
            case 500:
              if (message === SpecialErrors.InvalidTenant) {
                this.logout()
              } else {
                this.toastError("Oops! Something went wrong. Please try again in a few seconds.");
              }
              break
            case 401:
              if (message !== SpecialErrors.InvalidCode) {
                this.logout(window.location.href);
              }
              break;
            case 404:
              if (response?.config?.url?.includes('/clients/by_user_id')) {
                this.logout(window.location.href);
              }
              break;
            case 400:
              if (message === SpecialErrors.VerificationMaximumExceeded) {
                // Swallow the error toast - let UI implementation handle it
                break;
              }
            default:
              this.toastError(message);
              break
          }
        } else {
          if (error.message === "Network Error") {
            this.logout(window.location.href);
            location.reload();
          }
        }

        return Promise.reject(error);
      }
    );
  }

  toastError(message) {
    if (!Object.values(SpecialErrors).includes(message)) {
      toast.error(message);
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  getClientId() {
    const pathUrl = window.location.pathname;
    const pathUrlArray = pathUrl.split('/');
    return pathUrlArray[1] || localStorage.getItem(this.jwtConfig.storageClientIdKeyName);
  }

  setClientId(value) {
    localStorage.setItem(this.jwtConfig.storageClientIdKeyName, value);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  logout(destination) {
    Cookie.removeItem(CookieKeys.user);
    Cookie.removeItem(CookieKeys.shop);
    this.setToken('');
    const dest = destination || window.location.href;
    window.location.assign(dest);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
