import { NavItem } from 'reactstrap';
import { NavLink, useParams } from 'react-router-dom';
import { User, Mail, CheckSquare } from 'react-feather';
import Avatar from '@components/avatar';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import themeConfig from '@configs/themeConfig';
import useShop from '@hooks/useShop';
import useUserState from '@hooks/useUserState';
import ShopDefaultAvatar from '@src/assets/images/logo/OLI_APP_4C_LTBG.png';

const BookingLeftSide = () => {
  const [user] = useUserState();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const params = useParams();
  const shop = useShop();

  return isTabletOrMobile && document.getElementById('wrapper') ? (
    ReactDOM.createPortal(
      <>
        <div className="main-menu menu-light w-100 d-block booking-left-menu">
          <div className="main-menu-content">
            <div className="shop-banner">
              <div className="shop-banner__avatar">
                <img className="img-fluid mb-75" src={shop?.avatar || ShopDefaultAvatar} />
              </div>
              <h4 className="text-center">{shop?.name}</h4>
            </div>
            <ul className="navigation navigation-main background-color-transparent">
              <NavItem active>
                <NavLink exact to={`/${params.clientId}/profile`} className="nav-link" activeClassName="active">
                  <div className="d-flex align-items-center">
                    <User size={14} className="mr-75" />
                    My Profile
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact to={`/${params.clientId}/appointments`} className="nav-link" activeClassName="active">
                  <div className="d-flex align-items-center">
                    <Mail size={14} className="mr-75" />
                    My Appointments
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact to={`/${params.clientId}`} className="nav-link" activeClassName="active">
                  <div className="d-flex align-items-center">
                    <CheckSquare size={14} className="mr-75" />
                    Book New Appointment
                  </div>
                </NavLink>
              </NavItem>
            </ul>
          </div>

          <NavLink to={`/${params.clientId || ''}`} className="navbar-brand m-0 d-block text-center">
            <img src={themeConfig.app.appLogoTextImage} alt="logo" style={{ height: 20 }} />
          </NavLink>
        </div>
      </>,
      document.getElementById('wrapper')
    )
  ) : (
    <>
      <div className="d-none d-md-flex flex-column align-items-center justify-content-center mb-2">
        <Avatar size="xl" icon={<User />} src={user?.avatar} className="mb-1" color="primary" />
        <h4>{user?.name}</h4>
      </div>
      <div className="main-menu menu-light w-100 d-block booking-left-menu">
        <div className="main-menu-content">
          <ul className="navigation navigation-main background-color-transparent">
            <NavItem active>
              <NavLink exact to={`/${params.clientId}/profile`} className="nav-link" activeClassName="active">
                <div className="d-flex align-items-center">
                  <User size={14} className="mr-75" />
                  My Profile
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink exact to={`/${params.clientId}/appointments`} className="nav-link" activeClassName="active">
                <div className="d-flex align-items-center">
                  <Mail size={14} className="mr-75" />
                  My Appointments
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink exact to={`/${params.clientId}`} className="nav-link" activeClassName="active">
                <div className="d-flex align-items-center">
                  <CheckSquare size={16} className="mr-75" />
                  Book New Appointment
                </div>
              </NavLink>
            </NavItem>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BookingLeftSide;
