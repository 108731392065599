import React, { useEffect, useRef } from 'react';
import { Input } from 'reactstrap';

const VerificationCodeInput = ({ codeLength, doClearCode, setDoClearCode, setCode, isLoading }) => {
  const inputsRef = useRef([]);

  // Clear out Input boxes when value is empty
  useEffect(() => {
    if (doClearCode) {
      inputsRef.current.forEach((input) => {
        input.value = '';
      })
      setDoClearCode(false);
    }
  }, [doClearCode]);

  const handleInput = (e, inputIndex) => {
    const input = e.target;
    let code = input.value;

    // If a new character is typed, only keep the new one
    if (code.replace(/-/g, '').length === 6) {
      handleMobileCodeUtility(code);
      return;
    }
    if (code.length > 1) {
      code = code.slice(-1);
      input.value = code;
    }

    // Move focus to next input box if current input has a value
    if (code && code.length === 1 && inputIndex < codeLength - 1) {
      inputsRef.current[inputIndex + 1].focus();
    }

    // Call setCode if last input is filled
    if (inputIndex === codeLength - 1 && code) {
      const codeValue = Array.from(inputsRef.current, (input) => input.value).join('');
      if (codeValue.length === codeLength) {
        setCode(codeValue);
      }
    }
  };

  function handleMobileCodeUtility(code) {
    if (code.replace(/-/g, '').length !== 6) {
      return;
    }

    code = code.replace(/-/g, '');
    inputsRef.current[0].focus();
    inputsRef.current.forEach((input, i) => {
      input.value = code[i] || '';
      handleInput({ target: input }, i);
    });
  };

  const handleBack = (e, inputIndex) => {
    const input = e.target;
    const code = input.value;

    // Move focus to previous input box
    if (e.key === 'Backspace' && !code && inputIndex > 0) {
      inputsRef.current[inputIndex - 1].focus();
    }
  };

  const handlePaste = (e) => {
    // Prevent pasting more than the expected code length
    const pasteData = e.clipboardData.getData('text/plain');
    const pasteLength = pasteData.length;

    if (pasteLength !== codeLength && pasteLength !== (codeLength + 1)) {
      e.preventDefault();
    } else {
      const sanitizedPasteData = pasteData.replace('-', '');

      // Move focus to first input box
      inputsRef.current[0].focus();

      // Split pasted value into each input box
      inputsRef.current.forEach((input, i) => {
        input.value = sanitizedPasteData[i] || '';
        handleInput({ target: input }, i);
      });
    }
  };

  const inputs = Array.from({ length: codeLength }, (_, i) => (
    <React.Fragment key={i}>
      <Input
        disabled={isLoading}
        className="verification-code-input"
        type="text"
        inputMode="numeric"
        maxLength={6}
        innerRef={(ref) => (inputsRef.current[i] = ref)}
        onChange={(e) => handleInput(e, i)}
        onKeyDown={(e) => handleBack(e, i)}
        onPaste={handlePaste}
        autoComplete="new-password" // disable password suggestions
      />
      {(i === (codeLength / 2) - 1) && <div className="hyphen" />}
    </React.Fragment>
  ));

  return <div className="verification-code-input-container">{inputs}</div>;
};

export default VerificationCodeInput;
