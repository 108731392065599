export const isDepositRequired = (shop, product, isReturningClient) => {
  if (shop?.feature_settings?.deposit_collection_v1_enabled !== undefined && shop?.feature_settings?.deposit_collection_v1_enabled === true) {
    if (!product) return true;
    const metadata = product.metadata;
    if (!metadata.depositRequiredBeforeBooking) return false;
    if (isReturningClient && metadata.collectDepositFromReturningClients) {
      return true;
    } else if (!isReturningClient && metadata.collectDepositFromNewClients) {
      return true;
    } else if (metadata.collectDepositFromNewClients && metadata.collectDepositFromReturningClients) {
      return true;
    }
  }
  return false;
};

export const saveAppointmentBeforeDeposit = (product) => {
  return product?.metadata?.appointmentRequestWithoutDeposit;
};
