import { CheckCircle, MessageCircle } from "react-feather";
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import shopState from "../../../../recoil/atoms/current-shop";
import { useEffect, useMemo, useState } from "react";
import userState from "../../../../recoil/atoms/auth/user";
import '@styles/react/pages/page-booking.scss';
import { useMutation } from "react-query";
import { sendSms } from "../../../../apis/messaging.api";

const NavbarSms = ({ buttonText }) => {
  const shop = useRecoilValue(shopState);
  const user = useRecoilValue(userState);
  const [showPopover, setShowPopover] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(!user?.phoneNumber);
  const [isLoading, setIsLoading] = useState(false);
  const [isTextSent, setIsTextSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState('');
  const [errors, setErrors] = useState({
    phoneNumber: null,
  });

  useEffect(() => {
    if (isEditingPhoneNumber && user?.phoneNumber) {
      setIsEditingPhoneNumber(false);
    }
  }, [user?.phoneNumber]);

  const isTextUsEnabled = useMemo(() => {
    return shop?.feature_settings?.booking_text_us_enabled !== undefined &&
      shop.feature_settings.booking_text_us_enabled.enabled;
  });

  const onShowPopover = () => {
    setShowPopover(true);
  };

  const phoneRegExp = /^(\d{3})[- ]?\)?(\d{3})[- ]?(\d{4})$/;
  const validatePhoneNumber = (phoneNumber) => {
    return phoneRegExp.test(phoneNumber) && phoneNumber.length === 10;
  };

  const onChangePhoneNumber = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    setPhoneNumber(sanitizedPhoneNumber);
    const displayPhone = sanitizedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    setDisplayPhoneNumber(displayPhone);
    if ((errors.phoneNumber && validatePhoneNumber(sanitizedPhoneNumber)) || sanitizedPhoneNumber.length === 0) {
      setErrors({ ...errors, phoneNumber: null });
    }
  };

  const formatPhone = phone => {
    if (!phone) return "";
    return phone
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const reset = () => {
    setShowPopover(false);
    setPhoneNumber('');
    setDisplayPhoneNumber('');
    setErrors({ ...errors, phoneNumber: null });
    setIsLoading(false);
    setIsTextSent(false);
    setIsEditingPhoneNumber(!user?.phoneNumber);
  };

  const sendSmsMutation = useMutation(sendSms);
  const onStartConversation = () => {
    if (isLoading) return;
    setIsLoading(true);
    if (isEditingPhoneNumber && !validatePhoneNumber(phoneNumber)) {
      setErrors({ ...errors, phoneNumber: 'Please enter a valid phone number' });
      setIsLoading(false);
      return;
    }
    if (!phoneNumber && !user?.phoneNumber) {
      setIsEditingPhoneNumber(true);
      setErrors({ ...errors, phoneNumber: 'Please enter a phone number' });
      setIsLoading(false);
      return;
    }
    sendSmsMutation.mutate({
      phone_number: phoneNumber || user?.phoneNumber,
    }, {
      onSuccess: () => {
        setTimeout(() => {
          setIsLoading(false);
          setIsTextSent(true);
          setTimeout(() => {
            setIsTextSent(false);
            reset();
          }, 1500);
        }, 3000);
      },
      onError: () => {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {isTextUsEnabled && (
        <>
          <Button
            outline
            className="text-us-header-button"
            onClick={onShowPopover}
          >
            {buttonText ? buttonText : 'text with us'}
            <MessageCircle size={20} style={{ paddingBottom: '4px', marginLeft: '2px' }} />
          </Button>
          <Modal
            className="w-100 text-us-modal"
            style={{ marginTop: '100px', paddingRight: '12px' }}
            placement="bottom-center"
            isOpen={showPopover}
            toggle={() => reset()}
            delay={0}
            fade={false}
            target="new-client"
            trigger="legacy"
          >
            <ModalHeader
              className="text-us-modal-header"
            >
              <div
                className="d-flex align-items-center flex-column text-us-header-content"
              >
                <MessageCircle size={22} style={{ marginBottom: '4px' }} />
                text with us
              </div>
            </ModalHeader>
            <ModalBody
              className="text-us-modal-body"
            >
              <p style={{ fontWeight: 'bold' }}>
                Need to ask a question about your pet or schedule an appointment?<br />
              </p>
              {!isEditingPhoneNumber && (
                <>
                  <p>
                    We will text you at<br /><span style={{ color: '#03464C' }}>{`${formatPhone(user?.phoneNumber)}`}</span><br />to start the conversation.
                  </p>
                  <p>
                    We look forward to chatting with you!
                  </p>
                  <button
                    className="small-text-button"
                    onClick={() => setIsEditingPhoneNumber(true)}
                  >
                    change phone number
                  </button>
                </>
              )}
              {isEditingPhoneNumber && (
                <>
                  <Label
                    for="phoneNumber"
                    className="form-label text-us-label"
                  >
                    What is your phone number?
                  </Label>
                  <Input
                    id="phoneNumber"
                    maxLength={14}
                    className="input"
                    placeholder="Mobile Phone"
                    type="tel"
                    value={displayPhoneNumber}
                    disabled={isLoading}
                    onChange={(e) => {
                      onChangePhoneNumber(e.target.value);
                    }}
                    autocomplete="off"
                    style={{ height: '40px', width: '80%', margin: 'auto' }}
                  />
                  {(!!errors.phoneNumber) && (
                    <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                      {errors.phoneNumber}
                    </span>
                  )}
                  <p className="mt-1">
                    We will text you to start the conversation.
                  </p>
                  <p>
                    We look forward to chatting with you!
                  </p>
                  {user?.phoneNumber && (
                    <button
                      className="small-text-button"
                      onClick={() => setIsEditingPhoneNumber(false)}
                    >
                      use previous phone number
                    </button>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter
              style={{ justifyContent: 'center' }}
              className="d-flex flex-column align-items-center"
            >
              <Button
                className="text-us-confirm-button"
                color="primary"
                onClick={() => onStartConversation()}
                loading={isLoading}

              >
                {!isTextSent ? 'Okay' : 'Text Sent'}
                {isTextSent ? (
                  <CheckCircle size={20} style={{ marginLeft: '4px' }} />
                ) : (
                  <>
                    {isLoading && <Spinner style={{ marginLeft: '4px' }} size="sm" />}
                    {!isLoading && <MessageCircle size={20} style={{ marginLeft: '4px' }} />}
                  </>
                )}
              </Button>
              <button
                className="small-text-button"
                style={{ marginTop: '12px', color: `${isLoading ? 'white' : ''}` }}
                disabled={isLoading}
                onClick={() => reset()}
              >
                cancel
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};
export default NavbarSms;
