import themeConfig from '@configs/themeConfig';
import { ChevronLeft, Lock } from 'react-feather';
import { Button, Row, Spinner } from 'reactstrap';
import { getOfficeLocations } from '../../../../../apis/appointment.api';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import useShop from '../../../../../utility/hooks/useShop';
import { useRecoilState } from 'recoil';
import userState from '../../../../../recoil/atoms/auth/user';

const LocationSelection = ({ goNextStep, booking, setStep, setBooking, backPreviousStep }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const shop = useShop();
  const [user, setUser] = useRecoilState(userState);

  const {
    data,
    refetch,
  } = useQuery(['getLocations'], () => getOfficeLocations(), {
    onSuccess: (data) => {
      if (data?.data) {
        setLocations(data.data);
      }
      setIsLoading(false);
    },
    staleTime: 10000,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="client-type-selection">
      <div className="d-flex align-items-center booking-header" style={{ paddingTop: '8px' }}>
      </div>
      {!user ? (
        <Row>
          <Button className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
            <ChevronLeft />
          </Button>
        </Row>
      ) : (
        <div className='mt-2' />
      )}
      <div className="header">
        <span
          className="header-title"
        >
          At which location do you want to schedule your pet's appointment?
        </span>
        <hr />
        <div className="buttons-container">
          {isLoading ? (
            <div
              className="d-flex justify-content-center"
            >
              <Spinner
                color="primary"
              />
            </div>
          ) : (
            <>
              {locations?.length === 0 && (
                <>
                {`No locations found. Please contact ${shop?.name} to book an appointment.`}
                </>
              )}
              {locations?.length > 0 && (
                <>
                  {(locations?.map(
                    (location) => (
                      <Button
                        id={`location-btn-${location.id}`}
                        key={location.id}
                        className="button"
                        color="primary"
                        outline
                        onClick={() => {
                          setStep(2);
                          setBooking(prev => ({ ...prev, location: { ...location } }));
                          goNextStep();
                        }}
                      >
                        {location.name}
                      </Button>
                    ),
                  )
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationSelection;
