// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import themeConfig from '@configs/themeConfig';
import { NavLink } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import NavbarBookmarks from './NavbarBookmarks';
import NavbarUser from './NavbarUser';
import { Button } from 'reactstrap';
import { MessageCircle } from 'react-feather';
import NavbarSms from './NavbarSms';

const ThemeNavbar = props => {
  // ** Props
  const { skin, setSkin, isHidden, setIsHidden, setMenuVisibility, showNavbarMenu } = props;
  const history = useHistory();
  const params = useParams();
  const { pathname } = history.location;
  const clientId = pathname.split('/')[1];
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <Fragment>
      {!isTabletOrMobile && (
        <NavLink to={`/${clientId || ''}`} className="navbar-brand">
          <img src={themeConfig.app.appLogoTextImage} alt="logo" />
        </NavLink>
      )}
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} showNavbarMenu={showNavbarMenu} />
      </div>
      <div className='ml-auto d-flex'>
        <NavbarSms />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} isHidden={isHidden} setIsHidden={setIsHidden} />
    </Fragment>
  );
};

export default ThemeNavbar;
