import cn from 'classnames';
import { Button, Media, CustomInput, Row, Col } from 'reactstrap';
import { Camera, ChevronRight } from 'react-feather';
import Avatar from '@components/avatar';
import AvatarGroup from '@components/avatar-group';

const ProductItem = ({ selected, data, hideProviders, onClick, isShowCost = true, isShowDuration = true }) => (
  <>
    <Row className="px-3">
      <p className="product-item-title">{data.name}</p>
      <div className="product-item-details-parent">
        <div className='product-item-details'>
          {isShowDuration && <span className={cn('width-100', selected ? '' : 'text-secondary')}>{data.metadata?.time} min</span>}
          {isShowCost && (
            <span className={cn('product-item-cost', selected ? '' : 'text-blue')}>
              ${data.metadata?.cost}
            </span>
          )}
        </div>
      </div>
    </Row>
    <Row className="px-3">
      <div className="product-item-description">{data.description}</div>
    </Row>
  </>
);
export default ProductItem;
