import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import themeConfig from '@configs/themeConfig';
import { ChevronLeft, Lock } from "react-feather";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import shopState from "@recoil/atoms/current-shop";
import { createVerificationCode } from "@apis";
import * as Sentry from "@sentry/react";

const ReturningClientInfo = ({ goNextStep, booking, setStep, setBooking, backPreviousStep }) => {
  const [shop] = useRecoilState(shopState);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    phoneNumber: null,
    email: null,
  });

  useEffect(() => {
    if (shop) {
      Sentry.metrics.increment("booking_returning_client_info", 1, {
        tags: { shop: shop?.name, user: undefined },
      });
    }
  }, [shop]);

  const phoneRegExp = /^(\d{3})[- ]?\)?(\d{3})[- ]?(\d{4})$/;
  const validatePhoneNumber = (phoneNumber) => {
    return phoneRegExp.test(phoneNumber) && phoneNumber.length === 10;
  };

  const emailRegExp = /^[\w.]+[\+]*[\w.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const validateEmail = (email) => {
    return emailRegExp.test(email);
  };

  const hideEmail = () => {
    return phoneNumber.length > 0;
  };

  const hidePhoneNumber = () => {
    return email.length > 0;
  };

  const onChangePhoneNumber = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    setPhoneNumber(sanitizedPhoneNumber);
    const displayPhone = sanitizedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    setDisplayPhoneNumber(displayPhone);
    if ((errors.phoneNumber && validatePhoneNumber(sanitizedPhoneNumber)) || sanitizedPhoneNumber.length === 0) {
      setErrors({ ...errors, phoneNumber: null });
    }
  };

  const onChangeEmail = (email) => {
    setEmail(email);
    if ((errors.email && validateEmail(email)) || email.length === 0) {
      setErrors({ ...errors, email: null });
    }
  };

  const verificationCodeMutation = useMutation(createVerificationCode);

  const beginVerification = ({ clientInfo }) => {
    if (shop?.is_demo && !!shop?.feature_settings?.skip_demo_shop_booking_otp_enabled) {
      setIsLoading(false);
      setBooking(prev => ({ ...prev, verificationError: false, customer: { ...clientInfo } }));
      goNextStep();
      return;
    }

    const body = {
      email: clientInfo.email,
      phoneNumber: clientInfo.phoneNumber,
      phoneCode: clientInfo.phoneCode
    };
    verificationCodeMutation.mutate(body,
      {
        onSuccess: (data) => {
          setIsLoading(false);
          setBooking(prev => ({ ...prev, verificationError: false, customer: { ...clientInfo } }));
          goNextStep();
        },
        onError: (data) => {
          setIsLoading(false);
          if (data?.response?.data?.message === 'VerificationMaximumExceeded') {
            setBooking(prev => ({ ...prev, verificationError: true, customer: { ...clientInfo } }));
            goNextStep();
          }
        },
      }
    );
  };

  const onContinue = () => {
    setIsLoading(true);
    if (phoneNumber.length > 0) {
      if (!validatePhoneNumber(phoneNumber) || phoneNumber.length !== 10) {
        setErrors({
          ...errors,
          phoneNumber: 'Please enter a valid 10-digit phone number',
        });
        setIsLoading(false);
        return;
      }
    } else if (email.length > 0) {
      if (!validateEmail(email)) {
        setErrors({ ...errors, email: 'Please enter a valid email' });
        setIsLoading(false);
        return;
      }
    } else {
      setIsLoading(false);
      return;
    }
    beginVerification({ clientInfo: { phoneNumber, email, phoneCode: '1' } });
  };

  const canContinue = () => {
    return phoneNumber.length > 0 || email.length > 0;
  };

  return (
    <div className="client-type-selection">
      <div className="d-flex align-items-center booking-header" style={{ paddingTop: '8px' }}>
      </div>
      <Row>
        <Button className="btn-icon rounded-circle mr-50 mb-0 pt-0" color="gray" onClick={backPreviousStep}>
          <ChevronLeft />
        </Button>
        <div style={{ marginTop: '10px', textAlign: 'center', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
          <img src={themeConfig.app.appLogoTextImage} alt='logo' style={{ height: '40px' }}/>
          <Lock className='verification-header-lock-logo' size="15" stroke-width="3" />
        </div>
      </Row>
      <div className="header">
        <span
          className="header-title"
        >
          Let's verify your account using your mobile phone or email.
        </span>
        <div className="buttons-container">
          {!hidePhoneNumber() && (
            <>
              <Input
                maxLength={14}
                className="input"
                id="phoneNumber"
                placeholder="Mobile Phone"
                type="tel"
                value={displayPhoneNumber}
                disabled={isLoading}
                onChange={(e) => {
                  onChangePhoneNumber(e.target.value);
                }}
                autocomplete="off"
              />
            {(!!errors.phoneNumber) && (
              <span className="text-danger" style={{ fontSize: '0.75rem' }}>
              {errors.phoneNumber}
              </span>
            )}
            </>
          )}
          {!hideEmail() && !hidePhoneNumber() && (
            <div className="or-label">
              <Label>{'or'}</Label>
            </div>
          )}
          {!hideEmail() && (
            <>
              <Input
                className="input"
                id="email"
                placeholder="Email Address"
                type="email"
                value={email}
                disabled={isLoading}
                onChange={(e) => {
                  onChangeEmail(e.target.value);
                }}
                autocomplete="off"
                style={{ marginBottom: `${errors.email ? '0' : '12px'}` }}
              />
              {!!errors.email && (
                <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                  {errors.email}
                </span>
              )}
            </>
          )}
          <Button
            className="button"
            color="primary"
            loading={isLoading}
            disabled={!canContinue() || isLoading || errors.phoneNumber || errors.email}
            onClick={() => {
              onContinue();
            }}
          >
            Send verification code
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReturningClientInfo;
