import { Suspense } from 'react';
// ** Third Party Components
import { Row, Col, Container } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import useShop from '@hooks/useShop';
import '@styles/react/pages/page-booking.scss';
import ShopDefaultAvatar from '@src/assets/images/logo/OLI_APP_4C_LTBG.png';
import AppointmentsLeftSide from './components/AppointmentsLeftSide';
import { appointmentsRoutes } from './routes';
import { useQuery } from 'react-query';
import { getShopInfo } from '@apis';
import { useSetRecoilState } from 'recoil';
import shopState from '../../../recoil/atoms/current-shop';

const AppointmentsApp = () => {
  const shop = useShop();
  const params = useParams();
  const clientId = window.location.pathname.split('/')[1];
  const setShop = useSetRecoilState(shopState);

  useQuery('shopInfo', () => getShopInfo(clientId), {
    enabled: !!clientId,
    select: res => res.data?.data,
    onSuccess: res => {
      setShop(res);
    },
  });

  return (
    <>
      <div className="align-items-center pl-2 mb-2 mb-lg-5 d-none d-sm-none d-md-flex" style={{ height: 98 }}>
        <img
          className="img-fluid rounded mr-1"
          src={shop?.avatar || ShopDefaultAvatar}
          style={{ width: 98, height: 98, objectFit: 'contain' }}
        />
        <h4 className="mb-0">{shop?.name}</h4>
      </div>
      <Container className="booking">
        <Row className="row-eq-height">
          <Col md={0} lg={3}>
            <AppointmentsLeftSide />
          </Col>
          <Col md={12} lg={8} xl={6}>
            <Router basename={`${params?.clientId}/appointments`}>
              <Switch>
                {appointmentsRoutes.map(route => (
                  // Render more <Route>s with the same paths as
                  // above, but different components this time.
                  <Route
                    key={route.id}
                    path={route.path}
                    exact={route.exact}
                    render={props => {
                      return (
                        <Suspense fallback={null}>
                          <route.component {...props} clientId={params?.clientId} />
                        </Suspense>
                      );
                    }}
                  />
                ))}
              </Switch>
            </Router>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AppointmentsApp;
