import { useRecoilState } from 'recoil';
import confirmBoxState from '@recoil/atoms/common/confirm-box';

let resolve;
const useConfirm = () => {
  const [confirmBox, setConfirmBox] = useRecoilState(confirmBoxState);

  const confirm = args => {
    const { title, message, confirmButtonColor } = args || {};
    return new Promise(res => {
      setConfirmBox({
        show: true,
        title,
        message,
        confirmButtonColor,
      });
      resolve = res;
    });
  };

  const onConfirm = () => {
    setConfirmBox({
      show: false,
      title: '',
      message: '',
    });
    resolve(true);
  };

  const onCancel = () => {
    setConfirmBox({
      show: false,
      title: '',
      message: '',
    });
    resolve(false);
  };

  return { confirm, confirmBox, onCancel, onConfirm };
};

export default useConfirm;
