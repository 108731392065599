import { Controller } from 'react-hook-form';
import InputDateTime from './input-date-time';

const FormDateTime = ({ name, control, onChange, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error, isDirty } }) => {
        return (
          <InputDateTime
            {...props}
            errorMessage={error?.message}
            {...field}
            onChange={value => {
              onChange?.(value);
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
};

export default FormDateTime;
