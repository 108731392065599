import { useEffect, useMemo, useState } from 'react';
import { Button, FormGroup, Input, Label, Spinner, Col, Row, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CookieKeys } from '../../../../../utility/Constant';
import { Calendar } from 'react-feather';
import { Cookie } from '../../../../../utility/Cookie';
import Select from 'react-select';
import moment from 'moment';
import { border, borderColor } from 'polished';

const sanitizePhoneNumber = (phoneNumber) => {
  return phoneNumber?.replace(/[-()\s]/g, '');
};

const phoneRegExp = /^(\d{3})[- ]?\)?(\d{3})[- ]?(\d{4})$/;
const validatePhoneNumber = (phoneNumber) => {
  return phoneRegExp.test(phoneNumber) && phoneNumber.length === 10;
};

const schema = yup.object().shape({
  firstName: yup.string().trim().nullable().required('Name is required')
    .max(255, 'Please enter within 255 characters')
    .test('min-name', 'Please enter your full first and last name',
      (v, context) => {
        if (!!v && (v.includes(' ') && v.split(' ').every(v => !!v && v.length > 1))) {
          return true;
        }
        return false;
      }
    ),
  email: yup.string().nullable().email('Please enter a valid email').max(255, 'Please enter within 255 characters'),
  phoneNumber: yup
    .string()
    .nullable()
    .test('min-phone', 'Please enter a valid 10-digit phone number', (value, context) => {
      const tmp = sanitizePhoneNumber(value);
      return !tmp || tmp.length < 10 || (validatePhoneNumber(tmp) && tmp.length === 10);
    })
    .trim()
    .max(14, 'Phone number should be a maximum of 12 characters'),
  phoneCode: yup.string().nullable().trim(),
  petName: yup.string().nullable().trim(),
  // Address validation
  address1: yup.string().nullable().trim(),
  address2: yup.string().nullable().trim(),
  city: yup.string().nullable().trim(),
  state: yup.string().nullable().trim(),
  zip: yup.string().nullable().trim(),
});

const CustomerForm = ({ allowRemind, setAllowRemind, onSubmit: onSubmitProp, isLoading, user, breeds, shop, booking }) => {
  const defaultValues = {
    firstName: user?.firstName,
    phoneNumber: `${user?.phoneNumber || ''}`,
    phoneCode: user?.phoneCode || '1',
    email: user?.email,
    address1: booking?.customer?.address1,
    address2: booking?.customer?.address2,
    city: booking?.customer?.city,
    state: booking?.customer?.state,
    zip: booking?.customer?.zip,
    petName: '',
    sex: '',
    birthdate: null,
    breed: '',
    breed_id: '',
    note: '',
  };

  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    setError,
    formState: { errors, isDirty, dirtyFields },
    getValues,
  } = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(schema),
  });
  const watchForm = watch();
  const [addNewPet, setAddNewPet] = useState(false);
  const [sexes, setSexes] = useState([
    {
      label: 'Please select your pet\'s sex...',
      value: 'none',
    },
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    },
  ]);

  useEffect(() => {
    const prevValues = Cookie.getItem(CookieKeys.confirmationForm);
    if (prevValues) {
      Object.keys(prevValues).forEach(key => {
        setValue(key, prevValues[key], { shouldDirty: true });
      });
      Cookie.removeItem(CookieKeys.confirmationForm);
    }

    return () => {
      Cookie.setItem(CookieKeys.confirmationForm, JSON.stringify(getValues()));
    };
  }, []);

  const isTelemedicine = useMemo(() => {
    const product = booking.selectedProducts[0]?.products[0];
    if (product && product.isTelemedicine) return true;
    return false;
  }, [booking.selectedProducts]);

  const availablePatientNames = useMemo(() => {
    const result = booking?.customer?.patients?.filter(patient => {
      return !patient.deceased_at;
    }) || [];
    return result;
  }, [booking?.customer?.patients, booking?.species?.id]);

  useEffect(() => {
    if (availablePatientNames.length === 0) {
      setAddNewPet(true);
    }
  }, [availablePatientNames]);

  useEffect(() => {
    if (availablePatientNames.length === 1) {
      setValue('petName', availablePatientNames[0].name, { shouldDirty: true });
      setValue('sex', availablePatientNames[0].sex, { shouldDirty: true });
      setValue('birthdate', availablePatientNames[0].birthdate, { shouldDirty: true });
    }
  }, []);

  const choosePetName = (name) => {
    setValue('petName', name, { shouldDirty: true });
    const tmp = availablePatientNames.find(patient => patient.name === name);
    if (tmp) {
      setValue('sex', tmp.sex || 'none', { shouldDirty: true });
      setValue('birthdate', tmp.birthdate || null, { shouldDirty: true });
      setValue('breed', tmp.breed || '', { shouldDirty: true });
      setValue('breed_id', tmp.breed_id || '', { shouldDirty: true });
    }
  };

  const onStartAddNewPet = () => {
    setAddNewPet(true);
    setValue('petName', '', { shouldDirty: true });
    setValue('sex', 'none', { shouldDirty: true });
    setValue('birthdate', null, { shouldDirty: true });
    setValue('breed', '', { shouldDirty: true });
    setValue('breed_id', '', { shouldDirty: true });
  };

  const isInsuranceOfferEnabled = () => {
    if (shop?.feature_settings?.vertical_insure_enabled !== undefined && shop?.feature_settings?.vertical_insure_enabled?.enabled === true) {
      return true;
    }
    return false;
  };

  const patientDetailsRequired = () => {
    if (shop?.feature_settings?.booking_patient_demographic_collection_enabled !== undefined &&
      shop?.feature_settings?.booking_patient_demographic_collection_enabled === true) {
      if (addNewPet || (availablePatientNames.length === 0 && moment(booking?.customer?.created_at).isAfter(moment().subtract(5, 'minutes')) && !isLoading)) {
        return true;
      }
    }
    if (isInsuranceOfferEnabled()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (shop && patientDetailsRequired()) {
      setValue('sex', 'none', { shouldDirty: true });
    }
  }, [shop]);

  const emailAndPhoneRequired = () => {
    if (isTelemedicine) return true;
    if (shop?.feature_settings?.require_phone_and_email_for_client_booking !== undefined &&
      shop?.feature_settings?.require_phone_and_email_for_client_booking === true) {
      return true;
    } else {
      return false;
    }
  };

  const addressRequired = () => {
    const settingRequires = shop?.feature_settings?.collect_address_from_new_clients_enabled;
    if (settingRequires !== undefined && settingRequires === true) {
      if (moment(booking?.customer?.created_at).isAfter(moment().subtract(5, 'minutes')) && !isLoading) {
        return true;
      }
    }

    return false;
  };

  const states = useMemo(() => {
    return [
      { label: 'AL', value: 'AL' },
      { label: 'AK', value: 'AK' },
      { label: 'AZ', value: 'AZ' },
      { label: 'AR', value: 'AR' },
      { label: 'CA', value: 'CA' },
      { label: 'CO', value: 'CO' },
      { label: 'CT', value: 'CT' },
      { label: 'DE', value: 'DE' },
      { label: 'FL', value: 'FL' },
      { label: 'GA', value: 'GA' },
      { label: 'HI', value: 'HI' },
      { label: 'ID', value: 'ID' },
      { label: 'IL', value: 'IL' },
      { label: 'IN', value: 'IN' },
      { label: 'IA', value: 'IA' },
      { label: 'KS', value: 'KS' },
      { label: 'KY', value: 'KY' },
      { label: 'LA', value: 'LA' },
      { label: 'ME', value: 'ME' },
      { label: 'MD', value: 'MD' },
      { label: 'MA', value: 'MA' },
      { label: 'MI', value: 'MI' },
      { label: 'MN', value: 'MN' },
      { label: 'MS', value: 'MS' },
      { label: 'MO', value: 'MO' },
      { label: 'MT', value: 'MT' },
      { label: 'NE', value: 'NE' },
      { label: 'NV', value: 'NV' },
      { label: 'NH', value: 'NH' },
      { label: 'NJ', value: 'NJ' },
      { label: 'NM', value: 'NM' },
      { label: 'NY', value: 'NY' },
      { label: 'NC', value: 'NC' },
      { label: 'ND', value: 'ND' },
      { label: 'OH', value: 'OH' },
      { label: 'OK', value: 'OK' },
      { label: 'OR', value: 'OR' },
      { label: 'PA', value: 'PA' },
      { label: 'RI', value: 'RI' },
      { label: 'SC', value: 'SC' },
      { label: 'SD', value: 'SD' },
      { label: 'TN', value: 'TN' },
      { label: 'TX', value: 'TX' },
      { label: 'UT', value: 'UT' },
      { label: 'VT', value: 'VT' },
      { label: 'VA', value: 'VA' },
      { label: 'WA', value: 'WA' },
      { label: 'WV', value: 'WV' },
      { label: 'WI', value: 'WI' },
      { label: 'WY', value: 'WY' }
    ];
  });

  const notesPlaceholderText = () => {
    // notes_placeholder_text_for_client_booking
    if (shop?.feature_settings?.notes_placeholder_text_for_client_booking !== undefined &&
      shop?.feature_settings?.notes_placeholder_text_for_client_booking.length > 0) {
      return `${shop?.feature_settings?.notes_placeholder_text_for_client_booking}`;
    } else {
      return "Anything else you'd like to tell us about your pet?";
    }
  };

  const notesRequired = () => {
    if (shop?.feature_settings?.require_notes_for_client_booking !== undefined &&
      shop?.feature_settings?.require_notes_for_client_booking === true) {
      return true;
    } else {
      return false;
    }
  };

  const hideEmail = () => {
    return !emailAndPhoneRequired() && !!getValues()?.phoneNumber && getValues().phoneNumber.length !== 0;
  };

  const hidePhone = () => {
    return !emailAndPhoneRequired() && user && !!getValues()?.email && getValues().email.length !== 0;
  };

  const onSubmit = data => {
    const phoneNumber = sanitizePhoneNumber(data?.phoneNumber);
    const email = data?.email;
    let hasError = false;
    if (!phoneNumber && !email) {
      setError('phoneNumber', { message: `A mobile phone number ${emailAndPhoneRequired() ? 'and' : 'or'} email is required` });
      hasError = true;
      if (emailAndPhoneRequired()) {
        setError('email', { message: 'A valid email address is required' });
      }
    }
    if (emailAndPhoneRequired() && (!email || !phoneNumber)) {
      if (!phoneNumber) {
        setError('phoneNumber', { message: 'A phone number is required' });
      } else {
        setError('email', { message: 'A valid email address is required' });
      }
      hasError = true;
    }
    if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      setError('phoneNumber', { message: 'Please enter a valid 10-digit phone number' });
      hasError = true;
    }
    if (!data.petName) {
      setError('petName', { message: 'Pet name is required' });
      hasError = true;
    }
    if (data.petName && (data.petName.length > 30 || data.petName.split(' ').length >= 3)) {
      setError('petName', { message: 'Please limit your pet\'s name to 30 characters. If you have more than one pet, please schedule separate appointments.' });
      hasError = true;
    }
    if (!data.note && notesRequired()) {
      setError('note', { message: 'Please provide detail about the visit' });
      hasError = true;
    } else if (data.note && data.note.length > 500) {
      setError('note', { message: 'Please limit your note to 500 characters' });
      hasError = true;
    } else if (notesRequired() && data.note && data.note.length < 5) {
      setError('note', { message: 'Please provide a little more detail - even just a few words about the visit' });
      hasError = true;
    }
    if (addressRequired()) {
      if (!data.address1) setError('address1', { message: 'Address is required' });
      if (!data.city) setError('city', { message: 'City is required' });
      if (!data.state) setError('state', { message: 'State is required' });
      if (!data.zip) setError('zip', { message: 'Zip is required' });
      if (!data.address1 || !data.city || !data.state || !data.zip) {
        hasError = true;
      }
    }
    if (patientDetailsRequired()) {
      if (!data.sex || data.sex === 'none') {
        setError('sex', { message: 'Please select an option' });
        hasError = true;
      }
      if (!data.birthdate) {
        setError('birthdate', { message: 'Pet\'s date of birth is required' });
        hasError = true;
      } else if (new Date(data.birthdate) > new Date()) {
        setError('birthdate', { message: 'Pet\'s date of birth must be in the past' });
        hasError = true;
      } else if (new Date(data.birthdate) < new Date('1776-01-01')) {
        setError('birthdate', { message: 'Is your pet a time traveler? Please pick a birthdate after 1776 :)' });
        hasError = true;
      }
      if (breeds && breeds.length > 0 && (!data.breed || data.breed === '')) {
        setError('breed_id', { message: 'Pet\'s breed is required' });
        hasError = true;
      } else {
        if (!data.breed || data.breed === '') {
          setError('breed', { message: 'Pet\'s breed is required' });
          hasError = true;
        }
      }
    }
    if (hasError) {
      return;
    }
    onSubmitProp(
      {
        ...data,
        phoneNumber,
        petName: data?.petName,
        breed_id: data?.breed_id?.value
      },
      isDirty,
      dirtyFields
    );
  };

  const onChangeBreedId = (value) => {
    if (value) {
      setValue('breed_id', value);
      const b = breeds.find(b => b.value === value.value);
      setValue('breed', b?.label);
    } else {
      setValue('breed_id', '');
      setValue('breed', '');
    }
  };

  return (
    <form className="flex-grow-1" onSubmit={handleSubmit(onSubmit)} style={{ animation: 'fadeIn 0.5s' }}>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <FormGroup>
            <Label for="firstName">Your information</Label>
            <Input
              id="firstName"
              disabled={user && user?.firstName?.length > 0}
              className="form-input"
              placeholder="Full Name"
              {...field}
            />
            {!!errors.firstName && (
              <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                {errors.firstName.message}
              </span>
            )}
          </FormGroup>
        )}
      />
      {(!hidePhone() || hideEmail()) && (
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <FormGroup
              className="mb-0"
            >
              <Input
                disabled={user && user?.phoneNumber?.length > 0}
                maxLength={14}
                className="form-input"
                id="phoneNumber"
                placeholder="Mobile Phone"
                type="tel"
                { ...field }
              />
              {(!!errors.phoneCode || !!errors.phoneNumber) && (
                <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                  {errors.phoneCode?.message || errors.phoneNumber?.message}
                </span>
              )}
            </FormGroup>
          )}
        />
      )}
      {((!hideEmail() && !hidePhone()) || (hideEmail() && hidePhone())) && (
        <div className="or-label">
          {!addressRequired() && !emailAndPhoneRequired() && (
            <Label>or</Label>
          )}
        </div>
      )}
      {(!hideEmail() || hidePhone()) && (
        <>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormGroup className="mb-0">
                <Input
                  disabled={user && user?.email?.length > 0}
                  className="form-input"
                  id="email"
                  placeholder="Email Address"
                  {...field}
                />
                {!!errors.email && (
                  <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                    {errors.email.message}
                  </span>
                )}
              </FormGroup>
            )}
          />
        </>
      )}

      {addressRequired() && (
        <>
          <Controller
            name="address1"
            control={control}
            render={({ field }) => (
              <FormGroup
                className="mb-0 mt-1"
              >
                <Input
                  disabled={booking?.customer?.address1?.length || 0 > 0}
                  className="form-input"
                  id="address1"
                  placeholder="Street Address"
                  type="text"
                  { ...field }
                />
                {!!errors.address1 && (
                  <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                    {errors.address1?.message}
                  </span>
                )}
              </FormGroup>
            )}
          />
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <FormGroup
                className="mb-0 mt-1"
              >
                <Input
                  disabled={booking?.customer?.address1?.length || 0 > 0}
                  className="form-input"
                  id="address2"
                  placeholder="Apartment, Suite, etc."
                  type="text"
                  { ...field }
                />
                {!!errors.address1 && (
                  <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                    {errors.address2?.message}
                  </span>
                )}
              </FormGroup>
            )}
          />
        </>
      )}
      {(addressRequired() || isInsuranceOfferEnabled()) && (
        <Row className="px-1">
          {addressRequired() && (
            <Col xs={6} className="pr-1 pl-0">
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <FormGroup
                    className="mb-0 mt-1"
                  >
                    <Input
                      disabled={booking?.customer?.city?.length || 0 > 0}
                      className="form-input"
                      id="city"
                      placeholder="City"
                      type="text"
                      { ...field }
                    />
                    {!!errors.city && (
                      <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                        {errors.city?.message}
                      </span>
                    )}
                  </FormGroup>
                )}
              />
            </Col>
          )}
          <Col className="pr-1 pl-0">
            <Controller
              id="state"
              name="state"
              control={control}
              render={({ field }) => (
                <FormGroup
                  className="mb-0 mt-1"
                >
                  <Input
                    disabled={booking?.customer?.state?.length || 0 > 0}
                    style={{
                      height: '50px',
                    }}
                    id="state"
                    type="select"
                    { ...field }
                  >
                    {states.map(state => (
                      <option
                        key={state.value}
                        value={state.value}
                      >
                        {state.label}
                      </option>
                    ))}
                  </Input>
                  {!!errors.state && (
                    <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                      {errors.state?.message}
                    </span>
                  )}
                </FormGroup>
              )}
            />
          </Col>
          <Col className="px-0">
            <Controller
              name="zip"
              control={control}
              render={({ field }) => (
                <FormGroup
                  className="mb-0 mt-1"
                >
                  <Input
                    disabled={booking?.customer?.zip?.length || 0 > 0}
                    className="form-input"
                    id="zip"
                    placeholder="Zip"
                    type="text"
                    { ...field }
                  />
                  {!!errors.zip && (
                    <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                      {errors.zip?.message}
                    </span>
                  )}
                </FormGroup>
              )}
            />
          </Col>
        </Row>
      )}

      {(availablePatientNames?.length > 0 && !addNewPet) && (
        <Controller
          name="petName"
          control={control}
          render={({ field }) => (
            <FormGroup
            >
              <div>
                <Label className="mt-1" for="petName">Pet's name</Label>
              </div>
              <div>
                <UncontrolledButtonDropdown
                  className="pet-selection form-input"
                  id="petName"
                  placeholder="Pet Name"
                  type="select"
                  value={field.value}
                  style={{ cursor: 'pointer', width: '70%' }}
                  {...field}
                >
                  <DropdownToggle
                    className="pet-selection"
                    caret
                    color="primary"
                    outline
                  >
                    {`${field.value || 'Select pet name'}`}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{ width: '100%', overflow: 'auto', maxHeight: '200px' }}
                  >
                    {availablePatientNames?.map(patient => (
                      <DropdownItem
                        key={patient.id}
                        value={patient.name}
                        style={{ cursor: 'pointer', padding: '5px 10px' }}
                        onClick={(e) => {
                          choosePetName(e.target.value);
                        }}
                      >
                        {patient.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <Button
                  className="ml-1"
                  color="primary"
                  onClick={() => {
                    setValue('petName', '');
                    onStartAddNewPet();
                  }}
                  style={{ width: '25%', fontSize: '12px', padding: '5px 10px' }}
                  >
                  <span>New pet +</span>
                </Button>
                {!!errors.petName && (
                  <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                    {errors.petName.message}
                  </span>
                )}
              </div>
            </FormGroup>
          )}
        />
      )}
      {(addNewPet || (patientDetailsRequired() && !!getValues('petName'))) && (
        <>
          {addNewPet && (
            <Controller
              name="petName"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <Label className="mt-1" for="petName">Pet's name</Label>
                  <Input disabled={isLoading} className="form-input" id="petName" placeholder="Pet Name" {...field} />
                  {!!errors.petName && (
                    <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                      {errors.petName.message}
                    </span>
                  )}
                </FormGroup>
              )}
            />
          )}
          {patientDetailsRequired() && (
            <>
              <Controller
                name="sex"
                control={control}
                render={({ field }) => (
                  <FormGroup>
                    <Input
                      disabled={isLoading}
                      className="form-input-select"
                      id="sex"
                      type="select"
                      { ...field }
                    >
                      {sexes.map(sex => (
                        <option
                          key={sex.value}
                          value={sex.value}
                        >
                          {sex.label}
                        </option>
                      ))}
                    </Input>
                    {!!errors.sex && (
                      <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                        {errors.sex.message}
                      </span>
                    )}
                  </FormGroup>
                )}
              />
              <Controller
                name="birthdate"
                control={control}
                render={({ field }) => (
                  <FormGroup>
                    <Label className="mt-0" for="birthdate">Pet's date of birth</Label>
                    <Input
                      disabled={isLoading}
                      className="form-input"
                      id="birthdate"
                      type="date"
                      { ...field }
                    />
                    {!!errors.birthdate && (
                      <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                        {errors.birthdate.message}
                      </span>
                    )}
                  </FormGroup>
                )}
              />
              {breeds && breeds.length > 0 ? (
                <Controller
                  name="breed_id"
                  control={control}
                  render={({ field }) => (
                    <FormGroup>
                      <Select
                        id="breed_id"
                        placeholder="Pet's breed"
                        styles={{
                          control: (provided, { isDisabled, isFocused, isSelected }) => ({
                            ...provided,
                            minHeight: 28,
                            border: '1px solid #dddddd',
                            borderRadius: '0.357rem',
                            borderColor: isSelected ? '#03464C' : '#dddddd',
                            boxShadow: 'none',
                            '&:hover': {
                              borderColor: '#03464C',
                            }
                          }),
                          dropdownIndicator: provided => ({
                            ...provided,
                            padding: '8px 4px',
                          }),
                          placeholder: provided => ({
                            ...provided,
                            fontSize: 14,
                          }),
                          singleValue: provided => ({
                            ...provided,
                            fontSize: 14,
                          }),
                          option: (provided, { isDisabled, isFocused, isSelected }) => ({
                            ...provided,
                            fontSize: 14,
                            color: '#03464C',
                            padding: '8px 12px',
                            backgroundColor: isFocused ? '#dae1e7' : 'white',
                          }),
                          valueContainer: (provided, { isSelected, isFocused }) => ({
                            ...provided,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 12,
                          }),
                          menu: provided => ({ ...provided, zIndex: 10 }),
                          ...({}),
                        }}
                        isDisabled={isLoading}
                        options={breeds}
                        { ...field }
                        onChange={(value) => {
                          onChangeBreedId(value);
                        }}
                      />
                      {!!errors.breed_id && (
                        <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                          {errors.breed_id.message}
                        </span>
                      )}
                    </FormGroup>
                  )}
                />
              ) : (
                <Controller
                  name="breed"
                  control={control}
                  render={({ field }) => (
                    <FormGroup>
                      <Input
                        disabled={isLoading}
                        className="form-input"
                        id="breed"
                        placeholder="Breed"
                        type="text"
                        { ...field }
                      />
                      {!!errors.breed && (
                        <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                          {errors.breed.message}
                        </span>
                      )}
                    </FormGroup>
                  )}
                />
              )}
            </>
          )}
        </>
      )}

      <Controller
        name="note"
        control={control}
        render={({ field }) => (
          <FormGroup>
            <Input disabled={isLoading} className="form-input" type="textarea" id="note" placeholder={notesPlaceholderText()} {...field} style={{ minHeight: 90 }} />
            {!!errors.note && (
              <span className="text-danger" style={{ fontSize: '0.75rem' }}>
                {errors.note.message}
              </span>
            )}
          </FormGroup>
        )}
      />

      <Button
        color="primary"
        className="pill-button"
        type="submit"
        disabled={isLoading}
      >
        {isLoading && <Spinner size="sm" color="white" className="mr-75" />}
        {!isLoading &&
          <>
            <Calendar className='confirm-button-icon'/>
            <div className='confirm-button-text'>
              {`${!shop?.metadata?.isAutoAccept ? 'REQUEST' : 'CONFIRM'} APPOINTMENT`}
            </div>
          </>
        }
      </Button>
      {isTelemedicine && (
        <Label className='sms-opt-in-label mt-1' check onClick={() => setAllowRemind(!allowRemind)} color="primary">
          We will send you a text message with a video call meeting link prior to this virtual appointment. By booking this appointment, you consent to receive text messages from {shop?.name}. Reply STOP at any time to opt-out.
        </Label>
      )}
      {!isTelemedicine && (
        <FormGroup className="sms-opt-in-form" check>
          <Input
            className="sms-opt-in-checkbox"
            type="checkbox"
            checked={allowRemind}
            onChange={() => setAllowRemind(!allowRemind)}
          />
          <Label className='sms-opt-in-label' check onClick={() => setAllowRemind(!allowRemind)} color="primary">
            I consent to receive email or text messages from {shop?.name} regarding my account. Reply STOP at any time to opt-out.
          </Label>
        </FormGroup>
      )}
    </form>
  );
};

export default CustomerForm;
