import { atom } from 'recoil';

export const defaultSelectedProduct = {
  provider: null,
  products: [],
};

export const initialBookingState = {
  selectedProducts: [defaultSelectedProduct],
  customer: null,
  dateTime: null,
  dateTimeProviderId: null,
  roomId: null,
  verificationError: false,
  species: null,
  returningClientFlow: false,
  isSaved: false,
  isDropoff: false,
  location: null,
};

const bookingState = atom({
  key: 'booking',
  default: initialBookingState,
});

export default bookingState;
