import React, { useState } from 'react';
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col, Label } from 'reactstrap';
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';

const OliverDropdown = ({ title, items, selectedItem, onChange, disabled, contentTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UncontrolledButtonDropdown
      className="custom-dropdown align-items-center mx-1 mt-0"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
    <DropdownToggle
      outline
      color="primary"
      className="custom-toggle py-2"
      disabled={disabled}
    >
      {title}
      {!isOpen ? <RxCaretDown className="custom-toggle-arrow" size="30px" /> : <RxCaretUp className="custom-toggle-arrow" size="30px" />}
    </DropdownToggle>
    <DropdownMenu
      className="custom-dropdown-menu"
    >
      {items.map(item => (
        <DropdownItem
          className="custom-dropdown-item"
          key={`${title}-${item.id}`}
          active={typeof selectedItem === 'function' ? selectedItem(item) : selectedItem === item}
          onClick={() => onChange(item)}
        >
          {contentTitle ? item[contentTitle] : item.name}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
  );
};

export default OliverDropdown;
