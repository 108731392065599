import React, { useState } from 'react';
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col, Label } from 'reactstrap';
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';
import ProductItem from '../../apps/booking/components/ProductItem';

const ProductDropdown = ({ title, items, selectedItem, onChange, disabled, shop }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UncontrolledButtonDropdown direction="down" className="custom-dropdown mx-1 mt-0" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
    <DropdownToggle
      outline
      color="primary"
      className="custom-toggle py-2"
      disabled={disabled}
    >
      {title}
      {!isOpen ? <RxCaretDown className="custom-toggle-arrow" size="30px" /> : <RxCaretUp className="custom-toggle-arrow" size="30px" />}
    </DropdownToggle>
    <DropdownMenu
      className="custom-dropdown-menu"
    >
      {items.map(item => (
        <DropdownItem
          className="custom-dropdown-item"
          key={`${title}-${item.id}`}
          active={typeof selectedItem === 'function' ? selectedItem(item) : selectedItem === item}
          onClick={() => onChange(item)}
        >
          <ProductItem
            key={item.id}
            data={item}
            hideProviders
            onClick={() => onChange(item)}
            selected={selectedItem}
            isShowCost={shop?.metadata?.isBookingShowProductPrice}
            isShowDuration={shop?.metadata?.isBookingShowProductDuration}
          />
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledButtonDropdown>
  );
};

export default ProductDropdown;
