import { useRecoilState } from "recoil";
import NavbarSms from "../../../../../@core/layouts/components/navbar/NavbarSms";
import shopState from "../../../../../recoil/atoms/current-shop";

const NoAvailability = (props) => {
  const {
    goNextStep,
    backPreviousStep,
    booking,
    setBooking
  } = props;

  const [shop] = useRecoilState(shopState);

  const formatPhoneNumber = (phoneNumber) => {
    return [
      phoneNumber.slice(0, 3),
      phoneNumber.slice(3, 6),
      phoneNumber.slice(6)
    ].join('-');
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <h1
        className="section-label-text"
        style={{ fontWeight: 600, fontSize: '1.5rem', textAlign: 'center' }}>
          No appointments available
      </h1>
      <p
        className="section-label-text pt-1"
        style={{ opacity: 0.5, textAlign: 'center', lineHeight: '1.5rem' }}
      >
          We're sorry, we are currently not accepting appointments online.<br/>
          Feel free to text us or call us at {formatPhoneNumber(shop?.phoneNumber)}.
      </p>
      <div style={{ maxWidth: '300px' }}>
        <NavbarSms buttonText="text us" />
      </div>
    </div>
  );
};

export default NoAvailability;
