import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useQuery, useMutation } from 'react-query';
import { getAppointmentDetail, deleteAppoinments, updateAppointment, getShopInfo } from '@apis';
import { useParams } from 'react-router';
import momentTz from 'moment-timezone';
import { ChevronLeft } from 'react-feather';
import { useRecoilState, useSetRecoilState } from 'recoil';
import UILoader from '@components/ui-loader';
import toast from '@components/toast';
import useUserState from '@hooks/useUserState';
import useJwt from '@src/@core/auth/jwt/useJwt';
import externalKeyState from '@recoil/atoms/auth/externalKey';
import '@styles/react/libs/react-select/_react-select.scss';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import RescheduleForm from './RescheduleForm';
import shopState from '../../../../../recoil/atoms/current-shop';
import useMomentTimezone from '../../../../../utility/hooks/useMomentTimezone';

const AppointmentReschedule = ({ history, clientId }) => {
  const { jwt } = useJwt();
  const params = useParams();
  const [user] = useUserState();
  const setShop = useSetRecoilState(shopState);
  jwt.setClientId(clientId);
  const momentTimezone = useMomentTimezone();

  const { data, isLoading, refetch } = useQuery(['getAppointmentDetail', params.appointmentId], async () => {
    const { data } = await getAppointmentDetail(params.appointmentId);
    return data.data;
  });

  const [externalKey] = useRecoilState(externalKeyState);

  useQuery(
    ['shopInfo', user, externalKey],
    async () => {
      const { data } = await getShopInfo(clientId);
      const { phoneNumber, phoneCode } = data.data;
      const shopInfo = { ...data.data, ...(phoneNumber && phoneCode && { phone: `+${phoneCode}${phoneNumber}` }) };
      setShop(shopInfo);
      return shopInfo;
    },
    {
      enabled: !!externalKey || !!user,
    }
  );

  const upsertAppointmentMutation = useMutation(
    obj => {
      const { date, note } = obj;
      const { id, label, reminderSMS, reminderEmail } = data;
      return updateAppointment({
        id,
        label,
        note,
        isReminderSMS: reminderSMS,
        isReminderEmail: reminderEmail,
        userId: user?.id,
        note,
        date: momentTimezone(`${momentTimezone(date?.date).tz(momentTz.tz.guess()).format('YYYY-MM-DD')} ${date?.time?.value}`)
          .utc()
          .toISOString(),
        items: data.items.map(item => ({
          id: item.id,
          productId: item.metadata.productId,
          productTime: item.metadata.productTime,
          anyone: !item.providerId,
          providerId: item.providerId,
        })),
        source: 'BOOKING_SITE',
      });
    },
    {
      onSuccess: (res, variables) => {
        toast.success('Appointment Updated');
        refetch();
        history.push('/');
      },
    }
  );

  const onSubmit = values => {
    upsertAppointmentMutation.mutate(values);
    // history.push(`/${data?.id}`);
  };

  return (
    <UILoader blocking={isLoading}>
      <Card className="">
        <CardHeader className="justify-content-start align-items-center px-0 px-md-2">
          <Button className="rounded-circle btn-icon mr-50" color="flat-secondary" size="sm" onClick={() => history.goBack()}>
            <ChevronLeft />
          </Button>
          <h4 className="text-center text-lg-left mb-0">Reschedule appointment</h4>
        </CardHeader>
        <CardBody className="px-0 px-md-2">
          {!isLoading && (
            <RescheduleForm appointment={data} onSubmit={onSubmit} isUpdatingAppointment={upsertAppointmentMutation.isLoading} />
          )}
        </CardBody>
      </Card>
    </UILoader>
  );
};

export default AppointmentReschedule;
