import { lazy } from 'react';
import { lazyRetry } from '../../../App';
import Appointments from './components/Appointments';
import AppointmentDetail from './components/AppointmentDetail';
import AppointmentReschedule from './components/AppointmentReschedule';

export const appointmentsRoutes = [
  {
    path: '/',
    exact: true,
    className: '',
    component: Appointments,
    id: 'appointment-list',
  },
  {
    path: '/:appointmentId',
    exact: true,
    className: '',
    component: AppointmentDetail,
    id: 'appointment-detail',
  },
  {
    path: '/:appointmentId/reschedule',
    exact: true,
    className: '',
    component: AppointmentReschedule,
    id: 'appointment-reschedule',
  },
];
