import DefaultAvatarImg from '@src/assets/images/icons/avatar-default.png';

export const DATE_FORMAT_FRIENDLY = 'D, M j';
export const MOMENT_DATE_FORMAT_FRIENDLY = 'ddd, MMM DD';
export const API_ADMIN_URL = 'https://admin.getoliver.com/v1/api';
export const API_TENANT_URL = 'https://api.getoliver.com/services';
export const API_TENANT_PUBLIC_URL = 'https://api.getoliver.com/v1/api/external';
export const CORE_API_URL = process.env.REACT_APP_CORE_API_URL || '/core/v1';
export const MESSAGE_API_URL = process.env.REACT_APP_MESSAGE_API_URL || '/message';

export const HOUR_FORMAT = 'hh:mm A';
export const MONTH_DATE_HOUR_FORMAT = 'MMM DD, hh:mm A';
export const YEAR_MONTH_DATE_FORMAT = 'YYYY-MM-DD';
export const YEAR_MONTH_DATE_HOUR_FORMAT = 'YYYY-MM-DD HH:mm';
export const DAY_MONTH_DATE_TIME_FORMAT = 'dddd MMM D, YYYY, h:mma';
export const DAY_MONTH_DATE_FORMAT = 'dddd MMM D, YYYY';

export const CookieKeys = {
  user: 'user',
  client: 'client',
  shop: 'shop',
  confirmationForm: '@OLIVER_BOOKING/ConfirmationForm',
  messaging: '@OLIVER_BOOKING/messaging',
};

export const SpecialErrors = {
  AliasAlreadyUsed: 'AliasAlreadyUsed',
  InvalidCode: 'Invalid code',
  InvalidTenant: 'Invalid tenant and user',
  VerificationMaximumExceeded: 'VerificationMaximumExceeded',
};

export const AppointmentSteps = {
  ClientTypeSelection: 1,
  ReturningClientInfo: 2,
  LocationSelection: 3,
  AppointmentSelection: 4,
  ClientInfoCollection: 5,
  Verification: 6,
  Login: 7,
  SignUp: 8,
  ForgotPassword: 9,
  DepositCollection: 10,
  BookSuccess: 12,
  NoAvailability: 13,
};

export const DefaultAvatar = DefaultAvatarImg;

export const COLORS = [
  { hex: '#BB6BD9', rgb: '187, 107, 217', hexLighter: '#F0DAFB', rgbLighter: '240, 218, 251' },
  { hex: '#5749F2', rgb: '87, 73, 242', hexLighter: '#DDDAFB', rgbLighter: '221, 218, 251' },
  { hex: '#2D9CDB', rgb: '45, 156, 219', hexLighter: '#DAE7FB', rgbLighter: '218, 231, 251' },
  { hex: '#5CC9CA', rgb: '92, 201, 202', hexLighter: '#DAF9FB', rgbLighter: '218, 249, 251' },
  { hex: '#54B463', rgb: '84, 180, 99', hexLighter: '#CBECD7', rgbLighter: '203, 236, 215' },
  { hex: '#DD3075', rgb: '221, 48, 117', hexLighter: '#F3C1DB', rgbLighter: '243, 193, 219' },
  { hex: '#E05A51', rgb: '224, 90, 81', hexLighter: '#FBDADA', rgbLighter: '251, 218, 218' },
  { hex: '#ED8537', rgb: '237, 133, 55', hexLighter: '#FADFC7', rgbLighter: '250, 223, 199' },
  { hex: '#F2C94C', rgb: '242, 201, 76', hexLighter: '#FBF6DA', rgbLighter: '251, 246, 218' },
  { hex: '#99A3B7', rgb: '153, 163, 183', hexLighter: '#F2F2F2', rgbLighter: '242, 242, 242' },
];

export const AppointmentLabels = ['Anyone', 'Requested', 'Walk-in', 'Pending', 'No-show', 'Done'];

// export const avatarDefault = 'https://www.pixsy.com/wp-content/uploads/2021/04/ben-sweet-2LowviVHZ-E-unsplash-1.jpeg';

// eslint-disable-next-line
export const regexEmail =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
// eslint-disable-next-line
export const regexUrl =
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;

export const StatusContent = {
  REQUESTED: 'Requested',
  ACCEPTED: 'Accepted',
  CONFIRMED: 'Confirmed',
  DECLINED: 'Declined',
  DELETED: 'Canceled',
  DONE: 'Finished',
};
