import { useMutation, useInfiniteQuery, useQuery } from 'react-query';
import firebase from 'firebase/app';
import { getProfile, socialSignIn, customerSignIn, signUp, updateProfile } from '@apis';

import useJwt from '@src/@core/auth/jwt/useJwt';
import useShop from '@hooks/useShop';
import useUserState from '@hooks/useUserState';
import { CookieKeys } from '@utility/Constant';
import { Cookie } from '@utility/Cookie';

export const useCurrentUserProfile = (options = {}) => {
  const [, setUser] = useUserState();
  return useQuery(
    ['useCurrentUserProfile'],
    async () => {
      try {
        const { data } = await getProfile();
        setUser(data.data);
        return data.data;
      } catch (e) {
        throw e;
      }
    },
    { ...options }
  );
};

export const useUpdateProfileMutation = options => {
  const [, setUser] = useUserState();
  return useMutation(async values => {
    try {
      const { data } = await updateProfile(values);
      setUser(data.data);

      return data.data;
    } catch (e) {
      console.log(e);
      throw e?.response?.data;
    }
  }, options);
};

export const useSignUpMutation = options => {
  const { jwt } = useJwt();
  const [, setUser] = useUserState();

  return useMutation(async values => {
    try {
      const { data } = await signUp({
        // firstName: values.name,
        email: values.email,
        username: values.email || values.phoneNumber.slice(values.phoneCode?.length || 0),
        password: values.password,
        phoneCode: values.phoneCode,
        phoneNumber: values.phoneNumber.slice(values.phoneCode?.length || 0),
        rememberMe: true,
        role: 'ROLE_CUSTOMER',
      });
      setUser(data.data);
      jwt.setToken(data.data.token);
      Cookie.setItem(CookieKeys.user, data.data);

      return data.data;
    } catch (e) {
      console.log(e);
      throw e?.response?.data;
    }
  }, options);
};

export const useLoginMutation = options => {
  const { jwt } = useJwt();
  const [, setUser] = useUserState();

  return useMutation(async values => {
    try {
      const { data } = await customerSignIn({
        username: values.username,
        password: values.password,
        rememberMe: true,
      });
      setUser(data.data);
      jwt.setToken(data.data.token);
      Cookie.setItem(CookieKeys.user, data.data);

      return data.data;
    } catch (e) {
      console.log(e);
      throw e?.response?.data;
    }
  }, options);
};

export const useSocialSignInMutation = options => {
  const { jwt } = useJwt();
  const [, setUser] = useUserState();

  return useMutation(async values => {
    try {
      const { data } = await socialSignIn({
        avatar: values.avatar,
        email: values.email,
        firstName: values.firstName,
        oAuthType: values.oAuthType,
        idToken: values.idToken,
        phoneCode: values.phoneCode,
        phoneNumber: values.phoneNumber?.slice?.(values.phoneCode.length),
      });
      setUser(data.data);
      jwt.setToken(data.data.token);
      Cookie.setItem(CookieKeys.user, data.data);

      return data.data;
    } catch (e) {
      console.log(e);
      throw e?.response?.data;
    }
  }, options);
};

export const useGoogleLogin = ({ onError, onSuccess }) => {
  const socialSignInMutation = useSocialSignInMutation({ onSuccess, onError });

  return {
    ...socialSignInMutation,
    mutate: () => {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(googleAuthProvider)
        .then(async ({ user, credential, ...other }) => {
          socialSignInMutation.mutate({
            idToken: await user.getIdToken(),
            email: user.email,
            avatar: user.photoURL,
            firstName: user.displayName,
            oAuthType: 'GOOGLE',
          });
        })
        .catch(onError);
    },
  };
};

export const useFacebookLogin = ({ onError, onSuccess }) => {
  const socialSignInMutation = useSocialSignInMutation({ onSuccess, onError });

  return {
    ...socialSignInMutation,
    mutate: () => {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(facebookProvider)
        .then(async ({ user, credential, ...other }) => {
          socialSignInMutation.mutate({
            idToken: await user.getIdToken(),
            email: user.email,
            avatar: user.photoURL,
            firstName: user.displayName,
            oAuthType: 'FACEBOOK',
          });
        })
        .catch(onError);
    },
  };
};
