import { useState } from 'react';
import { Alert, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import GoogleIcon from '@src/assets/images/icons/google.png';
import FacebookIcon from '@src/assets/images/icons/facebook.png';
import InputPasswordToggle from '@components/input-password-toggle';
import { AppointmentSteps, regexEmail } from '@utility/Constant';
import { useFacebookLogin, useGoogleLogin, useLoginMutation } from '@queries';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
  username: yup.string().trim().required('Please enter your phone or email').max(255, 'Please enter within 255 characters'),
  password: yup.string().trim().required('Password is required').max(255, 'Please enter within 255 characters'),
});

const Login = ({ setStep, goNextStep, booking, setBooking }) => {
  const [socialError, setSocialError] = useState();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onContinue = customer => {
    setBooking({ ...booking, customer });
    history.replace(history.location.pathname, { loggedIn: true });
    goNextStep();
  };

  const signInMutation = useLoginMutation({
    onSuccess: data => onContinue(data),
    onSettle: () => history.replace(history.location.pathname, { loggedIn: true })
  });

  const onSubmit = values => {
    const reqUsername = regexEmail.test(values.username) ? values.username : `1;${values.username}`;
    signInMutation.mutate({ username: reqUsername, password: values.password });
  };

  const signInGoogle = useGoogleLogin({
    onSuccess: data => {
      setSocialError('');
      onContinue(data);
    },
    onError: e => {
      setSocialError(e?.message);
    },
  });

  const signInFacebook = useFacebookLogin({
    onSuccess: data => {
      setSocialError('');
      onContinue(data);
    },
    onError: e => setSocialError(e?.message),
  });

  return (
    <div className="booking-content-wrapper">
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <h4 className="text-center mb-1">Sign in to access your profile</h4>
          {socialError && (
            <Alert color="danger">
              <div className="alert-body">{socialError}</div>
            </Alert>
          )}
          {signInMutation.isError && (
            <Alert color="danger">
              <div className="alert-body">Wrong email or password</div>
            </Alert>
          )}

          <Form className="mb-3" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>Phone or Email</Label>
              <Controller control={control} name="username" render={({ field }) => <Input type="text" {...field} />} />
              {errors?.username && <FormText color="danger">{errors?.username?.message}</FormText>}
            </FormGroup>
            <FormGroup>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <InputPasswordToggle className="input-group-merge" label="Password" htmlFor="basic-default-password" {...field} />
                )}
              />
              {errors?.password && <FormText color="danger">{errors?.password?.message}</FormText>}
            </FormGroup>
            <Button
              className="text-left p-0 my-1 font-weight-normal"
              block
              color="link"
              onClick={() => setStep(AppointmentSteps.ForgotPassword)}
            >
              Forgot your password?
            </Button>
            <Button type="submit" color="secondary" block>
              Sign in
            </Button>
            <div className="text-center mt-2">
              Don’t have an account?{' '}
              <Button color="link" className="p-0" onClick={() => setStep(AppointmentSteps.SignUp)}>
                Sign up
              </Button>
            </div>
          </Form>

          <div className="divider mb-3">
            <div className="divider-text">OR</div>
          </div>

          <Button onClick={signInGoogle.mutate} outline block color="secondary" className="btn-social">
            <img className="" src={GoogleIcon} />
            Sign in with Google
          </Button>
          <Button onClick={signInFacebook.mutate} outline block color="secondary" className="btn-social">
            <img className="" src={FacebookIcon} />
            Sign in with Facebook
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
