import { useEffect, useState } from 'react';
import { useRequestResetPasswordMutation, useVerifyResetPasswordMutation, useResetPasswordMutation, useLoginMutation } from '@queries';
import toast from '@components/toast';
import { regexEmail } from '@utility/Constant';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import VerifyResetPasswordForm from './VerifyResetPasswordForm';

const _step = {
  request: 0,
  verify: 1,
  reset: 2,
};

const ForgotPassword = ({ goNextStep, booking, setBooking }) => {
  const [step, setStep] = useState(_step.request);
  const [username, setUsername] = useState();
  const [reqUsername, setReqUsername] = useState();
  const [token, setToken] = useState();

  const getReqUsername = (value, phoneCode) => (regexEmail.test(value) ? value : `${phoneCode};${value.replace(phoneCode, '')}`);

  const requestResetPasswordMutation = useRequestResetPasswordMutation();
  const verifyResetPasswordMutation = useVerifyResetPasswordMutation();
  const resetPasswordMutation = useResetPasswordMutation();

  const loginMutation = useLoginMutation({
    onSuccess: data => {
      goNextStep();
    },
  });

  const onSubmitRequestReset = values => {
    requestResetPasswordMutation.mutate(
      { username: getReqUsername(values.username, values.phoneCode) },
      {
        onSuccess: () => {
          setUsername(values.username);
          setReqUsername(getReqUsername(values.username, values.phoneCode));
          setStep(_step.verify);
        },
      }
    );
  };

  const onSubmitVerifyReset = code => {
    verifyResetPasswordMutation.mutate(
      { username: reqUsername, code },
      {
        onSuccess: res => {
          if (res.data.success) {
            setToken(res.data.data);
            setStep(_step.reset);
            return;
          }
          setToken('');
        },
      }
    );
  };

  const onSubmitResetPassword = values => {
    resetPasswordMutation.mutate(
      { token, password: values.password },
      {
        onSuccess: res => {
          if (res.data.success) {
            toast.success('Reset password successfully!');
            loginMutation.mutate({ username: reqUsername, password: values.password, rememberMe: true });
          }
        },
      }
    );
  };

  return {
    [_step.request]: <ForgotPasswordForm onSubmit={onSubmitRequestReset} isLoading={requestResetPasswordMutation.isLoading} />,
    [_step.verify]: (
      <VerifyResetPasswordForm
        username={username}
        onSubmit={onSubmitVerifyReset}
        isLoading={verifyResetPasswordMutation.isLoading}
        onResend={() => onSubmitRequestReset({ username })}
      />
    ),
    [_step.reset]: <ResetPasswordForm onSubmit={onSubmitResetPassword} isLoading={resetPasswordMutation.isLoading} />,
  }[step];
};

export default ForgotPassword;
