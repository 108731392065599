import axios from 'axios';

export const getTimeoffs = (id, page, size) => {
  return axios.get(`/appointment/v1/providers/${id}/time-off?page=${page}&size=${size}`);
};
export const createTimeoffs = (data, id) => {
  return axios.post(`/appointment/v1/providers/${id}/time-off`, data);
};
export const updateTimeoffs = (data, id, idTimeOff) => {
  return axios.put(`/appointment/v1/providers/${id}/time-off/${idTimeOff}`, data);
};
export const deleteTimeoff = (idStaff, idTimeOff) => {
  return axios.delete(`/appointment/v1/providers/${idStaff}/time-off/${idTimeOff}`);
};
// export const getProvider = id => axios.get(`${API_TENANT_PUBLIC_URL}/providers/${id}`);
