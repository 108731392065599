import { useMutation } from 'react-query';
import { requestResetPassword, verifyResetPasswordCode, resetPassword } from '@apis/auth.api';

export const useRequestResetPasswordMutation = () => {
  return useMutation(requestResetPassword);
};

export const useVerifyResetPasswordMutation = () => {
  return useMutation(verifyResetPasswordCode);
};

export const useResetPasswordMutation = () => {
  return useMutation(resetPassword);
};
