// ** React Imports
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import firebase from 'firebase/app';

// ** Custom Components
import Avatar from '@components/avatar';

// ** Utils
import { isUserLoggedIn } from '@utils';

// ** Store & Actions
import { useDispatch } from 'react-redux';

// ** Third Party Components
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power, Repeat, Calendar, PlusCircle } from 'react-feather';

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg';
import userState from '../../../../recoil/atoms/auth/user';
import bookingState, { initialBookingState } from '../../../../recoil/atoms/booking/booking';
import bookingStepState from '../../../../recoil/atoms/booking/bookingStep';
import { useRecoilState } from 'recoil';
import { Cookie } from '../../../../utility/Cookie';
import { AppointmentSteps, CookieKeys } from '../../../../utility/Constant';

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { pathname } = history.location;
  const clientId = pathname.split('/')[1];
  // ** State
  const [user, setUser] = useRecoilState(userState);
  const [booking, setBooking] = useRecoilState(bookingState);
  const [bookingStep, setBookingStep] = useRecoilState(bookingStepState);

  //** Vars
  const userAvatar = user?.avatar;

  const logout = () => {
    if (user.isAdmin) {
      setUser(null);
      Cookie.removeItem(CookieKeys.user);
      Cookie.removeItem(CookieKeys.shop);
      window.location.assign('/login');
      return;
    }
    setUser(null);
    Cookie.removeItem(CookieKeys.user);
    sessionStorage.removeItem(CookieKeys.confirmationForm);
    Cookie.removeItem(CookieKeys.confirmationForm);
    firebase.auth().signOut();
    window.location.href = `/${clientId}`;
    setBooking(initialBookingState);
    setBookingStep(AppointmentSteps.AppointmentSelection);
  };

  return (
    !!user && (
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={e => e.preventDefault()}>
          <Avatar icon={<User />} img={userAvatar} color="light-primary" imgHeight="40" imgWidth="40" status="online" />
        </DropdownToggle>
        <DropdownMenu className="nav-dropdown-menu">
          <DropdownItem tag={Link} to={`/${clientId}/profile`}>
            <User size={14} className="mr-75" />
            <span className="align-middle">My Profile</span>
          </DropdownItem>
          <DropdownItem tag={Link} to={`/${clientId}/appointments`}>
            <Calendar size={14} className="mr-75" />
            <span className="align-middle">My Appointments</span>
          </DropdownItem>
          <DropdownItem tag={Link} to={`/${clientId}`}>
            <PlusCircle size={14} className="mr-75" />
            <span className="align-middle">Schedule Now</span>
          </DropdownItem>
          <DropdownItem tag={Link} onClick={logout} to="/login">
            <Power size={14} className="mr-75" />
            <span className="align-middle">Sign Out</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  );
};

export default UserDropdown;
