import mock from "../mock";

const date = new Date();
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

mock.onGet("/v1/api/appointments").reply((config) => {
  const {
    q = "",
    size = 20,
    page = 0,
    role = "provider",
    currentPlan = null,
    status = null,
  } = config;
  /* eslint-disable  */

  /* eslint-enable  */

  return [
    200,
    {
      success: true,
      data: {
        content: events,
        totalElements: events.length,
        number: page,
        size: size,
      },
    },
  ];
});

const events = [
  {
    uuid: "97e045fbdd2ad2f4442aecdcdb0f8dee",
    fromDate: "2021-05-20T04:00:00.000000Z",
    toDate: "2021-05-20T05:00:00.000000Z",
    servedByUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    metadata: {
      relatedItems: [
        "461b984384720436dfa5c5f92f56e794",
        "f7064275519735fd1428ece803c8d3bb"
      ], // Show appointments of another staffs which 're included in one booking
      includedItems: [ // Show another services which is served by the same staff
        {
          uuid: "a610ee5d4686492d7734715804bc6a5f",
          bookingItemUUID: "8cc93e160f868ecf027fc5696abeaaa7",
          productUUID: "a14e5cd1-6ef0-448e-bc7c-9917f682e42a",
          productName: "e-services Security Colombian",
          productCost: null,
          productTime: null,
        },
        {
          uuid: "0eff6a0d02c788c9a90d9ddf0d527713",
          bookingItemUUID: "737573d01e37e7bddf3e099b3d3a1e55",
          productUUID: "52cdca35-42e4-4504-986a-103837f44d92",
          productName: "portals Berkshire intuitive",
          productCost: null,
          productTime: null,
        },
      ],
      note: null,
      bookingItemUUID: "50ecf1007307982169e35175950383c7",
      productUUID: "53675d0e-9690-414e-811f-01a2468fc20e",
      productName: "Ukraine",
      productCost: null,
      productTime: null,
      servedByUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForName: "Administrator",
      servedForEmail: "admin@localhost",
      servedForPhone: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    },
  },
  {
    uuid: "461b984384720436dfa5c5f92f56e794",
    fromDate: "2021-05-20T04:00:00.000000Z",
    toDate: "2021-05-20T05:00:00.000000Z",
    servedByUUID: "16b4262b-d82d-ssss-baed-390bb46bead4",
    servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    metadata: {
      relatedItems: [
        "97e045fbdd2ad2f4442aecdcdb0f8dee",
        "f7064275519735fd1428ece803c8d3bb"

      ],
      includedItems: [],
      note: null,
      bookingItemUUID: "81ffeebb539c75d94498fb186a93f10b",
      productUUID: "18c2f408-6ec1-4e48-b8fe-dfa0335fa46f",
      productName: "efficient methodologies Future",
      productCost: null,
      productTime: null,
      servedByUUID: "16b4262b-d82d-ssss-baed-390bb46bead4",
      servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForName: "Administrator",
      servedForEmail: "admin@localhost",
      servedForPhone: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    },
  },
  {
    uuid: "f7064275519735fd1428ece803c8d3bb",
    fromDate: "2021-05-20T07:00:00.000000Z",
    toDate: "2021-05-20T09:00:00.000000Z",
    servedByUUID: "f745f93b-8486-cccc-8fb3-fba189fd076e",
    servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    metadata: {
      relatedItems: [],
      includedItems: [],
      note: null,
      bookingItemUUID: "4a66a850e4c837bfc9dc39e68edf86fb",
      productUUID: "9674a909-e414-4a49-8120-e4d9af887bf7",
      productName: "Customer",
      productCost: null,
      productTime: null,
      servedByUUID: "f745f93b-8486-cccc-8fb3-fba189fd076e",
      servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForName: "Administrator",
      servedForEmail: "admin@localhost",
      servedForPhone: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    },
  },
  {
    uuid: "e0224a4b3f3f6a068ed7f86e6c269af1",
    fromDate: "2021-05-12T04:00:00.000000Z",
    toDate: "2021-05-12T05:00:00.000000Z",
    servedByUUID: "f745f93b-8486-4dda-8fb3-fba189fd076e",
    servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    metadata: {
      relatedItems: [],
      includedItems: [],
      note: null,
      bookingItemUUID: "b2bdf634a6e768146f80d66f27d9689a",
      productUUID: "df3fc0a6-33c3-4f36-91d8-44dd7aaa8698",
      productName: "Colombian",
      productCost: null,
      productTime: null,
      servedByUUID: "f745f93b-8486-4dda-8fb3-fba189fd076e",
      servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForName: "Administrator",
      servedForEmail: "admin@localhost",
      servedForPhone: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    },
  },
  {
    uuid: "da6581e2d93c2dfc9766ece0bdec175d",
    fromDate: "2021-05-13T04:00:00.000000Z",
    toDate: "2021-05-13T05:00:00.000000Z",
    servedByUUID: "f745f93b-8486-4dda-8fb3-fba189fd076e",
    servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    metadata: {
      relatedItems: [],
      includedItems: [],
      note: null,
      bookingItemUUID: "a7b37fe436e418c85c1d68cd6f2d1d1e",
      productUUID: "59caa4ed-6a8c-4b4d-ac06-8ad26c92da1a",
      productName: "Cheese",
      productCost: null,
      productTime: null,
      servedByUUID: "f745f93b-8486-4dda-8fb3-fba189fd076e",
      servedForUUID: "16b4262b-d82d-4cd8-baed-390bb46bead4",
      servedForName: "Administrator",
      servedForEmail: "admin@localhost",
      servedForPhone: "16b4262b-d82d-4cd8-baed-390bb46bead4",
    },
  },
];
