import { getTimeoffs, searchTimes } from '@apis';
import FormDateTime from '@components/form-date-time';
import { yupResolver } from '@hookform/resolvers/yup';
import { chain, filter, findLast, flattenDeep, isEmpty, uniq, uniqBy } from 'lodash';
import momentTz from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { useStaffDetailQuery } from '../../../../../queries/staff.query';
import { MOMENT_DATE_FORMAT_FRIENDLY, YEAR_MONTH_DATE_HOUR_FORMAT, YEAR_MONTH_DATE_FORMAT } from '../../../../../utility/Constant';
import useShop from '../../../../../utility/hooks/useShop';
import { numberToString } from '../../../../../utility/Utils';

const detailSchema = yup.object().shape({
  date: yup
    .mixed()
    .test(
      'is-date',
      'Please select a valid date and time',
      value => !!value?.date && !!value?.time && momentTz(`${momentTz(value.date).format('YYYY-MM-DD')} ${value.time.value}`).isValid()
    ),
  note: yup.string().max(255, 'Please enter within 255 characters').nullable(),
});

const RescheduleForm = ({ appointment, onSubmit, isUpdatingAppointment }) => {
  const [startDate, setStartDate] = useState(appointment?.fromDate ? momentTz(appointment?.fromDate).toDate() : null);
  const shop = useShop();

  const bookingLeadTime = useMemo(() => shop?.metadata?.bookingLeadTime || 0, [shop]);

  useEffect(() => {
    if (shop?.timezone) {
      momentTz.tz.setDefault(shop?.timezone);
    }
  }, [shop]);

  const diffOffset = useMemo(() => {
    const currentOffset = momentTz().tz(momentTz.tz.guess()).utcOffset();
    const defaultOffset = momentTz().tz(shop?.timezone).utcOffset();
    return currentOffset - defaultOffset || 0;
  }, [shop]);

  const groupByStaff = useMemo(
    () =>
      chain(appointment?.items)
        .groupBy('providerId')
        .mapValues(arr => {
          const result = arr.reduce(
            (res, item) => {
              res.provider = { providerId: item.metadata?.providerId, providerName: item.metadata?.providerName };
              res.products = uniqBy([...res.products, item], 'metadata.productId');
              return res;
            },
            { staff: null, products: [] }
          );
          return result;
        })
        .value(),
    [appointment]
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(detailSchema),
    defaultValues: {
      date: appointment?.fromDate
        ? {
            date: momentTz(appointment?.fromDate).toDate(),
            time: {
              label: momentTz(appointment?.fromDate).format('HH:mm'),
              value: momentTz(appointment?.fromDate).format('HH:mm'),
            },
          }
        : {
            date: null,
            time: null,
          },
      note: appointment?.note || '',
    },
  });

  const { data: timeData } = useQuery(
    ['getTime', startDate],
    async () => {
      const res = await searchTimes({
        date: momentTz(startDate).tz(momentTz.tz.guess()).add(diffOffset, 'minutes').toISOString(),
        providerIds: uniq(appointment?.items.map(service => service.providerId)),
        productIds: uniq(
          flattenDeep(
            Object.values(groupByStaff).map(item =>
              item.products.map(product => [
                product?.metadata?.productId,
                ...(product?.metadata?.includedItems?.map(p => p?.metadata?.productId) || []),
              ])
            )
          )
        ).filter(item => !!item),
      });
      return res.data.data;
    },
    {
      enabled: !!startDate,
    }
  );
  const timesOptions = useMemo(
    () =>
      timeData
        ?.filter(item => item.enabled && momentTz(item.dateTime).isSameOrAfter(momentTz().add(bookingLeadTime, 'minutes')))
        .map(item => ({ ...item, label: item.value, value: item.value })),
    [timeData, appointment, bookingLeadTime]
  );

  const { data: timeOffs, isFetching: isFetchingTimeOffs } = useQuery(
    ['timeOffs', appointment?.items?.[0]?.providerId],
    async () => {
      const { data } = await getTimeoffs(appointment?.items?.[0]?.providerId, 0, 1000);
      return data.data.content;
    },
    {
      enabled: !!appointment?.items?.[0]?.providerId,
    }
  );

  const { data: providerMetadata, isFetching: isFetchingProvider } = useStaffDetailQuery(appointment?.items?.[0]?.providerId, {
    enabled: !!appointment?.items?.[0]?.providerId,
    select: res => res.data?.data?.metadata,
  });

  const staffWorkingHours = useMemo(() => {
    const { businessHours: staffBusinessHours = [] } = providerMetadata || {};
    if (isEmpty(staffBusinessHours)) return {};
    const dateTime = momentTz(startDate).tz(momentTz.tz.guess());
    const day = dateTime.weekday() || 7;
    const date = dateTime.format('YYYY-MM-DD');
    const { startHour, endHour, startMinute, endMinute } = findLast(staffBusinessHours, { day });
    const startTime = momentTz(`${date} ${numberToString(startHour)}:${numberToString(startMinute)}`);
    const endTime = momentTz(`${date} ${numberToString(endHour)}:${numberToString(endMinute)}`);
    return { startTime, endTime };
  }, [providerMetadata, startDate]);

  const invalidTimes = useMemo(() => {
    const { startTime, endTime } = staffWorkingHours;
    return filter(
      timesOptions,
      time => momentTz(time.dateTime).isSameOrAfter(startTime) && momentTz(time.dateTime).isSameOrBefore(endTime)
    );
  }, [timesOptions, staffWorkingHours]);

  const isOfflineDate = useCallback(
    dateItem => {
      const { bookingHours = [], holidayHours = [] } = shop?.metadata || {};
      const { businessHours: staffBusinessHours = [] } = providerMetadata || {};
      const day = momentTz(dateItem).tz(momentTz.tz.guess()).weekday();
      const isOffline =
        bookingHours?.some(item => {
          if (item.day === 7 && day === 0) {
            return item.offline;
          }

          return item.day === day && item.offline;
        }) ||
        staffBusinessHours?.some(item => {
          if (item.day === 7 && day === 0) {
            return item.offline;
          }

          return item.day === day && item.offline;
        }) ||
        holidayHours?.some(item => momentTz(dateItem).isSame(item?.date, 'day') && item?.offline) ||
        (timeOffs || []).some(
          item => momentTz(dateItem).isSameOrAfter(item.fromDate, 'day') && momentTz(dateItem).isSameOrBefore(item.toDate, 'day')
        );

      return isOffline;
    },
    [shop, providerMetadata, timeOffs, momentTz]
  );

  return (
    <Form className="px-1" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="date">
          Date/Time <span className="text-danger">*</span>
        </Label>
        {!isFetchingTimeOffs && (
          <FormDateTime
            name="date"
            control={control}
            onDateChange={date => setStartDate(date)}
            timeOptions={invalidTimes}
            disable={[isOfflineDate]}
            minDate={momentTz().add(bookingLeadTime, 'minutes').format(MOMENT_DATE_FORMAT_FRIENDLY)}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label for="note">Note</Label>

        <Controller
          name="note"
          control={control}
          render={({ field, fieldState }) => (
            <Input type="textarea" name="text" id="note" placeholder="Note" {...field} style={{ minHeight: 90 }} />
          )}
        />
        {errors.note && (
          <span className="text-danger">
            <small>{errors.note.message}</small>
          </span>
        )}
      </FormGroup>

      <div className="mt-5">
        <Button
          block
          color="primary"
          type="submit"
          className="d-flex align-items-center justify-content-center"
          disabled={!isDirty || isUpdatingAppointment}
        >
          {isUpdatingAppointment ? (
            <>
              <Spinner size="sm" className="mr-25" />
              Rescheduling
            </>
          ) : (
            'Reschedule'
          )}
        </Button>
      </div>
    </Form>
  );
};

export default RescheduleForm;
