import { Calendar, Edit, Info, Users } from 'react-feather';
import { lazy } from 'react';
import { lazyRetry } from '../../../App';
import BookAppointment from './components/BookAppointment';

export const bookingRoutes = [
  {
    path: '/',
    exact: true,
    className: '',
    component: BookAppointment,
    id: 'booking-menu-appointment',
    sideBar: {
      label: 'Book Appointment',
      icon: <Calendar size={15} className="mr-1" />,
    },
    button: {
      label: 'Appointment Type',
    },
    mode: 'appointment',
  },
  {
    path: '/:paymentId',
    exact: true,
    className: '',
    component: BookAppointment,
    id: 'booking-menu-appointment',
    sideBar: {
      label: 'Book Appointment',
      icon: <Calendar size={15} className="mr-1" />,
    },
    button: {
      label: 'Appointment Type',
    },
    mode: 'clinician',
  }
];
