import { useEffect, useState } from 'react';
import { Globe, MapPin, MessageSquare } from 'react-feather';
import { useRecoilState } from 'recoil';
import cn from 'classnames';
import shopState from '../../../../recoil/atoms/current-shop';
import moment from 'moment';
import { urlPattern } from '../../../../utility/Constant';

const daysOfWeek = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

const contactInfo = shop => [
  shop?.url && {
    key: 'contact-info-web',
    href: shop?.url,
    icon: <Globe size={12} />,
    label: 'Website',
  },
  shop?.phone && {
    key: 'contact-info-phone',
    href: `sms:${shop?.phone}`,
    icon: <MessageSquare size={12} />,
    label: shop?.phone,
  },
  // shop?.email && {
  //   key: 'contact-info-email',
  //   href: `mailto:${shop?.email}`,
  //   icon: <Mail size={12} />,
  //   label: shop?.email,
  // },
  // shop?.metadata?.facebookUrl && {
  //   key: 'contact-info-facebook',
  //   href: shop?.metadata?.facebookUrl,
  //   icon: <Facebook size={12} />,
  //   label: 'Facebook',
  // },
  // shop?.metadata?.yelpUrl && {
  //   key: 'contact-info-yeld',
  //   href: shop?.metadata?.yelpUrl,
  //   icon: <Link size={12} />,
  //   label: 'Yelp',
  // },
  // shop?.metadata?.instagramUrl && {
  //   key: 'contact-info-insta',
  //   href: shop?.metadata?.instagramUrl,
  //   icon: <Instagram size={12} />,
  //   label: 'Instagram',
  // },
  shop?.address && {
    key: 'contact-info-address',
    href: urlPattern.test(shop?.metadata?.googleMapUrl) ? shop?.metadata?.googleMapUrl : `https://www.google.com/maps/search/?api=1&${shop?.address}`,
    icon: <MapPin size={12} />,
    label: shop?.address,
  },
];

const BookingRightSide = () => {
  const [shop] = useRecoilState(shopState);
  const [shopCoordinates, setShopCoordinates] = useState(null);

  useEffect(() => {
    const { metadata } = shop || {};
    const { mapCoordinates, isBookingShowMap } = metadata || {};
    if (!mapCoordinates || !isBookingShowMap) return;
    setShopCoordinates(mapCoordinates.split(',').map(Number));
  }, [shop]);

  const hideBookingHours = () => {
    if (shop?.feature_settings?.hide_client_booking_hours_enabled !== undefined &&
      shop.feature_settings?.hide_client_booking_hours_enabled === true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="d-none d-sm-block">
        <h5 className="mb-1">Contact Info</h5>
        <ul className="contact-info mb-2">
          {contactInfo(shop)
            .filter(item => !!item)
            .map(item => (
              <li key={item.key} className="mb-1">
                <a href={item.href} target="_blank" className="d-flex align-items-center">
                  <span className="contact-info-icon">{item.icon}</span>
                  <span>{item.label}</span>
                </a>
              </li>
            ))}
        </ul>
        {/* {shop?.metadata?.mapCoordinates && shop?.metadata?.isBookingShowMap && (
          <MapsBasic hideHeader containerClass="mb-2" coordinates={shopCoordinates} zoom={16} />
        )} */}
      </div>
      {!hideBookingHours() && (
        <>
          <h5 className="mb-1">Business hours</h5>
          <ul className="business-hours">
            {shop?.metadata?.businessHours?.map(item => (
              <li key={`business-hours-${item.day}`} className={cn({ today: daysOfWeek[item.day] === moment().format('ddd') })}>
                <span>{daysOfWeek[item.day]}</span>
                {!item.offline ? (
                  <span>
                    {item.startHour}:{item.startMinute === 0 ? '00' : item.startMinute} - {item.endHour > 12 ? item.endHour - 12 : item.endHour}:
                    {item.endMinute === 0 ? '00' : item.endMinute}
                  </span>
                ) : (
                  <span>Closed</span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default BookingRightSide;
