import { useState } from 'react';
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { useQuery } from 'react-query';
import { getBookingAppointments } from '@apis/booking.api';
import useUserState from '@hooks/useUserState';
import AppointmentItem from './AppointmentItem';
import qs from 'query-string';
import useShop from '../../../../../utility/hooks/useShop';

const Appointments = ({ history }) => {
  const shop = useShop();
  const params = qs.parse(history.location.search);
  const [tab, setTab] = useState(params.tab || 'upcoming');
  const [user] = useUserState();
  const { data, isLoading } = useQuery(
    ['getAppointments', tab],
    async () => {
      const { data } = await getBookingAppointments(user?.id, { type: tab, size: 1000 });
      return data.data;
    },
    {
      enabled: !!user?.id,
    }
  );

  const { content: appointments } = data || {};

  const onItemClick = id => {
    history.push(`/${id}`);
  };

  return (
    <Card>
      <CardBody className="px-0 px-md-2">
        <h4 className="text-center text-lg-left">Your Appointments</h4>
        <div className="mt-2 mb-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                active={tab === 'upcoming'}
                onClick={() => {
                  setTab('upcoming');
                  history.replace('?tab=upcoming');
                }}
              >
                Upcoming
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={tab === 'history'}
                onClick={() => {
                  setTab('history');
                  history.replace('?tab=history');
                }}
              >
                Past
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="py-2" activeTab={tab}>
            <TabPane tabId="upcoming">
              {isLoading && (
                <div className="d-flex justify-content-center mt-2 mb-2">
                  <Spinner />
                </div>
              )}
              {!isLoading && appointments?.length === 0 && <div className="d-flex justify-content-center mt-2 mb-2">Nothing to see here yet!</div>}
              {appointments?.map(item => {
                return (
                  <AppointmentItem key={item.id} hideProviders data={item} onClick={() => onItemClick(item.id)} timezone={shop?.timezone} />
                );
              })}
            </TabPane>
            <TabPane tabId="history">
              {isLoading && (
                <div className="d-flex justify-content-center mt-2 mb-2">
                  <Spinner />
                </div>
              )}
              {!isLoading && appointments?.length === 0 && <div className="d-flex justify-content-center mt-2 mb-2">Nothing to see here yet!</div>}
              {appointments?.map(item => (
                <AppointmentItem key={item.id} hideProviders data={item} onClick={() => onItemClick(item.id)} timezone={shop?.timezone} />
              ))}
            </TabPane>
          </TabContent>
        </div>
      </CardBody>
    </Card>
  );
};

export default Appointments;
