import { forwardRef } from 'react';

// ** React select
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

// ** APIs

const Select = forwardRef(({ onChange = () => {}, minHeight, styles, isCreatable, ...rest }, ref) => {
  const Tag = isCreatable ? CreatableSelect : ReactSelect;

  return (
    <Tag
      ref={ref}
      cacheOptions
      className="react-select w-100"
      classNamePrefix="select"
      isClearable={false}
      styles={{
        control: provided => ({
          ...provided,
          minHeight: minHeight || 28,
          border: '1px solid #dddddd',
          borderRadius: '0.357rem',
        }),
        dropdownIndicator: provided => ({
          ...provided,
          padding: '2px 4px',
        }),
        placeholder: provided => ({
          ...provided,
          fontSize: 12,
        }),
        singleValue: provided => ({
          ...provided,
          fontSize: 12,
        }),
        option: provided => ({
          ...provided,
          fontSize: 12,
          color: '#433B54',
        }),
        valueContainer: provided => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menu: provided => ({ ...provided, zIndex: 10 }),
        ...(styles || {}),
      }}
      onChange={onChange}
      {...rest}
    />
  );
});

export default Select;
